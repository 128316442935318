<template>
  <button
    class="btn btn-primary btn-lg mr-2"
    :disabled="offloadSyncProgress > -1"
    @click="triggerSqliteOffloadForceSync()"
  >
    <span v-if="offloadSyncProgress > -1">
      <i class="fas fa-spinner fa-spin"></i> Syncing {{ offloadSyncProgress }}%
    </span>
    <span v-else>Force Sync</span>
  </button>
</template>

<script>
import {ALERT_MESSAGES, OFFLOAD, SQLITE_OFFLOAD_FORCE_SYNC_CHUNK_SIZE} from "@/spa/constants";
import bus from "@/spa/utils/bus";
import {mapActions, mapState} from "vuex";
import {checkCloudConnection} from "@/spa/plugins/axios";
import {getSupportScript, updateSupportScript} from "@/spa/services/sync-service";
import {TRANSACTION_STATUS_ID} from "@/mobile_bridge/offload/receipt-model";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {storeToS3} from "@/spa/services/logger-service";

const SQLITE_TRIGGER_COUNT = 'sqliteOffloadTriggerCount';

export default {
  data() {
    return {
      offloadSyncProgress: -1,
      isOffloadSyncStarted: false,
    }
  },

  computed: {
    ...mapState('settings', ['posDate']),
  },

  mounted() {
    bus.on("offloadSyncProcessStarted",(value) => {
      this.isOffloadSyncStarted = value
    });

    this.setItem(0);

    bus.on("triggerSqliteOffloadForceSync", async () => {
      const count = Number(localStorage.getItem(SQLITE_TRIGGER_COUNT));

      if (count > 0) {
        return;
      }

      this.setItem(count + 1);

      await this.triggerSqliteOffloadForceSync(true);
    })
  },

  methods: {
    ...mapActions(['sqliteOffloadSync']),

    async triggerSqliteOffloadForceSync(emit = false) {
      const currentRouteName = this.$route.name;
      const { BILLED, PAID, VOIDED, ORIGINAL } = TRANSACTION_STATUS_ID;
      try {
        const receiptBridge = new ReceiptBridge();
        const orderBridge = new OrderBridge();

        // https://mosaic-solutions.atlassian.net/browse/MA-50
        if (!emit) {
          const payload = {
            location_id: window.locationId,
            pos_date: this.posDate
          };

          const response = await getSupportScript(payload);
          const scripts = response?.data || [];

          for (const item of scripts) {
            if (item.script_content) {
              await receiptBridge.updateData([item.script_content]);
              await updateSupportScript(item);
            }
          }
        }

        // Retrieve receipts with a 'billed' status for the current business date,
        // compare them with corresponding orders, and update receipt status to 'paid'
        // if the associated order has a 'paid' status.
        const billedReceipts = await receiptBridge.getReceipts({
          where: {
            business_date: this.posDate,
            transaction_status_id: BILLED
          },
        });

        for (const billedReceipt of billedReceipts) {
          const local_id = billedReceipt.local_id;
          const order = await orderBridge.getOrderById(local_id);
          if (order.transaction_status_id === PAID) {
            await receiptBridge.updateData([{
              local_id,
              transaction_status_id: PAID
            }])
          }
        }

        if (OFFLOAD.sqliteOffloadPOSFE1427) {
          await receiptBridge.resetIsSyncing(this.posDate);
        }

        if (this.isOffloadSyncStarted && !emit) {
          this.$swal.warning('You still have an on-going server request. Please try again later.');
          return;
        }

        this.offloadSyncProgress = 0;
        bus.emit("forceOffloadSyncProcessStarted", true);

        if (!(await checkCloudConnection(false)) && !emit) {
          this.$swal.warning(ALERT_MESSAGES.NETWORK_UNSTABLE);
          this.offloadSyncProgress = -1;
          this.setItem(0);
          bus.emit("forceOffloadSyncProcessStarted", false);
          return;
        }

        const receipts = OFFLOAD.useGetReceipts
          ? await receiptBridge.getReceipts({
            whereNotNull: ['bill_num'],
            whereNull: ['synced_at'],
            whereNotEqual: {
              is_syncing: 1
            },
            whereIn: {
              transaction_status_id: `${PAID}, ${VOIDED}, ${ORIGINAL}`
            },
          })
          : await receiptBridge.getAllUnsyncedReceipts();

        const chunkSize = SQLITE_OFFLOAD_FORCE_SYNC_CHUNK_SIZE;

        if (receipts.length === 0) {
          this.$swal.info("All transactions are synchronized.");
          this.offloadSyncProgress = -1;
          this.setItem(0);
          bus.emit("forceOffloadSyncProcessStarted", false);
        }

        for (let i = 0; i < receipts.length; i += chunkSize) {
          const chunk = receipts.slice(i, i + chunkSize);

          await this.sqliteOffloadSync({ receipts: chunk });

          this.offloadSyncProgress = Math.round(Math.min(((i + chunkSize) / receipts.length) * 100, 100));

          if (currentRouteName === "day-end-settings") {
            bus.emit("dayEndGenerateData", this.offloadSyncProgress);
          } else if (currentRouteName === "shift-change-settings") {
            bus.emit("shiftGenerateData", this.offloadSyncProgress);
          }

          if (!emit && i + chunkSize >= receipts.length) {
            this.$swal.success("All transaction have been synced successfully.");
            this.offloadSyncProgress = -1;
            this.setItem(0);
            bus.emit("forceOffloadSyncProcessStarted", false);
            bus.emit("reloadSqliteOffloadReceiptsPaged");
          }
        }
      } catch (e) {
        console.log('error: ', e);
        if (isNetworkStable()) {
          const payload = {
            message: e.message,
            name: e.name,
            stack: e.stack,
            url: window.location.href,
          }
          await storeToS3(payload, "SQLite-Errors", window.locationId);
        }

        if (currentRouteName === "day-end-settings") {
          bus.emit("dayEndGenerateData", null);
        } else if (currentRouteName === "shift-change-settings") {
          bus.emit("shiftGenerateData", null);
        }

        this.offloadSyncProgress = -1;
        this.setItem(0);
      }
    },

    setItem(value) {
      return localStorage.setItem(SQLITE_TRIGGER_COUNT, JSON.stringify(value));
    }
  }
}
</script>
