<template>
  <div class="settings-page">
    <div class="d-flex flex-nowrap">
      <div class="px-lg-4 py-4 col-xl-8 d-flex align-items-center justify-content-between">
        <label>Settings Page</label>

        <sqlite-offload-force-sync v-if="isSqliteOffloadReceiptEnabled" class="d-none" />
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-xl-4 order-lg-12">
        <SettingsSidebar />
      </div>
      <div class="col-xl-3">
        <div class="tab-panel" id="no-selected" role="tabpanel">
            <div class="col-lg-4 col-md-12">

              <table class="dayend-tbl">
                <thead>
                  <tr>
                    <th class="cell100 column1">Denomination</th>
                    <th class="cell100 column2 pl-2">Bills</th>
                    <th class="cell100 column3 pl-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasCashFundPermission">
                    <denomination-item
                      v-for="denomination in billsDenomination"
                      :key="denomination"
                      :denomination="denomination"
                      :disabled="useWholeAmountInput"
                      @applyCashBreakdown="applyCashBreakdown"/>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">TOTAL CASH ON HAND: </td>
                      <td v-if="!useWholeAmountInput" class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td"> {{this.$filters.formatPrice(this.totalCash)}} </td>
                      <td v-else class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td">
                        <input type="number" v-model="totalCashOverride" />
                      </td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">+ CASH WITHDRAW: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.totalCashWithdraw)}}</td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- BEGINNING FUND: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.initialFund)}}</td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- ADDITIONAL FUND: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.totalAdditionalFund)}}</td>
                    </tr>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">= TOTAL CASH SALES: </td>
                      <td class="cell100 column3 text-center font-weight-bolder text-dark" id="x-read-cash-deposit-td">{{this.$filters.formatPrice(this.totalCashDeposit)}} </td>
                    </tr>
                  </template>
                  <template v-else>
                    <denomination-item
                      v-for="denomination in billsDenomination"
                      :key="denomination"
                      :denomination="denomination"
                      :disabled="useWholeAmountInput"
                      @applyCashBreakdown="applyCashBreakdown"/>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">TOTAL CASH: </td>
                      <td v-if="!useWholeAmountInput" class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td"> {{this.$filters.formatPrice(this.totalCash)}} </td>
                      <td v-else class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td">
                        <input type="number" v-model="totalCashOverride" />
                      </td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- CASH FLOAT: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.cashFloat[this.terminalId])}}</td>
                    </tr>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">= TOTAL CASH DEPOSIT: </td>
                      <td class="cell100 column3 text-center font-weight-bolder text-dark" id="x-read-cash-deposit-td">{{this.$filters.formatPrice(this.totalCashDeposit)}} </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <label class="ml-0 mr-3 mt-2 align-self-center" id="useWholeAmountInput">
              <input type="checkbox" class="mr-0" v-model="useWholeAmountInput" /> OVERRIDE TOTAL CASH INPUT?
              </label>
            </div>
        </div>
      </div>
      <div v-if="!isSqliteOffloadReceiptEnabled && dataGenerationProgressPercentage < 1" class="data-generation-progress col-xl-5">
        <no-items
          icon="spinner"
          :icon-class="{ 'fa-spin': true }"
          :text="`Generating xread data ${ $filters.formatPrice(dataGenerationProgressPercentage * 100) }%`"
          />
      </div>
      <div v-else-if="isSqliteOffloadReceiptEnabled && progressText !== ''" class="data-generation-progress col-xl-5">
        <no-items
          icon="spinner"
          :icon-class="{ 'fa-spin': true, 'mb-5': true }"
          :text="progressText"
        />
      </div>
      <div v-else class="col-xl-5">
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-5">
            <div class="ml-5 row">
              <div class="col-4">Current Shift:</div>
              <div class="col-4">Shift {{ currentShift.shift }}</div>
              <div class="col-4">{{ username }}</div>
            </div>
            <div class="row">
              <div class="w-100">
                <table class="dayend-tbl">
                  <thead>
                    <tr>
                      <th class="cell100 column1">Revenue</th>
                      <th class="cell100 column2 pl-2 text-right">Shiftwise</th>
                      <th class="cell100 column3 pl-2 text-right">Daywise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="revenue in revenueBreakdownPerShift" :key="revenue.name">
                      <td class="cell100 column1">{{revenue.name}}</td>
                      <td class="cell100 column2 pl-2 text-right "  v-show="showSpotAudit"> {{this.$filters.formatPrice(revenue.shiftwise)}}</td>
                      <td class="cell100 column3 text-right "  v-show="showSpotAudit"> {{this.$filters.formatPrice(revenue.daywise)}} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1 text-dark font-weight-bolder">TOTALS</td>
                      <td class="cell100 column2 text-dark font-weight-bolder pl-2 text-right " id="ttl-shiftwise-sales" v-show="showSpotAudit"> {{this.$filters.formatPrice(totalShiftwise)}} </td>
                      <td class="cell100 column3 text-dark font-weight-bolder text-right " id="ttl-daywise-sales" v-show="showSpotAudit"> {{this.$filters.formatPrice(totalDaywise)}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-5">
            <div class="row">
              <div class="w-100">
                <table class="dayend-tbl table-responsive">
                  <thead>
                    <tr>
                      <th class="cell100 column1">Payment Types</th>
                      <th class="cell100 column2 pl-2 text-right">Expected</th>
                      <th class="cell100 column3 pl-2 text-right">Payment Amount</th>
                      <th class="cell100 column3 pl-2 text-right">Over/(Short)</th>
                    </tr>
                  </thead>
                  <tbody>

                    <payment-breakdown-item
                      v-for="(breakdown, index) in paymentMethodsWithoutGCExcess"
                      :key="index"
                      :paymentType="breakdown.name"
                      :expectedAmount=breakdown.amount
                      :isCash="breakdown.isCash"
                      :totalCashDeposit="totalCashDeposit"
                      :showSpotAudit="showSpotAudit"
                      :isChangeShift="true"
                      @applyPaymentBreakdown="applyPaymentBreakdown"/>

                    <tr>
                      <td class="cell100 column1 text-dark font-weight-bolder">TOTALS</td>
                      <td class="cell100 column2 text-dark font-weight-bolder pl-2 text-right" id="x-read-ttl-sales"> <div class v-show="showSpotAudit">{{this.$filters.formatPrice(totalExpectedAmount)}}</div></td>
                      <td class="cell100 column3 text-right" id="x-read-payment-ttl"> {{this.$filters.formatPrice(totalPaymentAmount)}} </td>
                      <td class="cell100 column4 text-right " id="x-read-over-short-ttl-td" ><div class v-show="showSpotAudit">{{this.$filters.formatPrice(totalOverShortAmount)}}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-5">
            <div class="row">
              <div class="w-100">
                <table class="dayend-tbl">
                  <thead>
                    <tr>
                      <th class="cell100 column1">Detail</th>
                      <th class="cell100 column2 pl-2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="cell100 column1">Total Bills</td>
                      <td class="cell100 column2 pl-2" id="ttl-bill-td"> {{ this.totalBills }}</td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Avg per Check</td>
                      <td class="cell100 column2 pl-2" id="avg-check-td"> {{ this.$filters.formatPrice(this.avgPerCheck)  }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Total Pax</td>
                      <td class="cell100 column2 pl-2" id="ttl-pax-td"> {{ this.totalPax }}</td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Shift Total Amount</td>
                      <td class="cell100 column2 pl-2" id="ttl-amt-td"> {{ this.$filters.formatPrice(this.shiftTotalAmount) }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Total Amount</td>
                      <td class="cell100 column2 pl-2" id="ttl-amt-td"> {{ this.$filters.formatPrice(this.totalAmount) }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Table Turned</td>
                      <td class="cell100 column2 pl-2" id="ttl-tableturn-td"> {{ this.totalTableTurned }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="row">
              <div class="col-md-4">
                <button class="btn btn-prim my-1" id="save-shift-btn" @click="changeShift" :disabled="hasCashSales">CHANGE SHIFT</button>
              </div>
              <div class="col-md-4">
                <button class="btn btn-can my-1" id="cancel-eod-btn" @click="cancelShiftChange">CANCEL</button>
              </div>
              <div class="col-md-4">
                <button class="btn btn-prim my-1" id="show-over-SC-btn" @click="clickShowSpotAudit">Show Spot Audit </button>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="row mt-4">
              <div class="col-md-6">
                <button class="btn btn-prim my-1" @click="printXread">PRINT X-READ</button>
              </div>
              <div class="col-md-6">
                <BPrintZReadSections
                :sections="recieptDetails.print_zread_sections"
                @printSections="printSeparateSections"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
      <x-z-read-preview-modal
        reportType='xread'
        :recieptDetails="recieptDetails"
        :paymentMethods="paymentMethods"
        :incomeHeadSales="revenueBreakdownPerShift"
        :cashFloat="cashFloat[this.terminalId]"
        :cashAdditional="totalAdditionalFund"
        :cashWithdraw="totalCashWithdraw"
        :lastKot="lastKot"
        :lastBillNum="lastBillNum"
        :totalPax="totalPaxPerShift"
        :avgPerTransaction="avgPerCheckPerShift"
        :totalSales="totalSales"
        :totalVatableSales="totalVatableSales"
        :totalVatAmount="totalVatAmount"
        :totalNetSales="totalNetSales"
        :totalNetSalesWCharges="totalNetSalesWCharges"
        :totalVatExemptSales="totalVatExempSales"
        :totalZeroRatedSales="totalZeroRatedSales"
        :discountItems="totalPerDiscountItems"
        :serviceTypes="serviceTypesTotal"
        :totalCashValue="totalCash"
        :totalCashSales="totalCashSales"
        :totalOverShort="totalOverShortAmount"
        :totalServiceCharge="totalServiceCharge"
        :totalOtherCharges="totalOtherCharges"
        :totalBeforeServiceCharge="totalBeforeServiceCharge"
        :totalWOVoidAndServiceCharge="totalWOVoidAndServiceCharge"
        :beginningOR="previousReceiptNum"
        :endingOR="lastReceiptNum"
        :beginningSettlement="beginningSettlement"
        :endingSettlement="endingSettlement"
        :begBilledOrder="begBilledOrder"
        :endBilledOrder="endBilledOrder"
        :beginningBalance="beginningBalance"
        :endingBalance="endingBalance"
        :voidedSalesAmount="totalVoidedSales"
        :voidedBilledAmount="totalVoidedBilled"
        :beginningVoidAmount="beginningVoidAmount"
        :endingVoidAmount="endingVoidAmount"
        :beginningVoidOrder="begVoidOrder"
        :endingVoidOrder="lastVoidBillNum"
        :totalTransaction="totalTransaction"
        :beginningVoidNum="beginningVoidNum"
        :endingVoidNum="endingVoidNum"
        :receiptDate="receiptDateString"
        :totalGrossWithServiceCharge="totalGrossWithServiceCharge"
        :totalGrossWithServiceChargeWoVoid="totalGrossWithServiceChargeWoVoid"
        :totalVatAdjustment="totalVatAdjustment"
        :floats="currentPosDateDataFromDb.floats"
        :hourly-sales="hourlySales"
        :productMixData="generatePmixData()"
        :grandTotalPmix="generatePmixGrandTotal"
        :has-cash-fund-permission="hasCashFundPermission"
        :isPmixWPriceQtyAndAmountEnabled="isPmixWPriceQtyAndAmountEnabled"
        v-model="isXreadPreviewModalOpen"
      />

      <eod-preview-modal
        reportType='ccr'
        :recieptDetails="recieptDetails"
        :paymentMethods="paymentMethods"
        :incomeHeadSales="revenueBreakdown"
        :cashFloat="cashFloat[this.terminalId]"
        :cashWithdraw="totalCashWithdraw"
        :lastKot="lastKot"
        :lastBillNum="lastBillNum"
        :totalPax="totalPax"
        :avgPerTransaction="avgPerCheck"
        :totalVatableSales="totalVatableSales"
        :totalSales="totalSales"
        :totalVatAmount="totalVatAmount"
        :totalNetSales="totalNetSales"
        :totalNetSalesWCharges="totalNetSalesWCharges"
        :totalVatExemptSales="totalVatExempSales"
        :totalZeroRatedSales="totalZeroRatedSales"
        :discountItems="totalPerDiscountItems"
        :serviceTypes="serviceTypesTotal"
        :totalCashValue="totalCash"
        :totalCashSales="totalCashSales"
        :totalEODCashSales="totalEODCashSales"
        :totalNonCashSales="totalNonCashSales"
        :totalEODNonCashSales="totalEODNonCashSales"
        :totalOverShort="totalOverShortAmount"
        :totalServiceCharge="totalServiceCharge"
        :totalBeforeServiceCharge="totalBeforeServiceCharge"
        :beginningSettlement="beginningSettlement"
        :endingSettlement="endingSettlement"
        :begBilledOrder="begBilledOrder"
        :endBilledOrder="endBilledOrder"
        :beginningBalance="beginningBalance"
        :endingBalance="endingBalance"
        :voidedSalesAmount="totalVoidedSales"
        :voidedBilledAmount="totalVoidedBilled"
        :beginningVoidAmount="beginningVoidAmount"
        :endingVoidAmount="endingVoidAmount"
        :beginningVoidOrder="begVoidOrder"
        :endingVoidOrder="endVoidOrder"
        :totalTransaction="totalTransaction"
        :beginningVoidNum="previousVoidNum"
        :endingVoidNum="endingVoidNum"
        :receiptDate="receiptDateString"
        :beginningOR="previousReceiptNum"
        :endingOR="lastReceiptNum"
        :totalGrossWithServiceCharge = "totalGrossWithServiceCharge"
        :totalVatAdjustment="totalVatAdjustment"
        :nonRevenueOrders="nonRevenueOrders"
        :totalGrossNet="totalAmount"
        :hourly-sales="hourlySales"
        v-model="isEodPreviewModalOpen"
        :productMixData="generatePmixData()"
      />
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import DenominationItem from '@/spa/components/common/DenominationItem';
import PaymentBreakdownItem from '@/spa/components/common/PaymentBreakdownItem';
import {mapActions, mapMutations, mapState} from 'vuex';
import { pick, sumBy, uniqBy, orderBy, isEmpty } from 'lodash';
import cloneDeep from 'rfdc/default';
import {
  cashierLogout,
  getPaymentMethods,
  getReceiptDetails,
  getLoggedInUsers,
} from '@/spa/services/cashier-service';
import XZReadPreviewModal from '@/spa/components/modals/XZReadPreviewModal';
import EodPreviewModal from "@/spa/components/modals/EodPreviewModal";
import { getAllDiscountItems } from '@/spa/services/discount-service';
import { print } from "@/spa/services/printer-service";
import xReadHtml from '@/spa/components/templates/print/xzread.html';
import eodHtml from '@/spa/components/templates/print/eod.html';
import { convert } from 'html-to-text';
import { getSyncedOrders } from '@/spa/services/sync-service';
import moment from 'moment';
import { shiftChangeSync } from '@/spa/services/sync-service';
import { checkCloudConnection } from '@/spa/plugins/axios';
import {
  getReprintData,
  getReprintDataReceiptBased,
  getProductMixData,
  getProductMixDataReceiptBased,
} from '@/spa/services/dayend-service';

import NoItems from '@/spa/components/placeholders/NoItems';

import { VAT_EXCL } from '@/vue/helper/discount';

import {
  GET_BREAKDOWNS_FROM_DB as DEFAULT_GET_BREAKDOWNS_FROM_DB,
  PERMISSION_TYPES,
  ENABLE_EPEI_REQUIRE_BEG_CASH_FUND,
  ENABLE_FORBID_SHIFT_CHANGE_NO_CASH_INPUT,
  USE_SM_MARKETS_OR_FORMAT,
  ENABLE_SALES_CONSOLIDATOR,
  ENABLE_CASH_FUND_IMPROVEMENT,
  ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE,
  OFFLOAD,
  ALERT_MESSAGES,
  USE_RECEIPT_BASED,
  ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
} from '@/spa/constants';
import BPrintZReadSections from '@/spa/components/common/BPrintZReadSections';
import {SeparateSectionMixin} from '@/spa/components/mixins/ReportMixin'
import { IS_NON_VAT } from "@/spa/constants";
let GET_BREAKDOWNS_FROM_DB = DEFAULT_GET_BREAKDOWNS_FROM_DB;
import { clearStorage } from '@/spa/plugins/vuex';
import { clearAxiosCache } from '@/spa/plugins/axios';
import bus from '@/spa/utils/bus';
import SqliteOffloadForceSync from "@/spa/components/pages/components/SqliteOffloadForceSync";
import {generateDateTime, TRANSACTION_STATUS_ID} from "@/mobile_bridge/offload/receipt-model";
import {SHIFT_TABLE_LOCAL_ID} from "@/mobile_bridge/offload/shift-table";

export default {

  components: {
    SettingsSidebar,
    DenominationItem,
    PaymentBreakdownItem,
    XZReadPreviewModal,
    EodPreviewModal,
    NoItems,
    BPrintZReadSections,
    SqliteOffloadForceSync
  },

  mixins: [SeparateSectionMixin],

  data() {
    return {
      cashBreakDown: {},
      shiftBilledOrders: [],
      billedOrdersPerPosDate: [],
      settledOrderPerShift: [],
      revenueBreakdownPerShift: [],
      revenueBreakdownPerDay: [],
      totalShiftwise: 0,
      totalDaywise: 0,
      paymentBreakDown: [],
      computedPaymentBreakdown: [],
      totalPaymentAmount: 0,
      totalOverShortAmount: 0,
      paymentMethods: [],
      totalExpectedAmount: 0,
      showSpotAudit: false,
      isXreadPreviewModalOpen: false,
      recieptDetails: {},
      totalVatExempSales: 0,
      totalZeroRatedSales: 0,
      totalVatableSales: 0,
      totalNetSales: 0,
      totalNetSalesWCharges: 0,
      totalVatAmount: 0,
      totalPerDiscountItems: [],
      serviceTypesTotal: [],
      totalCashSales: 0,
      totalNonCashSales: 0,
      totalEODCashSales: 0,
      totalEODNonCashSales: 0,
      totalServiceCharge: 0,
      totalOtherCharges: 0,
      totalVoidedSales: 0,
      totalBeforeServiceCharge: 0,
      totalWOVoidAndServiceCharge: 0,
      beginningSettlement: {},
      endingSettlement: {},
      begBilledOrder: {},
      endBilledOrder: {},
      shiftVoidOrders: [],
      begVoidOrder:{},
      endVoidOrder: {},
      receiptDateString: '',
      orders: [],
      totalGrossWithServiceCharge: 0,
      totalGrossWithServiceChargeWoVoid: 0,
      totalSales: 0,
      totalVatAdjustment: 0,
      endingVoidNum: 0,
      totalItemVoidedSales: 0,
      beginningVoidNum: 0,
      receiptDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      isEodPreviewModalOpen: false,
      revenueBreakdown: [],
      nonRevenueOrders: [],
      dataGenerationProgressPercentage: 0,
      currentPosDateDataFromDb: {},
      reportTypeTitle: 'X-Reading',
      sectionsValues: {
        header: '',
        over_short: '',
      },
      useWholeAmountInput: false,
      totalCashOverride: null,
      isShiftChanged: false,
      currentShiftCashSales: [],
      reportType: 'xread',
      totalShiftCashSales: 0,
      hasCashFund: false,
      hasCashSales: false,
      hourlySales: {},
      isSqliteOffloadReceiptEnabled: OFFLOAD.sqliteOffloadReceipt,
      isPmixWPriceQtyAndAmountEnabled: USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
      sqliteOffloadOrders: [],
      sqliteOffloadProductMix: {},
      progressText: "Checking data...",
    };
  },

  computed: {

    ...mapState([
        'activeBrandId',
        'queueStatus',
    ]),

    ...mapState({ originalOrders: state => state.orders }),

    ...mapState('user', ['terminalId','username']),

    ...mapState('settings', ['cashFloat',
                            'terminalsCashFund',
                            'billsDenomination',
                            'shiftTable',
                            'posDate',
                            'beginningBalance',
                            'beginningVoid',
                            'beginningVoidAmount',
                            'currentShift',
                            'previousVoidNum',
                            'previousReceiptNum',
                            'serviceTypes',
                            'shiftChangeRecord',
                            'dayEndRecord',
                          ]),

    filterOrderPerShift () {
      if (OFFLOAD.sqliteOffloadReceipt) {
        return this.sqliteOffloadOrders.filter(order => {
          const billedShiftDateMatches = order?.billedShiftTable?.pos_date === this.posDate;
          const originShiftDateMatches = order?.originShiftTable?.pos_date === this.posDate;
          const shiftMatches = order?.billedShiftTable?.shift === this.currentShift.shift || order?.originShiftTable?.shift === this.currentShift.shift;

          return (billedShiftDateMatches || originShiftDateMatches) && shiftMatches;
        });
      }

      const billedOrders = uniqBy([...this.orders, ...this.originalOrders],'_id').filter(o => (o?.billedShiftTable?.pos_date == this.posDate || o?.originShiftTable?.pos_date == this.posDate) && (o?.billedShiftTable?.shift == this.currentShift.shift  || o?.originShiftTable?.shift == this.currentShift.shift));
      return billedOrders;
    },

    isQueueCompleted() {
      if (isEmpty(this.queueStatus)) {
        return true;
      }

      return !Object.values(this.queueStatus).some(s => s.is_sync == 0 || s == 0);
    },

    totalCash() {
      var sum = 0;
      if(this.useWholeAmountInput) {
        return this.totalCashOverride ?? 0;
      }
      for (var el in this.cashBreakDown ) {
        if(el in this.cashBreakDown) {
          sum += parseFloat( this.cashBreakDown[el] );
        }
      }
      return sum;
    },

    cashFunds() {
      return this.terminalsCashFund;
    },

    totalCashWithdraw() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.terminalId]['Withdraw'];
      }
      return 0;
    },

    totalAdditionalFund() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.terminalId]['Additional Fund'];
      }
      return 0;
    },

    initialFund() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.terminalId]['Beginning Fund'];
      }
      return 0;
    },

    totalCashDeposit() {
      // Cash Float includes Beginning Fund and Additional Fund
      return this.totalCash - this.cashFloat[this.terminalId] + this.totalCashWithdraw;
    },

    totalBills() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_paid_count;
      }

      return this.billedOrdersPerPosDate.filter(s => s.isSettled == true && !s.isVoided).length;
    },

    totalPax() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_guest_count;
      }

      const totalPax = this.billedOrdersPerPosDate.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.pax;
      }, 0);

      return totalPax;
    },

    totalPaxPerShift() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ttl_guest_count;
      }

      const totalPax = this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.pax;
      }, 0);

      return totalPax;
    },

    totalTableTurned() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_table_turned_cout;
      }

      // this still not sure if we need to add voided transaction for table turned.
      return this.billedOrdersPerPosDate.length;

    },

    shiftTotalAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ttl_gross_sales;
      }

      const shiftTotalAmount = this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return shiftTotalAmount;

    },

    voidedSalesAmount() {

      const voidedSalesAmount = this.shiftVoidOrders.reduce((accumulator, object) => {
        if (!object.totals) return accumulator;
        let total = object.totals.rawPrice;
        if(object.totals.isScInclusive == 0) // exclusive
          total += object.totals.serviceCharge;

        return accumulator + total;

      }, 0);

      return voidedSalesAmount;

    },

    totalAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_total_amount;
      }

      const totalAmount = this.billedOrdersPerPosDate.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return totalAmount;

    },

    avgPerCheck() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_avg_per_trans;
      }

      const settledBilled = this.billedOrdersPerPosDate.filter(s => s.isSettled == true && !s.isVoided);

      const shiftTotalAmount = settledBilled.reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return shiftTotalAmount / settledBilled.length;

    },

    avgPerCheckPerShift() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.avg_per_trans;
      }

      const settledBilled = this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided);

      const shiftTotalAmount = settledBilled.reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      let total = (shiftTotalAmount / settledBilled.length);
      total = isNaN(total) ? 0 : total

      return total;

    },

    endingBalance() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ending_bal
      }

      if (USE_SM_MARKETS_OR_FORMAT) {
        return this.beginningBalance + this.totalAmount + this.totalDiscounts + this.totalServiceCharge;
      } else {
        return this.totalNetSales + this.beginningBalance;
      }
    },

    beginningVoidAmountOverride() {
      return this.currentPosDateDataFromDb.beg_void_amt;
    },

    endingVoidAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ttl_void_amount;
      }

      return this.totalVoidedSalesAmount + this.beginningVoidAmount;
    },

    totalTransaction() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.total_transaction_count;
      }

      return this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided).length;
    },

    totalDiscounts() {

      const totalDiscounts = this.totalPerDiscountItems.reduce((accumulator, object) => {
        return accumulator + object.amount;
      }, 0);

      return totalDiscounts;
    },

    lastKotNum() {

      // last kot will accumulate once its settled or voided
      const billedAndVoidOrders = this.filterOrderPerShift.filter(s => (s?.billedShiftTable?.shift == this.currentShift.shift || s?.originShiftTable?.shift == this.currentShift.shift) && s?.originShiftTable?.pos_date == this.posDate);
      if(billedAndVoidOrders.length > 0){
        const lastbilledAndVoidOrders = billedAndVoidOrders[billedAndVoidOrders.length - 1];
        const kots = lastbilledAndVoidOrders.kots;
        return Math.max(...kots);
      }

      return this.lastKot
    },

    totalVoidedSalesAmount() {
      return this.totalItemVoidedSales + this.voidedSalesAmount;
    },

    paymentMethodsWithoutGCExcess() {
      return this.paymentMethods.filter(paymentMethod => paymentMethod.name !== 'GC EXCESS');
    },

    hasCashSales() {
      return ENABLE_FORBID_SHIFT_CHANGE_NO_CASH_INPUT
      && this.settledOrderPerShift.some(order =>
        order
        && order.isSettled
        && ("isVoided" in order ? !order.isVoided : true)
        && order.originShiftTable?.pos_date == this.posDate
        && order.payments?.some(o => o.amount > 0 && (/^cash\.?$/i).test(o.method))
      )
      && this.totalCashDeposit <= 0;
    },

    hasCashFundPermission() {
      return ENABLE_CASH_FUND_IMPROVEMENT || (this.$can(this.PERMISSIONS.CASH_FUND_MANAGEMENT) || false);
    },

    generatePmixGrandTotal() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb?.product_mix_grand_total;
      }

      return this.computePmixGrandTotal(this.generatePmixData());
    }
  },

  async mounted() {
    bus.on("shiftGenerateData", (value) => {
      if (value != null) {
        this.progressText = `Syncing data ${value}%`;

        if (value >= 100) {
          setTimeout(async() => {
            await this.fetchCurrentPosDateData();
            await this.generateData();
          }, 300)
        }

        return;
      }

      Swal.fire({
        text: "An error occurred during syncing. Please try again.",
        confirmButtonText: 'Retry Sync',
        allowOutsideClick: false,
        icon: 'error',
        customClass: {
          actions: 'swal-button-nowrap'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          bus.emit("triggerSqliteOffloadForceSync");
        }
      })
    })

    if (!this.$can(this.PERMISSIONS.DAY_END) && !ENABLE_SALES_CONSOLIDATOR) {
        this.$swal.warning('You do not have permission to shift change. Please contact your administrator.');
        this.$router.push({ name: 'home' });
        return;
    }

    //if syncing or unsync show loading
    if(!this.isQueueCompleted) {
      this.transactionsNotSyncedAlert();
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
      const { PAID, VOIDED, ORIGINAL } = TRANSACTION_STATUS_ID;
      const rb = new ReceiptBridge();
      const receipts = OFFLOAD.useGetReceipts
        ? await rb.getReceipts({
          whereNotNull: ['bill_num'],
          whereNull: ['synced_at'],
          whereNotEqual: {
            is_syncing: 1
          },
          whereIn: {
            transaction_status_id: `${PAID}, ${VOIDED}, ${ORIGINAL}`
          },
        })
        : await rb.getAllUnsyncedReceipts();
      if (receipts.length > 0) {
        this.progressText = `Syncing data 0%`;
        bus.emit("triggerSqliteOffloadForceSync");

        return;
      }
    }

    if (GET_BREAKDOWNS_FROM_DB) {
      await this.fetchCurrentPosDateData();
    }

    this.orders = await getSyncedOrders();

    await this.generateData();
  },

  methods: {
    ...mapMutations('settings', ['setShiftChangeRecord', 'setBeginningBalance', 'setBeginningVoidAmount']),
    ...mapActions(['forceSyncOrders']),

    async generateData() {
      if (OFFLOAD.sqliteOffloadReceipt) {
        const {PAID,VOIDED,ORIGINAL} = TRANSACTION_STATUS_ID;
        const ob = new OrderBridge();
        // paid, voided and original orders
        const orders = await ob.getPendingOrders(`${PAID}, ${VOIDED}, ${ORIGINAL}`);
        this.sqliteOffloadOrders = orders.map(order => JSON.parse(order.order_data));

        const rcb = new ReceiptContentBridge();
        this.sqliteOffloadProductMix = await rcb.generatePMIX(this.posDate, this.currentShift.shift);
      }

      this.filterOrderBilledPerShift();
      this.filterOrderBilledPerPosDate();
      this.filterSettledOrderPerShift();

      this.setDataGenerationProgressPercentage(0);
      await this.filterVoidOrderPerShift();
      this.setDataGenerationProgressPercentage(1/8);
      await this.fetchPaymentMethods();
      this.setDataGenerationProgressPercentage(2/8);
      await this.generateRevenueBreakdownPerDay();
      this.setDataGenerationProgressPercentage(3/8);
      await this.generateRevenueBreakdownPerShift();
      this.setDataGenerationProgressPercentage(4/8);
      await this.generatePaymentBreakdown();
      this.setDataGenerationProgressPercentage(5/8);
      await this.parseReceiptDetails();
      this.setDataGenerationProgressPercentage(6/8);
      await this.fetchDiscountItems();
      this.setDataGenerationProgressPercentage(7/8);
      await this.generateXreadDetails();
      this.setDataGenerationProgressPercentage(1);

      this.progressText = "";

      this.computeTotalsInPaymentTypes();
    },

    transactionsNotSyncedAlert(shouldRedirect = false) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        this.$router.push({ name: 'settings' });
        return;
      }
      Swal.fire({
        title: 'Transactions are not yet fully synced. Please wait for sync to finish for accurate reports.',
        icon: 'warning',
      }).then((result) => {
        if (result.isConfirmed && shouldRedirect) this.$router.push({ name: 'home' });
      });
    },

    async fetchDiscountItems() {

      this.totalPerDiscountItems = [];

      try {
          const response = await getAllDiscountItems();
          this.totalPerDiscountItems = response.data.discounts
              .map(d => ({ name: d.discount_name, amount: 0}));
      } catch (e) {
          console.error(e);
      }

    },

    async fetchCurrentPosDateData(rawData = 1, ignoreCache = false) {
      try {
          if (USE_RECEIPT_BASED) {
            const { data } = await getReprintDataReceiptBased({
              zReadDate: this.posDate,
              posDate: this.posDate,
              locationId: this.locationId,
              terminalId: this.terminalId,
              userId: this.userId,
              dayEndData: {
                cash_float: this.cashFloat[this.terminalId]?? 0,
                cash_sales: this.totalCashSales + this.totalCashWithdraw,
                over_short: this.totalOverShortAmount,
              },
              shiftData: {},
              xRead: true,
              isRawData: rawData,
              rawData,
              ignoreCache,
              useSqliteOffload: OFFLOAD.sqliteOffloadReceipt
            });
            if (rawData) {
              this.currentPosDateDataFromDb = data;
            }
          } else {
            const { data } = await getReprintData(this.posDate);
            if (rawData) {
              this.currentPosDateDataFromDb = data;
            }
          }
          console.log({ data: cloneDeep(this.currentPosDateDataFromDb) });
        } catch (e) {
          console.error(e);
          Swal.fire({
            title: 'Error',
            text: 'Unable to fetch data from database. Please check your connection and refresh the page.',
            icon: 'error',
          }).then((result) => {
            if (result.isConfirmed) this.transactionsNotSyncedAlert(true);
          });
        }
    },

    setDataGenerationProgressPercentage(percentage) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        this.progressText = `Generating X-Read data ${ this.$filters.formatPrice(percentage * 100) }%`;

        return;
      }

      this.dataGenerationProgressPercentage = percentage;
    },

    async fetchPaymentMethods() {
        this.paymentMethods = [];
        try {
            const response = await getPaymentMethods();
            this.paymentMethods = response.data
                .filter(p => p.brand_id == this.activeBrandId)
                .map(p => ({ name: p.payment_name, amount: 0, isCash: p.payment_name === "CASH"}));
        } catch (e) {
            console.error(e);
        }
    },

    async generatePaymentBreakdown() {
      let gcExcess = {amount: 0, count: 0};

      if (GET_BREAKDOWNS_FROM_DB) {
        this.paymentMethods = Object.keys(this.currentPosDateDataFromDb.tender_summary)
          .map(name => ({ name, amount: this.currentPosDateDataFromDb.tender_summary[name], isCash: name.toUpperCase() === "CASH"}));

        this.totalCashSales = 0;
        this.totalNonCashSales = 0;
        this.totalEODCashSales = 0;
        this.totalEODNonCashSales = 0;

        this.paymentMethods.forEach(p => {
          if (p.name.toUpperCase() === 'CASH') {
            this.totalCashSales += parseFloat(p.amount);
            this.totalEODCashSales += parseFloat(p.amount);
          } else {
            this.totalNonCashSales += parseFloat(p.amount);
            this.totalEODNonCashSales += parseFloat(p.amount);
          }

          this.totalExpectedAmount += parseFloat(p.amount);
        });

        return;
      }

      this.shiftBilledOrders.forEach((fs) => {
        if (fs.isVoided) return;
        if (!fs.payments || fs.payments.length === 0) {
          fs.payments = fs.splits?.map(s => s.payments || []).flat() || [];
        }
        fs.payments.forEach((p, i) => {
          let objIndex = this.paymentMethods.findIndex((obj) => obj.name == p.method);
          let amount = parseFloat(p.exact_amount);
          if (i === fs.payments.length - 1) {
            amount -= parseFloat(fs.change ?? 0);
          }

          if (objIndex < 0) {
            this.paymentMethods.push({
              name: p.method,
              amount: 0,
              isCash: p.method.toUpperCase() === "CASH",
            });
            objIndex = this.paymentMethods.length - 1;
          }

          this.paymentMethods[objIndex].amount += amount;
          this.paymentMethods[objIndex].count ??= 0;
          this.paymentMethods[objIndex].count += 1;
          this.totalExpectedAmount += amount;

          if (this.paymentMethods[objIndex].name == "CASH") {
            this.totalEODCashSales += amount;
            this.totalCashSales += amount;
          } else {
            this.totalEODNonCashSales += amount;
            this.totalNonCashSales += amount;
          }
        }, this);

        if (this.checkIfHasGcExcess(fs)) {
          gcExcess.amount += fs.change;
          gcExcess.count += 1;
        }
      });

      if(gcExcess.amount) {
        this.paymentMethods = [
            ...this.paymentMethods,
            { name: 'GC EXCESS', amount: gcExcess.amount, isCash: false, count: gcExcess.count }
        ]
      }
    },

    async parseReceiptDetails() {
        try {
            let receiptResponse = await getReceiptDetails();
            this.recieptDetails = Object.values(receiptResponse.data).find(d=>d.brand_id == this.activeBrandId);
        } catch (error) {
            console.error(error);
        }

        return {};
    },

    async generateXreadDetails() {
      if (GET_BREAKDOWNS_FROM_DB) {
        this.nonRevenueOrders = []; // have to get from local
        this.serviceTypesTotal = Object.keys(this.currentPosDateDataFromDb.service_types)
          .map(name => ({
            name,
            amount: this.currentPosDateDataFromDb.service_types[name].value,
            count: this.currentPosDateDataFromDb.service_types[name].count,
          }));
        this.hourlySales = this.currentPosDateDataFromDb.hourly_sales;
        this.totalBeforeServiceCharge = this.currentPosDateDataFromDb.gross_wo_svc_charge;
        this.totalWOVoidAndServiceCharge = this.currentPosDateDataFromDb.gross_wo_svc_charge_and_void_sales;
        this.totalGrossWithServiceCharge = this.currentPosDateDataFromDb.gross_sales_w_svc;
        this.totalGrossWithServiceChargeWoVoid = this.currentPosDateDataFromDb.gross_sales_w_svc_wo_void_sales;
        this.totalItemVoidedSales = this.currentPosDateDataFromDb.void_balance;
        this.totalNetSales = this.currentPosDateDataFromDb.ttl_net_sales;
        this.totalNetSalesWCharges = this.currentPosDateDataFromDb.ttl_net_sales_w_charges;
        this.totalPerDiscountItems = Object.keys(this.currentPosDateDataFromDb.discounts)
          .map(name => ({
            name,
            amount: this.currentPosDateDataFromDb.discounts[name],
          }));
        this.totalSales = this.currentPosDateDataFromDb.ttl_gross_sales;
        this.totalServiceCharge = this.currentPosDateDataFromDb.paid_svc_charge;
        this.totalVoidedSales = this.currentPosDateDataFromDb.void_balance;
        this.totalVoidedBilled = this.currentPosDateDataFromDb?.void_billed_balance?? 0;
        this.totalVatableSales = this.currentPosDateDataFromDb.ttl_vatable_sales;
        this.totalVatAdjustment = this.currentPosDateDataFromDb.vat_adjustment;
        this.totalVatAmount = this.currentPosDateDataFromDb.ttl_vat_amount;
        this.totalVatExempSales = this.currentPosDateDataFromDb.vats['Vat Exempt Sales'];
        this.totalZeroRatedSales = this.currentPosDateDataFromDb.vats['Zero Rated Sales'];

        this.beginningSettlement = {
          bill_num: this.currentPosDateDataFromDb.first_bill,
          receipt_num: this.currentPosDateDataFromDb.beg_invoice,
          void_bill_num: this.currentPosDateDataFromDb.beg_void,
        };
        this.begBilledOrder = this.beginningSettlement;
        this.endingSettlement = {
          bill_num: this.currentPosDateDataFromDb.ending_bill ?? this.currentPosDateDataFromDb.first_bill,
          receipt_num: this.currentPosDateDataFromDb.end_invoice ?? this.currentPosDateDataFromDb.beg_invoice,
          void_bill_num: this.currentPosDateDataFromDb.end_void ?? this.currentPosDateDataFromDb.beg_void,
        };
        this.endBilledOrder = this.endingSettlement;

        this.begVoidOrder = this.begBilledOrder;
        this.endVoidOrder = this.endBilledOrder;
        this.beginningVoidNum = this.begVoidOrder.void_bill_num;
        this.endingVoidNum = this.endVoidOrder.void_bill_num;

        //other charges
        this.totalOtherCharges = this.currentPosDateDataFromDb.ttl_other_charges ?? this.totalOtherCharges;

        this.setBeginningBalance(this.currentPosDateDataFromDb.beginning_bal);
        this.setBeginningVoidAmount(this.currentPosDateDataFromDb.beg_void_amt);

        return;
      }

      this.serviceTypesTotal = [];

      this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided).forEach(
        sbo => {

          let vatExemptSales = sbo.totals.vatExemptSales || 0;
          let zeroRatedSales = sbo.totals.zeroRatedSales || 0;
          this.totalVatExempSales += vatExemptSales;
          this.totalZeroRatedSales += zeroRatedSales;
          this.totalSales += sbo.totals.total;
          this.totalNetSales += sbo.totals.net;
          this.totalVatAmount += sbo.totals.vat;
          this.totalServiceCharge += sbo.totals.serviceCharge;

          let grossWoSc = sbo.totals.rawPrice;
          let grossWithSC = sbo.totals.rawPrice;

          if(sbo.totals.isScInclusive == 1){ // inclusive
            grossWoSc -= sbo.totals.serviceCharge;
          } else { // exclusive
            grossWithSC += sbo.totals.serviceCharge;
          }

          //other charges
          if(sbo.totals.otherCharges) {
            for(const chargeName in sbo.totals.otherCharges) {
              this.totalOtherCharges += sbo.totals.otherCharges[chargeName];
            }
          }

          this.totalBeforeServiceCharge += grossWoSc;
          this.totalGrossWithServiceCharge += grossWithSC
          this.totalGrossWithServiceChargeWoVoid += grossWithSC

          let vatEx = vatExemptSales + zeroRatedSales;
          this.totalVatableSales += (sbo.totals.net - vatEx);

          if (USE_SM_MARKETS_OR_FORMAT) {
            this.totalNetSalesWCharges = this.totalNetSales + this.totalVatAmount + this.totalServiceCharge + this.totalOtherCharges;
          } else {
            this.totalNetSalesWCharges = this.totalSales;
          }

          sbo.orders.filter(s => !s.isVoided).forEach(o => {
            let serviceName = this.serviceTypes.filter(st => st.id === o.serviceTypeId)[0].service_name;

            let serviceTypeIndex = this.serviceTypesTotal.findIndex((obj => obj.name == serviceName));
            if(serviceTypeIndex < 0) {
              this.serviceTypesTotal.push({ name: serviceName, amount: o.totals.net, count: 1 })
            } else {
              this.serviceTypesTotal[serviceTypeIndex].amount += o.totals.net;
              this.serviceTypesTotal[serviceTypeIndex].count++;
            }
          });

          // get non revenue orders
          let nonRev = sbo.orders.filter(o => o.product.product_group.product_category.is_non_revenue == 1);
          if(nonRev.length > 0) {
            this.nonRevenueOrders[sbo._id] = nonRev;
          }

          sbo.orders.filter(s => !s.isVoided).forEach(
            o => {
              let objIndex = null;
              if('discount' in o) {
                objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == o.discount.discount_name));
                this.totalPerDiscountItems[objIndex].amount += o.discount.discountAmount;
              } else if('comp' in o) {
                objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == o.comp.compName));
                this.totalPerDiscountItems[objIndex].amount += o.comp.compAmount;
              }

              // POSUAR-165
              if (USE_SM_MARKETS_OR_FORMAT && o.discount) {
                this.totalVatExempSales += o.discount.discountAmount;
              }
            }
          )

          sbo.orders.filter(s => s.isVoided && !s.isCancelled).forEach(
            o => {

              const totals = o.preVoidTotals ?? o.totals;
              this.totalItemVoidedSales += parseFloat(totals.total);
              let voidedGrossWoSc = totals.rawPrice;
              let voidedGrossWithSC = totals.rawPrice;

              if(sbo.totals.isScInclusive == 1){ // inclusive
                voidedGrossWoSc -= totals.serviceCharge;
              } else { // exclusive
                voidedGrossWithSC += totals.serviceCharge;
              }

              this.totalBeforeServiceCharge += voidedGrossWoSc;
              this.totalGrossWithServiceCharge += voidedGrossWithSC;

            }
          )

          if(sbo.billDiscount) {

            let discountAmount = 0;

            if(sbo.billDiscount.amount)
              discountAmount = sbo.billDiscount.amount;

            let objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == sbo.billDiscount.discount.discount_name));
            if (objIndex < 0) {
              this.totalPerDiscountItems.push({ name: sbo.billDiscount.discount.discount_name, amount: 0 });
              objIndex = this.totalPerDiscountItems.length - 1;
            }

            this.totalPerDiscountItems[objIndex].amount += discountAmount;

            // POSUAR-165
            if (USE_SM_MARKETS_OR_FORMAT && sbo.billDiscount) {
              this.totalVatExempSales += sbo.billDiscount.amount;
            }
          }

          this.totalVatAdjustment = this.totalPerDiscountItems.reduce((a, b) => {
            if (VAT_EXCL.includes(b.name)) {
              return a + b.amount;
            }
            return a;
          }, 0) * 0.6; // 12%/20% = 60%
        }
      )

      const filteredSettlements = orderBy(this.shiftBilledOrders, 'receipt_num');

      if(filteredSettlements.length > 0) {
        const totalSettlements = filteredSettlements.length;
        this.beginningSettlement = filteredSettlements[0];
        this.endingSettlement = filteredSettlements[totalSettlements -1];
      } else {
        this.beginningSettlement.receipt_num = this.lastReceiptNum;
        this.endingSettlement.receipt_num = this.lastReceiptNum;
      }

      this.setBillNumSeries();

      let voidOrders = this.shiftVoidOrders.filter(s => s.void_bill_num != null);
      voidOrders.sort((a, b) => a.void_bill_num - b.void_bill_num);

      if(voidOrders.length > 0) {
        this.begVoidOrder = voidOrders[0];
        this.beginningVoidNum = this.begVoidOrder.void_bill_num;
        this.endingVoidNum = voidOrders[voidOrders.length - 1].void_bill_num;
      } else {
        this.beginningVoidNum = this.lastVoidBillNum;
        this.endingVoidNum = this.lastVoidBillNum;
      }

      // POS2-1164 LineItemVoid then full bill void issue
      // CAN BE DELETED IF WILL CAUSE COMPUTATIONS PROBLEMS
      // THIS IS AN OVERRIDING CODE BLOCK FOR totalItemVoidedSales
      this.filterOrderPerShift.forEach(fOPS => {
        fOPS.orders.filter(s => s.isVoidAfterBill).forEach(
          o => {
            this.totalItemVoidedSales += parseFloat(o.totals.total);
            let voidedGrossWoSc = o.totals.rawPrice;
            let voidedGrossWithSC = o.totals.rawPrice;

            if(fOPS.totals.isScInclusive == 1){ // inclusive
              voidedGrossWoSc -= o.totals.serviceCharge;
            } else { // exclusive
              voidedGrossWithSC += o.totals.serviceCharge;
            }

            this.totalBeforeServiceCharge += voidedGrossWoSc;
            this.totalGrossWithServiceCharge += voidedGrossWithSC;

          }
        )}
      );


      this.shiftVoidOrders.forEach(
        vo => {
          if (!vo.totals) return;
          let grossWithoutSc = vo.totals.rawPrice;
          let grossWithSC = vo.totals.rawPrice;

          if(vo.totals.isScInclusive == 1){ // inclusive
            grossWithoutSc -= vo.totals.serviceCharge;
          } else { // exclusive
            grossWithSC += vo.totals.serviceCharge;
          }
          this.totalBeforeServiceCharge += grossWithoutSc;
          this.totalGrossWithServiceCharge += grossWithSC
        }
      );

    },

    applyCashBreakdown(data) {

      this.cashBreakDown[data.denomination] = parseFloat(data.totalAmount);

    },

    applyPaymentBreakdown(data) {
      this.computedPaymentBreakdown[data.paymentType] = pick(data, ['paymentAmount', 'overShortAmount','paymentType']);
      this.computeTotalsInPaymentTypes();
    },

    computeTotalsInPaymentTypes() {
      let totalAmount = this.paymentMethods.reduce((acc, item) => {
        if (item.name.toUpperCase() !== 'CASH') {
          acc += item.amount;
        }
        return acc;
      }, 0); // this will need to restart the computation
      let overShort = 0;
      const breakdowns = Object.values(this.computedPaymentBreakdown);
      totalAmount += sumBy(breakdowns, b => parseFloat(b.paymentAmount));
      overShort += sumBy(breakdowns, b => parseFloat(b.overShortAmount));

      if(this.cashFloat[this.terminalId] > 0 && overShort === 0 && this.totalCash === 0) {
        overShort = (parseFloat(this.cashFloat[this.terminalId]) + this.totalCashSales - this.totalCashWithdraw) * -1;
      }

      this.totalPaymentAmount = totalAmount;
      this.totalOverShortAmount = overShort;
    },

    filterOrderBilledPerShift() {

      this.shiftBilledOrders = this.filterOrderPerShift.filter(function(order) {
        if(order.isBilled && order?.billedShiftTable?.shift == this.currentShift.shift && order?.billedShiftTable?.pos_date == this.posDate && !order.isVoided)
          return true;
      },this);

    },

    setBillNumSeries() {
      let shiftOrders = this.filterOrderPerShift.filter(function(order) {
        if(order.isBilled && order?.billedShiftTable?.shift == this.currentShift.shift && order?.billedShiftTable?.pos_date == this.posDate)
          return true;
      },this);

      if(shiftOrders.length > 0) {
        shiftOrders.sort((a,b) => a.bill_num - b.bill_num);
        const totalBilled = shiftOrders.length;
        this.begBilledOrder = shiftOrders[0];
        this.endBilledOrder = shiftOrders[totalBilled - 1];
      } else {
        this.begBilledOrder.bill_num = this.lastBillNum;
        this.endBilledOrder.bill_num = this.lastBillNum;
      }
    },

    filterVoidOrderPerShift() {
      this.shiftVoidOrders = this.filterOrderPerShift.filter(o => o.isVoided).map(o => ({ ...o, void_bill_num: o.void_bill_num || o.void_receipt_num }));
    },

    filterOrderBilledPerPosDate() {
      this.billedOrdersPerPosDate = this.orders.filter(function(order) {
        if(order.isBilled && order?.billedShiftTable?.pos_date == this.posDate)
          return true;
      },this);

    },

    filterSettledOrderPerShift() {
      this.settledOrderPerShift = this.orders.filter(function(order) {

        if(order.isSettled && order?.billedShiftTable?.shift == this.currentShift.shift && order?.billedShiftTable?.pos_date == this.posDate )
          return true;
      },this);
    },

    generateVatAdjustmentString(ttlVatAdj) {
      return `<tr>
                  <td valign="top">VAT ADJUSTMENT </td>
                  <td valign="top">${this.$filters.formatPrice(ttlVatAdj ?? 0)}</td>
              </tr>`;
    },

    generatePmixData() {
      if (OFFLOAD.sqliteOffloadReceipt) {
        return this.sqliteOffloadProductMix;
      }

      let data = {};
      this.settledOrderPerShift?.forEach(so => {
        so.orders.filter(order => !order.isVoided).forEach(o => {
          let serviceType = so.serviceType;
          let groupName = "Group Name: " + (o.product?.product_group?.group_name || "No Group Name");
          let modifiersArr =  o.product?.forcedMods ? o.product?.forcedMods?.concat(o.product?.unforcedMods) : [];
          if(!data[serviceType]) {
            data[serviceType] = {};
          }

          if(!data[serviceType][groupName]) {
            data[serviceType][groupName] = {};
          }

          if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {

            if(!data[serviceType][groupName][o.product.product_name]) {
              data[serviceType][groupName][o.product.product_name] = []
            }
            data[serviceType][groupName][o.product.product_name]['qty'] ??= 0;
            data[serviceType][groupName][o.product.product_name]['orig_price'] ??= 0;
            data[serviceType][groupName][o.product.product_name]['total_price'] ??= 0;

            data[serviceType][groupName][o.product.product_name]['qty'] += o.quantity;
            if (o.product.pricings.length > 0) {
              data[serviceType][groupName][o.product.product_name]['orig_price'] = parseFloat(o.product.pricings[0]['prod_price'] ?? "0");
              data[serviceType][groupName][o.product.product_name]['total_price'] = data[serviceType][groupName][o.product.product_name]['qty'] * data[serviceType][groupName][o.product.product_name]['orig_price'];
            }
            //loop through modifiers array
            if (modifiersArr.length > 0) {
              modifiersArr.forEach(ma => {
                let modGroupName = "Mod Group Name: " + ma.mod_group_name;
                if(!data[serviceType][modGroupName]) {
                  data[serviceType][modGroupName] = {};
                }

                if(!data[serviceType][modGroupName][ma.modifier_name]) {
                  data[serviceType][modGroupName][ma.modifier_name] = []
                }

                data[serviceType][modGroupName][ma.modifier_name]['qty'] ??= 0;
                data[serviceType][modGroupName][ma.modifier_name]['orig_price'] ??= 0;
                data[serviceType][modGroupName][ma.modifier_name]['total_price'] ??= 0;

                data[serviceType][modGroupName][ma.modifier_name]['qty'] += ma.quantity;
                data[serviceType][modGroupName][ma.modifier_name]['orig_price'] = parseFloat(ma.mod_price ?? "0");
                data[serviceType][modGroupName][ma.modifier_name]['total_price'] = data[serviceType][modGroupName][ma.modifier_name]['qty'] * data[serviceType][modGroupName][ma.modifier_name]['orig_price'];

              });
            }
          } else {
            data[serviceType][groupName][o.product.product_name] ??= 0;
            data[serviceType][groupName][o.product.product_name] += o.quantity;
            //loop through modifiers array
            if (modifiersArr.length > 0) {
              modifiersArr.forEach(ma => {
                let modGroupName = "Mod Group Name: " + ma.mod_group_name;
                if(!data[serviceType][modGroupName]) {
                  data[serviceType][modGroupName] = {};
                }

                data[serviceType][modGroupName][ma.modifier_name] ??= 0;
                data[serviceType][modGroupName][ma.modifier_name] += ma.quantity;
              });
            }
          }
        });
      });

      return data;
    },

    async generateRevenueBreakdownPerShift() {
      if (GET_BREAKDOWNS_FROM_DB) {
        this.revenueBreakdown = Object.keys(this.currentPosDateDataFromDb.categories)
          .map(name => ({
            name,
            qty: this.currentPosDateDataFromDb.categories[name.toLocaleLowerCase()+'_qty'],
            amount: this.currentPosDateDataFromDb.categories[name],
            daywise: this.currentPosDateDataFromDb.categories[name],
            shiftwise: this.currentPosDateDataFromDb.categories[name],
          })).filter(item => typeof item.qty !== 'undefined');
        this.revenueBreakdownPerShift = this.revenueBreakdown;
        this.revenueBreakdownPerDay = this.revenueBreakdown;
        this.totalDaywise = Object.values(this.currentPosDateDataFromDb.categories).reduce((a, b) => a + b, 0);
        this.totalShiftwise = this.totalDaywise;
        return;
      }

      let revenueBreakdown = [];
      let totalRevPerShift = 0;
      this.shiftBilledOrders.filter(s => s.isSettled == true && !s.isVoided).forEach(
        sbo => sbo.orders.filter(s => !s.isVoided).forEach(
          o => {

            let totalNet = o.totals.net;

            if(sbo.billDiscount) {
              if(sbo.billDiscount.discount.discount_rate)
                totalNet -= ((totalNet * sbo.billDiscount.discount.discount_rate) / sbo.pax) * sbo.billDiscount.discountQuantity;
              else if(sbo.billDiscount.discount.discount_amount)
                totalNet -= sbo.billDiscount.discount.discount_amount / sbo.orders.filter(s => !s.isVoided).length;
            }

            let revenueObj = {
              name : o.product.category,
              amount : totalNet,
            }
            revenueBreakdown.push(revenueObj);
          }
        )
      )
      var result = [];
      var self = this;
      revenueBreakdown.reduce(function(res, value) {
        if (!res[value.name]) {
          res[value.name] = { name: value.name, shiftwise: value.amount, daywise : self.revenueBreakdownPerDay[value.name] };
          result.push(res[value.name])
        } else {

          res[value.name].shiftwise += value.amount;

        }
        totalRevPerShift += value.amount;
        return res;
      }, {});

      this.revenueBreakdownPerShift = result;
      this.totalShiftwise = totalRevPerShift;

    },
    async generateRevenueBreakdownPerDay() {
      if (GET_BREAKDOWNS_FROM_DB) {
        this.revenueBreakdown = Object.keys(this.currentPosDateDataFromDb.categories)
          .map(name => ({
            name,
            qty: this.currentPosDateDataFromDb.categories[name.toLocaleLowerCase()+'_qty'],
            amount: this.currentPosDateDataFromDb.categories[name],
            daywise: this.currentPosDateDataFromDb.categories[name],
            shiftwise: this.currentPosDateDataFromDb.categories[name],
          })).filter(item => typeof item.qty !== 'undefined');
        this.revenueBreakdownPerShift = this.revenueBreakdown;
        this.revenueBreakdownPerDay = this.revenueBreakdown;
        this.totalDaywise = Object.values(this.currentPosDateDataFromDb.categories).reduce((a, b) => a + b, 0);
        this.totalShiftwise = this.totalDaywise;
        return;
      }

      let revenueBreakdown = [];
      let totalRevPerDay = 0;
      this.billedOrdersPerPosDate.filter(s => s.isSettled == true && !s.isVoided).forEach(
        sbo => {
          sbo.orders.filter(s => !s.isVoided).forEach(
            o => {

              let totalNet = o.totals.net;

              if(sbo.billDiscount) {
                if(sbo.billDiscount.discount.discount_rate)
                  totalNet -= ((totalNet * sbo.billDiscount.discount.discount_rate) / sbo.pax) * sbo.billDiscount.discountQuantity;
                else if(sbo.billDiscount.discount.discount_amount)
                  totalNet -= totalNet * (sbo.billDiscount.discount.discount_amount / sbo.orders.filter(s => !s.isVoided).length);
              }

              if(revenueBreakdown[o.product.category]) {
                revenueBreakdown[o.product.category] += totalNet;
              } else {
                revenueBreakdown[o.product.category] = totalNet;
              }
              totalRevPerDay += totalNet;

            }
        )}
      )

      this.revenueBreakdownPerDay = revenueBreakdown;
      this.revenueBreakdown = revenueBreakdown;
      this.totalDaywise = totalRevPerDay;
    },

    cancelShiftChange() {
      this.$router.push({ name: 'home' });
    },

    async clickShowSpotAudit() {
      const response = await this.$openApproverModal(PERMISSION_TYPES.SHOW_SPOT_AUDIT);
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Show Spot Audit cancelled');
          }

          return;
      }
      this.showSpotAudit = true;
    },

    async changeShift() {
      if (window.enableOfflineMode) {
        this.$swal.warning('Please switch to online mode to proceed with changing shifts.');
        return;
      }

      if (!this.isQueueCompleted) return this.transactionsNotSyncedAlert();

      // EPOS-705: If there’s no Cash Sales AND no Beginning Cash Fund amount is 0, prevent Shift Change
      if (ENABLE_EPEI_REQUIRE_BEG_CASH_FUND && this.totalCash == 0 && this.cashFloat[this.terminalId] == 0) {
        return this.showRequireCashFundAlert();
      }

      const response = await this.$openApproverModal(PERMISSION_TYPES.X_READ);
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Change Shift cancelled');
          }

          return;
      }

      if (window.MosaicPosAppSupportedFeatures?.multiterminal >= 2) {
        const mub = new MultiterminalUtilityBridge();
        const status = await mub.getStatus();
        const appBasedMultiterminal = status.mode == 'primary';

        if (appBasedMultiterminal) {
          await new PrimaryBroadcastUtilityBridge().forceSatelliteLogout();
        }
      }

      let ccrButton = '';
      let width = '33em';
      let xPreview = 'PREVIEW';
      let xStyle = ''
      if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
        ccrButton += '<button type="button" class="swal2-deny swal2-styled swal2-default-outline ccr-preview-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);"><div id="ccr-preview-btn">PREVIEW CASHIER CHECKOUT</div></button>'
        width = '52em';
        xPreview = '<div id="se-preview-btn">PREVIEW XREAD</div>'
        xStyle = 'se-preview-btn1'
      }

      Swal.fire({
            title: 'Do you want to proceed with SHIFT END?',
            width,
            text: "Clicking Yes will proceed to printing of X-reading and app logout.",
            iconHtml: '<div class="de-icon"><i class="fas fa-question"></i></div>',
            closeButtonHtml: '<i class="fas fa-times-circle"></i>',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            footer: '<div class="swal2-actions void-swal-action d-flex flex-wrap flex-md-nowrap" style="display: flex;">' +
                '<div class="swal2-loader"></div>' +
                '<button type="button" class="swal2-deny swal2-styled swal2-default-outline se-preview-btn '+xStyle+'" aria-label="" style="display: inline-block;  background-color: rgb(27, 117, 187);" @click="clickPreview">'+xPreview+'</button>' +
                ccrButton +
                '<button type="button" class="swal2-cancel swal2-styled swal2-default-outline se-cancel-btn" aria-label="" style="display: inline-block; background-color: rgb(244, 112, 112);">NO</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline se-yes-no-print-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);">YES</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline se-yes-with-print-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);"><div id="se-yes-and-print-span">YES & PRINT X-READ</div></button>' +
                '</div>',
            didOpen: () => {
              const btnPreview = document.querySelector('.se-preview-btn')
              const btnPreviewCCR = document.querySelector('.ccr-preview-btn')
              const btnCancel = document.querySelector('.se-cancel-btn')
              const btnYesNoPrint = document.querySelector('.se-yes-no-print-btn')
              const btnYesWithPrint = document.querySelector('.se-yes-with-print-btn')

              btnPreview.addEventListener('click', () => {

                  Swal.close()
                  this.clickPreview();

              }, Swal)

              if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
                btnPreviewCCR.addEventListener('click', () => {
                  Swal.close()
                  this.clickPreviewCCR();
                })
              }

              btnCancel.addEventListener('click', () => {
                Swal.close()
              })

              btnYesNoPrint.addEventListener('click', () => {
                window.__showLoader();

                this.clickYesNoPrint();
              })

              btnYesWithPrint.addEventListener('click', () => {
                window.__showLoader();

                this.clickYesWithPrint();
              })

            },
            customClass: {
                icon: 'de-swal-icon',
                actions: 'void-swal-action',
                popup: 'de-popup',
                footer: 'de-footer',
                htmlContainer: 'de-container',
                closeButton: 'de-close'
            }
        })
    },

    clickPreview() {
      this.receiptDateString = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.isXreadPreviewModalOpen = true;
    },

    clickPreviewCCR() {
      this.receiptDateString = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.isEodPreviewModalOpen = true;
    },

    async clickYesNoPrint() {
      if (this.isShiftChanged) return;
      this.isShiftChanged = true;
      if (ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE) {
        window.__hideLoader();
        let width = '33em';
        const response = await getLoggedInUsers();
        const cashiers = response.data.map((cashier, index) => {
          return `<ul style="margin-top: 0.5rem; margin-bottom: 0.5rem; text-align: left;"><span class="dot"></span>${cashier?.user?.username}</ul>`;
        })
        Swal.fire({
            title: `Changing shift will also logout other logged in users from other terminals.<li style="list-style-type: none; font-size: 1.2rem; margin-top: 1.5rem;">${cashiers}</li>`,
            width,
            text: 'Would you like to proceed?',
            iconHtml: '<div class="de-icon"><i class="fas fa-exclamation-triangle"></i></div>',
            closeButtonHtml: '<i class="fas fa-times-circle"></i>',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            footer: '<div class="swal2-actions void-swal-action" style="display: flex;">' +
                '<div class="swal2-loader"></div>' +
                '<button type="button" class="swal2-cancel swal2-styled swal2-default-outline change-shift-cancel-btn" aria-label="" style="display: inline-block; background-color: rgb(244, 112, 112);">CANCEL</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline change-shift-confirm-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);">CHANGE SHIFT</button>' +
                '</div>',
            didOpen: () => {
              const btnCancel = document.querySelector('.change-shift-cancel-btn')
              const btnConfirm = document.querySelector('.change-shift-confirm-btn')

              btnCancel.addEventListener('click', () => {
                Swal.close()
              })

              btnConfirm.addEventListener('click', () => {
                this.shiftEnd();
              })
            },
            customClass: {
                icon: 'de-swal-icon',
                actions: 'void-swal-action',
                popup: 'de-popup',
                footer: 'de-footer',
                htmlContainer: 'de-container',
                closeButton: 'de-close'
            }
        })
      } else {
        this.shiftEnd();
      }
    },

    async clickYesWithPrint() {
      if (this.isShiftChanged) return;
      this.isShiftChanged = true;

      if (ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE) {
        window.__hideLoader();
        let width = '33em';
        const response = await getLoggedInUsers();
        const cashiers = response.data.map((cashier, index) => {
          return `<ul style="margin-top: 0.5rem; margin-bottom: 0.5rem; text-align: left;"><span class="dot"></span>${cashier?.user?.username}</ul>`;
        }).join("");
        Swal.fire({
            title: `Changing shift will also logout other logged in users from other terminals.<li style="list-style-type: none; font-size: 1.2rem; margin-top: 1.5rem;">${cashiers}</li>`,
            width,
            text: 'Would you like to proceed?',
            iconHtml: '<div class="de-icon"><i class="fas fas fa-exclamation-triangle"></i></div>',
            closeButtonHtml: '<i class="fas fa-times-circle"></i>',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            footer: '<div class="swal2-actions void-swal-action" style="display: flex;">' +
                '<div class="swal2-loader"></div>' +
                '<button type="button" class="swal2-cancel swal2-styled swal2-default-outline change-shift-cancel-btn" aria-label="" style="display: inline-block; background-color: rgb(244, 112, 112);">CANCEL</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline change-shift-confirm-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);">CHANGE SHIFT</button>' +
                '</div>',
            didOpen: () => {
              const btnCancel = document.querySelector('.change-shift-cancel-btn')
              const btnConfirm = document.querySelector('.change-shift-confirm-btn')

              btnCancel.addEventListener('click', () => {
                Swal.close()
              })

              btnConfirm.addEventListener('click', () => {
                this.shiftEnd(true);
              })
            },
            customClass: {
                icon: 'de-swal-icon',
                actions: 'void-swal-action',
                popup: 'de-popup',
                footer: 'de-footer',
                htmlContainer: 'de-container',
                closeButton: 'de-close'
            }
        })
      } else {
        this.shiftEnd(true);
      }
    },

    async shiftEnd(print = false) {
      if (!(await checkCloudConnection())) {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to proceed with shift change.');
          return;
      }

      await this.fetchCurrentPosDateData(false, true);

      if(print) {
        await this.printXread(false);
      }

      const shiftChangeData = {
        shift_end_date_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // the time and date when the user click the shift end.
        lastBillNum: this.lastBillNum,
        lastVoidBillNum: this.lastVoidBillNum,
        endingVoidAmount: this.endingVoidAmount,
        endingBalance: this.endingBalance,
        lastReceiptNum: this.previousReceiptNum,
        total_bills: this.totalBills,
        average_check: this.avgPerCheckPerShift,
        total_pax: this.totalPaxPerShift,
        total_amount: this.shiftTotalAmount,
        over_short: this.overShortAmount ?? 0,
        shiftTable: this.shiftTable,
        orders: this.shiftBilledOrders,
      }

      this.setShiftChangeRecord(shiftChangeData);

      try {
        const stb = new ShiftTableBridge();

        if (!OFFLOAD.sqliteOffloadReceipt) {
          await this.forceSyncOrders(this.posDate);
        }

        //save shiftChange to database
        const response = await shiftChangeSync(shiftChangeData);

        await stb.shiftEnd();

        if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
            await this.printEOD();
        }

        let hasPerformShiftChange = response.data;

        if (ENABLE_FORCE_AUTO_LOGOUT_ON_SHIFT_CHANGE && hasPerformShiftChange) {
          bus.emit("executeLogoutIfTerminalOnePerformsShiftChange", hasPerformShiftChange);
        } else {
          this.$store.commit('user/resetState');
          this.$store.commit('global/resetState');
          this.$store.commit('modals/resetState');
          this.$store.commit('settings/resetState');
          this.$store.commit('resetState');
          sessionStorage.clear();
          localStorage.clear();
          await clearStorage();
          await clearAxiosCache();
          await cashierLogout()

          window.location.href = '/cashier/home'
        }
      } catch (error) {
          this.$swal.error(error.message);
          console.error(error);
      }
    },

    padWithZero(num) {

      return String(num || 0).padStart(20, '0');

    },

    async printXread(paused=true,direct=false) {
      if (paused) {
        const response = await this.$openApproverModal(PERMISSION_TYPES.X_READ);
        if (response.success) {
          await this.printXread(false, true)
        }
        return;
      }

      let pmixSummary = this.generateProductMixString(this.generatePmixData(), this.generatePmixGrandTotal);

      let printTemplate = xReadHtml;

      //get separate sections
      let separateSections = JSON.parse(this.recieptDetails?.print_zread_sections ?? '[]');


      let overShortString = !separateSections.includes('over_short') ? this.getSectionHtml('over_short') : '';

      let discountItemsStr = this.totalPerDiscountItems.map((discount) =>  {

        return `<tr><td valign="top">${discount.name}</td><td valign="top">${this.$filters.formatPrice(discount.amount)}</td></tr>`;

      }).join("\n");


      let serviceTypeStr = this.serviceTypesTotal.map((serviceType) =>  {

        return `
          <tr><td valign="top">${serviceType.name}</td><td valign="top">${this.$filters.formatPrice(serviceType.amount)}</td></tr>
          <tr><td valign="top">Count:</td><td valign="top">${serviceType.count}</td></tr>
        `;

      }).join("\n");

      let incomeHeadSalesStr = this.revenueBreakdownPerShift.map((incomeHead) =>  {

        return `<tr>
                    <td valign="top">${incomeHead.name}</td>
                    <td valign="top">${incomeHead.qty}</td>
                    <td valign="top">${this.$filters.formatPrice(incomeHead.shiftwise)}</td>
                </tr>`;

      }).join("\n");

      let paymentMethodsStr = this.paymentMethods.map((pm) =>  {

         return `<tr><td valign="top">${pm.name}</td><td valign="top">${this.$filters.formatPrice(pm.amount)}</td></tr>`;

      }).join("\n");

      let cashFundSection = '';

      if (this.cashFunds && this.hasCashFundPermission) {
        cashFundSection = `
                <tr>
                    <td colspan="2"> ------------------------------- </td>
                </tr>
                <tr>
                    <td valign="top" colspan="2" class="text-left">Cash Funds</td>
                </tr>`;
        cashFundSection += this.createValueLabelRowWithHeader(this.cashFunds);
      }

      //other charges
      let otherChargesString = `<tr><td valign="top">Other Charges </td> <td valign="top">${this.$filters.formatPrice(this.totalOtherCharges)}</td></tr>`;
      let toReplace = {
        ___REPORT_TYPE___ : this.reportTypeTitle,
        __REPRINT__: "",
        ___ACCOUNT_NAME___: this.recieptDetails?.account_name ?? '',
        ___LOCATION___: this.recieptDetails?.location ?? '',
        ___LOCATION_ADDRESS___: this.recieptDetails?.location_address ?? '',
        ___PREFIX_VAT_REG___: IS_NON_VAT ? 'Non-' : '',
        ___VAT_REG_TIN___: this.recieptDetails?.location_vat_reg ?? '',
        ___MIN___: this.recieptDetails?.lp_min ?? '',
        ___SERIAL_NO___: this.recieptDetails?.lp_serial ?? '',
        ___BEGINNING_BALANCE___: this.$filters.formatPrice(this.beginningBalance),
        ___ENDING_BALANCE___: this.$filters.formatPrice(this.endingBalance),
        ___BEGINNING_OR___: this.padWithZero(this.beginningSettlement.receipt_num || this.lastReceiptNum),
        ___ENDING_OR___: this.padWithZero(this.endingSettlement.receipt_num || this.lastReceiptNum),
        ___BEGINNING_BILL___: this.padWithZero(this.begBilledOrder.bill_num || this.lastBillNum),
        ___ENDING_BILL___: this.padWithZero(this.endBilledOrder.bill_num || this.lastBillNum),
        ___BEGINNING_VOID___: this.padWithZero(this.beginningVoidNum || this.lastVoidBillNum),
        ___ENDING_VOID___: this.padWithZero(this.endingVoidNum || this.lastVoidBillNum),
        ___BEGINNING_VOID_AMOUNT___: this.$filters.formatPrice(GET_BREAKDOWNS_FROM_DB ? this.beginningVoidAmountOverride : this.beginningVoidAmount),
        ___ENDING_VOID_AMOUNT___: this.$filters.formatPrice(this.endingVoidAmount),
        ___GROSS_SECTION___: this.grossSection
          .replace('___GROSS_W_SERVICE_CHARGE___', this.$filters.formatPrice(this.totalGrossWithServiceCharge))
          .replace('___GROSS_W_SERVICE_CHARGE_WO_VOID___', this.$filters.formatPrice(this.totalGrossWithServiceChargeWoVoid))
          .replace('___GROSS_WO_SERVICE_CHARGE_WO_VOID_SECTION___', this.totalServiceCharge != 0 ? `<tr>
                <td valign="top">Gross w/o Service Charge</td>
                <td valign="top">${this.$filters.formatPrice(this.totalWOVoidAndServiceCharge)}</td>
            </tr>` : ''),
        ___SERVICE_CHARGE_SECTION___: this.totalServiceCharge != 0 ? this.serviceChargeSection
          .replace('___SERVICE_CHARGE___', this.$filters.formatPrice(this.totalServiceCharge)) : '',
        ___OTHER_CHARGES___: otherChargesString,
        ___TOTAL_DISCOUNTS__: this.$filters.formatPrice(this.totalDiscounts),
        ___VOIDED_SALES___: this.$filters.formatPrice(this.totalVoidedSales),
        ___VOIDED_BILLED___: this.$filters.formatPrice(this.totalVoidedBilled),
        ___VATABLE_SALES___: this.$filters.formatPrice(this.totalVatableSales),
        ___VAT_AMOUNT___: this.$filters.formatPrice(this.totalVatAmount),
        ___VAT_EXEMPT_SALES___: this.$filters.formatPrice(this.totalVatExempSales),
        ___ZERO_RATED_SALES___: this.$filters.formatPrice(this.totalZeroRatedSales),
        ___NET_SALES___: this.$filters.formatPrice(this.totalNetSales),
        ___TOTAL_CASH_VALUE___: this.$filters.formatPrice(this.totalCash),
        ___TOTAL_CASH_FLOAT___: this.$filters.formatPrice(this.cashFloat[this.terminalId]),
        ___CASH_FLOATS___: cashFundSection,
        ___CASH_SALES_SECTION___: this.cashSalesSection
              .replaceAll('___TOTAL_CASH_SALES___', this.$filters.formatPrice(this.totalCashSales + this.totalCashWithdraw))
              .replaceAll('___TOTAL_CASH_SALES_EXPECTED___', this.$filters.formatPrice(this.totalCashSales + this.totalCashWithdraw))
              .replaceAll('___TOTAL_CASH_SALES_DECLARED___', this.$filters.formatPrice(this.totalCash - this.cashFloat[this.terminalId] + this.totalCashWithdraw)),
        ___OVER_SHORT___: overShortString.replaceAll('___TOTAL_OVER_SHORT___', this.$filters.formatPrice(this.totalOverShortAmount)),
        ___PRODUCT_MIX_SUMMARY___: this.$can(this.PERMISSIONS.OLD_ZREAD_FORMAT) && this.$can(this.PERMISSIONS.PRINT_X_PMIX) && this.currentPosDateDataFromDb?.location_status != 'Inactive' ? pmixSummary : '',
        ___LAST_KOT___: this.lastKot,
        ___LAST_BILL_NUM___: this.lastBillNum,
        ___TRANSACTION_COUNT___: this.totalTransaction,
        ___TOTAL_PAX___: this.totalPaxPerShift,
        ___AVERAGE_PER_TRANSACTION___: this.$filters.formatPrice(this.avgPerCheckPerShift),
        ___GRAND_TOTAL___: this.$filters.formatPrice(this.endingBalance),
        ___POS_DATE___: moment(this.posDate).format("YYYY-MM-DD"),
        ___SHIFT_NO___: this.currentShift.shift,
        ___TERMINAL_ID___: this.shiftTable.terminal_id,
        ___GENERATED_BY___: this.generatedBy,
        ___SUB_TOTAL_TITLE___: this.totalServiceCharge != 0 ? 'Net Sales w/ VAT & SC' : 'Net Sales w/ VAT',
        ___SUB_TOTAL___: this.$filters.formatPrice(this.totalNetSalesWCharges),
        ___DISCOUNT_ITEMS___ : discountItemsStr,
        ___HOURLY_SALES___: this.generateHourlySalesString(this.hourlySales, this.locationId),
        ___SERVICE_TYPES___  : serviceTypeStr,
        ___INCOME_HEAD_SALES___ : incomeHeadSalesStr,
        ___PAYMENT_METHODS___ : paymentMethodsStr,
        ___REPORT_TIMESTAMP_SECTION___: this.reportTimestampSection
              .replace('___RECIEPT_PRINTED_DATE___', moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
              .replace('___RECIEPT_PRINTED_DATE_ONLY___', moment(new Date()).format("YYYY-MM-DD"))
              .replace('___RECIEPT_PRINTED_TIME_ONLY___', moment(new Date()).format("HH:mm:ss")),
        ___Z_COUNTER___: '',
        ___OLD_GRAND_TOTAL___ : "",
        ___VAT_ADJUSTMENT___ : this.generateVatAdjustmentString(this.totalVatAdjustment),
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
        printTemplate = printTemplate.replace(RE, function(matched) {
            return toReplace[matched];
        });

      console.log(
                convert(printTemplate,
                    {
                        wordwrap: 130,
                        preserveNewlines: true,
                    })
                );

      let printData = {
                print_type: 'Report',
                html: printTemplate,
            };

      let printConfig = {
      };

      await Promise.all([
        print(printData, printConfig),
        direct ? this.fetchCurrentPosDateData() : null,
      ]);
    },

    async printEOD(reportType = 'ccr') {
      let printTemplate = eodHtml;

      let separateSections = JSON.parse(this.recieptDetails.print_zread_sections);

      let isReprint = false;

      let spacer = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";

      let ccrSeries = `<tr>
            <td valign="top" colspan="2">Beg. SI#</td>
        </tr>
        <tr>
          <td valign="top" colspan="2">${spacer}${this.padWithZero(this.beginningSettlement.receipt_num)}</td>
        </tr>
        <tr>
            <td valign="top" colspan="2">End. SI#</td>
        </tr>
        <tr>
          <td valign="top" colspan="2">${spacer}${ this.padWithZero(this.endingSettlement.receipt_num)}</td>
        </tr>
        <tr>
            <td valign="top">Beg. CheckID#</td>
            <td valign="top"></td>
        </tr>
        <tr>
            <td valign="top">End. CheckID#</td>
            <td valign="top"></td>
        </tr>
        <tr>
            <td valign="top">Beg. Void Amt.</td>
            <td valign="top">${ this.$filters.formatPrice(this.beginningVoidAmount) }</td>
        </tr>
        <tr>
            <td valign="top">End. Void Amt.</td>
            <td valign="top">${ this.$filters.formatPrice(this.endingVoidAmount) }</td>
        </tr>`;

      let salesNetDiscString = this.serviceTypesTotal.map((st) => {
              return `<tr>
                  <td class="indent-2" style="width: 50%">${ st.name }</td>
                  <td class="text-right" style="width: 30%">${ this.$filters.formatPrice(st.amount) }</td>
                  <td style="width: 20%"></td>
                </tr>`
          }).join('');

      let serviceTypesNet = this.serviceTypesTotal.reduce((accumulator, st) => {
            return accumulator + st.amount;
          }, 0);

      let totalSalesIncomeAndCharge = serviceTypesNet + this.totalServiceCharge;

      let totalSettlement = this.totalEODCashSales + this.totalEODNonCashSales;

      let nonCashPaymentMethods = this.paymentMethods.filter(p => p.name != 'CASH');

      let nonCashRevString = nonCashPaymentMethods.map((nc) => {
        return `<tr>
          <td class="indent-2">${ nc.name }</td>
          <td class="text-right">${ nc.count ?? 0  }</td>
          <td class="text-right">${ this.$filters.formatPrice(nc.amount) }</td>
        </tr>`
      }).join('');

      let nonCashTotalsObj = {
              totalCount: nonCashPaymentMethods.reduce((accumulator, p) => {
                return accumulator + (p.count ?? 0);
              }, 0),
              totalAmount: nonCashPaymentMethods.reduce((accumulator, p) => {
              return accumulator + (p.amount ?? 0);
            }, 0),
          };

      let nonRevItemStr = '';

      let nonRevenueItems = () => {
        let nonRevItems = {};

        for (const prop in this.nonRevenueOrders) {
          this.nonRevenueOrders[prop].forEach(o => {
            if(nonRevItems[o.product.product_name]) {
              nonRevItems[o.product.product_name] += o.totals.total;
            } else {
              nonRevItems[o.product.product_name] = o.totals.total;
            }
          });
        }

        for(const prop in nonRevItems) {
          nonRevItemStr += `<tr>
              <td class="indent-2" style="width: 50%;">${ prop }</td>
              <td style="width: 30%" class="text-right">${ this.$filters.formatPrice(nonRevItems[prop]) }</td>
              <td style="width: 20%"></td>
            </tr>`;
        }

        return nonRevItems;
      }

      let nonRevTotal = () => {
        let total = 0;

        let items = nonRevenueItems();

        for (const prop in items) {
          total += items[prop];
        }

        return total;
      };

      let cashierShifts = () => {
        let data = {};

        this.shiftChangeRecord.forEach(cs => {
          let name = cs?.shiftTable?.user?.last_name + ' ' + cs?.shiftTable?.user?.first_name;
          if(data[name]) {
            data[name] += cs.over_short;
          } else {
            data[name] = 0;
          }
        });

        return data;
      };

      let totalCashForDeposit = reportType !== 'eod' ? this.totalEODCashSales + this.cashFloat[this.terminalId] - this.totalCashWithdraw : this.totalEODCashSales;

      let cashierShiftsString = `<tr>
            <td colspan="2" valign="top">Employee Over (Short)</td>
        </tr>`;
      let csArr = cashierShifts();
      for(const key in csArr) {
        cashierShiftsString += `<tr>
          <td class="indent-3" style="width: 50%">${key}</td>
          <td style="width: 30%" class="text-right
          ">
          ${this.$filters.formatPrice(csArr[key])}
          </td>
          <td style="width: 20%"></td>
        </tr>`
      }

      let cashierCheckoutSum = reportType !== 'eod' ? `<tr>
            <td colspan="2" valign="top">CHECKOUT SUMMARY</td>
        </tr>
        <tr>
            <td colspan="2"><br></td>
        </tr>
        <tr>
            <td colspan="2">CASH SETTLEMENT</td>
        </tr>
        <tr>
            <td valign="top">CALCULATED CASH OWED</td>
            <td valign="top">${this.$filters.formatPrice(totalCashForDeposit)}</td>
        </tr>
        <tr>
            <td valign="top">CALCULATED CASH DECLARED</td>
            <td valign="top">${this.$filters.formatPrice(0)}</td>
        </tr>
        <tr>
            <td valign="top">Cash Over (Short)</td>
            <td valign="top">${this.$filters.formatPrice(0)}</td>
        </tr>
        <tr>
            <td colspan="2" height="10"></td>
        </tr>` : '';

      let cashierCheckoutFooter = reportType !== 'eod' ? `<table id="z-footer">
          <tr>
              <td valign="top" style="font-size: 10px;">Employee</td>
              <td valign="top" style="font-size: 10px;"></td>
          </tr>
          <tr>
              <td colspan="2">
                  <br>
                  <br>
              </td>
          </tr>
          <tr>
              <td valign="top" style="font-size: 10px;">Cash Received By</td>
              <td valign="top" style="font-size: 10px;"></td>
          </tr>
      </table>` : '';

      let employeeCashTrans1 = reportType !== 'eod' ? `<tr>
            <td valign="top">Employee Cash Transactions</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.terminalId])}</td>
        </tr>` : '';

      let employeeCashTrans2 = reportType !== 'eod' ? `<tr>
            <td colspan="2" valign="top">Employee Cash Transactions</td>
        </tr>
        <tr>
            <td valign="top">Cash Fund</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.terminalId])}</td>
        </tr>
        <tr>
            <td valign="top">Cash Bleed 1</td>
            <td valign="top">0</td>
        </tr>
        <tr>
            <td valign="top">Total Employee Cash Transactions</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.terminalId])}</td>
        </tr>` : '';

      let cashierShiftInfo = reportType !== 'eod' ? `<tr>
            <td valign="top">
              Cashier Name: ${this.username}<br>
              Shift: ${this.currentShift.shift}<br>
              POS #: ${this.shiftTable.terminal_id}
            </td>
          </tr>` : '';

      let totalGrossSales = this.totalAmount + this.totalDiscounts;
      let grossEndBalance = this.beginningBalance + totalGrossSales;
      let totalPunchedSales = totalGrossSales + this.totalVoidedSalesAmount;

      let employeeTotalOverShort = reportType === 'eod' ? `<tr>
            <td valign="top">Total Employee Over (Short)</td>
            <td valign="top">${this.$filters.formatPrice(this.totalOverShortAmount)}</td>
        </tr>`: '';

      let toReplace = {
        ___REPORT_TYPE___ : "Cashier Checkout",
        __REPRINT__: isReprint ? "REPRINT" : "",
        ___DATE_AND_TIME___: moment(this.receiptDate).format("LLL"),
        ___STORE_CODE___: '',
        ___BRAND_BRANCH___: this.recieptDetails.account_name + " " + this.recieptDetails.location,
        ___DOB___: moment(this.posDate).format("LLL"),
        ___BIR_MIN___: this.recieptDetails.lp_min,
        ___CASHIER_NAME___: this.username,
        ___SHIFT___: this.currentShift.shift,
        ___POS_NUMBER___: this.shiftTable.terminal_id,
        ___BEGINNING_OPENREADING___: "Beginning",
        ___ENDING_CLOSEREADING___: "Ending",
        ___GROSS_SALES_BEGINNING___: this.$filters.formatPrice(this.beginningBalance),
        ___GROSS_SALES_ENDING___: this.$filters.formatPrice(grossEndBalance),
        ___CCR_SERIES___: reportType !== "eod" ? ccrSeries : '',
        ___TOTAL_GROSS_SALES___: this.$filters.formatPrice(totalGrossSales),
        ___TOTAL_PUNCHED_SALES___: this.$filters.formatPrice(totalPunchedSales),
        ___GROSS_WO_SERVICE_CHARGE___: this.$filters.formatPrice(this.totalBeforeServiceCharge),
        ___TOTAL_SVC_CHARGE_AMT___: this.$filters.formatPrice(this.totalServiceCharge),
        ___TOTAL_DISCOUNT___: this.$filters.formatPrice(this.totalDiscounts),
        ___TOTAL_VOID_SALES___: this.$filters.formatPrice(this.totalVoidedSalesAmount),
        ___SALES_NET_DISCOUNT___: salesNetDiscString,
        ___TOTAL_SALES_INCOME_AND_CHARGE___: this.$filters.formatPrice(totalSalesIncomeAndCharge),
        ___VATABLE_SALES___: this.$filters.formatPrice(this.totalVatableSales),
        ___TOTAL_VAT_AMT___: this.$filters.formatPrice(this.totalVatAmount),
        ___TOTAL_SETTLEMENT___: this.$filters.formatPrice(totalSettlement),
        ___REVENUE_TOTAL_CASH___: this.$filters.formatPrice(this.totalEODCashSales),
        ___REVENUE_TOTAL_NON_CASH___: this.$filters.formatPrice(this.totalEODNonCashSales),
        ___NONCASH_REVENUE_SETTLEMENT___: nonCashRevString,
        ___TOTAL_NON_CASH_COUNT___: nonCashTotalsObj.totalCount,
        ___TOTAL_NON_CASH_AMT___: this.$filters.formatPrice(nonCashTotalsObj.totalAmount),
        ___TOTAL_NON_REVENUE_AMT___: this.$filters.formatPrice(nonRevTotal()),
        ___NON_REVENUE_ITEMS___: nonRevItemStr,
        ___NON_REVENUE_TOTAL_SETTLEMENT___: this.$filters.formatPrice(0),
        ___NON_REVENUE_TOTAL_CASH___: this.$filters.formatPrice(0),
        ___NON_REVENUE_TOTAL_NON_CASH___: this.$filters.formatPrice(0),
        ___NON_REVENUE_SETTLEMENT___: '',
        ___TOTAL_CASH_FOR_DEPOSIT___: this.$filters.formatPrice(totalCashForDeposit),
        ___TOTAL_NON_CASH_FOR_DEPOSIT___:this.$filters.formatPrice(this.totalEODNonCashSales) ,
        ___CASH_FUND___: this.$filters.formatPrice(this.cashFloat[this.terminalId]),
        ___TOTAL_GROSS_NET___: this.$filters.formatPrice(this.totalAmount),
        ___TOTAL_NET_SALES___: this.$filters.formatPrice(serviceTypesNet),
        ___TOTAL_CASH_VALUE___: this.$filters.formatPrice(this.totalCash),
        ___TOTAL_CASH_FLOAT___: this.$filters.formatPrice(this.cashFloat[this.terminalId]),
        ___PRODUCT_MIX_SUMMARY___: '',
        ___CASH_SALES_SECTION___: this.cashSalesSection
              .replaceAll('___TOTAL_CASH_SALES___', this.$filters.formatPrice(this.totalCashSales + this.totalCashWithdraw))
              .replaceAll('___TOTAL_CASH_SALES_EXPECTED___', this.$filters.formatPrice(this.totalCashSales + this.totalCashWithdraw))
              .replaceAll('___TOTAL_CASH_SALES_DECLARED___', this.$filters.formatPrice(this.totalCash - this.cashFloat[this.terminalId] + this.totalCashWithdraw)),
        ___TOTAL_OVER_SHORT___: this.$filters.formatPrice(this.totalOverShortAmount),
        ___ACCOUNTABILITY_CASHIERS___: reportType === "eod" ? cashierShiftsString : '',
        ___TOTAL_CASH_DECLARED___: this.$filters.formatPrice(0),
        ___TOTAL_CASH_OVER_SHORT___: this.$filters.formatPrice(0),
        ___CASHIER_CHECKOUT_SUM___: cashierCheckoutSum,
        ___CASHIER_CHECKOUT_FOOTER___: cashierCheckoutFooter,
        ___EMPLOYEE_CASH_TRANSACTIONS1___: employeeCashTrans1,
        ___EMPLOYEE_CASH_TRANSACTIONS2___: employeeCashTrans2,
        ___CASHIER_SHIFT_INFO___: cashierShiftInfo,
        ___EMPLOYEE_TOTAL_OVER_SHORT___: employeeTotalOverShort,
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
        printTemplate = printTemplate.replace(RE, function(matched) {
            return toReplace[matched];
        });

      console.log(
                convert(printTemplate,
                    {
                        wordwrap: 130,
                        preserveNewlines: true,
                    })
                );

      let printData = {
                print_type: 'EOD',
                html: printTemplate,
            };

      let printConfig = {
      };

      await print(printData, printConfig);
    },

    checkIfHasGcExcess(settlement) {
      const vouchers = settlement.payments.filter(payment => payment.type === 'voucher');

      if(!vouchers.length) {
        return false;
    }

      if(sumBy(vouchers, 'amount') > settlement.breakdown.total) {
        return true
  }

      return false
    },
  }

}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

:deep(#useWholeAmountInput) {
  font-weight: bold;
  white-space: nowrap;
}


.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>

<style>
.dot {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #D0342C;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
}
</style>
