import {DataOffloadBridge} from "./offload"; // Importing this way preserves autocomplete hints
import {
    getTransactionStatusId,
    createReceipt,
    createReceiptContent,
    appendNumberToInteger,
    resetTotalsToZero,
    sumArray,
    formatAmount,
    hasDiscountedLineItems,
    formatTwoDecimals,
    TRANSACTION_STATUS_ID,
    RECEIPT_CONTENT_TYPE,
    CANCELLATION_TYPE,
} from "@/mobile_bridge/offload/receipt-model";
import moment from "moment";
import {
    SQLITE_OFFLOAD_MAX_RECEIPT_CONTENTS_COUNT,
    SQLITE_OFFLOAD_DELETE_ORDERS_OLDER_THAN,
    SQLITE_OFFLOAD_DELETE_RECEIPTS_OLDER_THAN,
    USE_SM_MARKETS_OR_FORMAT,
    ACTIVE_ACCOUNT_TYPE,
    ACCOUNT_TYPES,
    OFFLOAD,
    ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
    USE_NEW_AMOUNT_DUE_FORMULA,
} from "@/spa/constants";
import {cloneDeep, isEmpty, isNil} from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {storeToS3} from "@/spa/services/logger-service";
import swal from "sweetalert";

export const OFFLOAD_RECEIPT_ACTION = {
    PLACE_ORDER: 'Place Order',
    ADD_ORDER: 'Add Order',
    BILL_ORDER: 'Bill Order',
    BILL_VOID: 'Bill Void',
    PAID_VOID: 'Paid Void',
    PENDING_VOID: 'Pending Void',
    SETTLE_ORDER: 'Settle Order',
    DISCOUNT_ORDER: 'Discount Order',
    DISCOUNT_REMOVE: 'Discount Remove',
    MOVE_TABLE: 'Move Table',
    MERGE_TABLE: 'Merge Table',
    UPDATE_STATE: 'Update State',
};

function transformArray(originalArray) {
    const transformedArray = [];

    const findOrCreate = (arr, condition, create) => {
        let item = arr.find(condition);
        if (!item) {
            item = create();
            arr.push(item);
        }
        return item;
    };

    originalArray.forEach(item => {
        const transaction = findOrCreate(transformedArray, t => t.local_id === item.transaction_local_id, () => ({
            ...item,
            products: [],
        }));

        if (item.type === RECEIPT_CONTENT_TYPE.PRODUCTS) {
            const product = { ...item };
            transaction.products.push(product);
        } else if (item.type === RECEIPT_CONTENT_TYPE.MODIFIERS) {
            const correspondingProduct = findOrCreate(
                transaction.products,
                p => p.local_id === item.receipt_content_local_id,
                () => ({ ...item, modifiers: [] })
            );

            const modifier = { ...item };
            if (!correspondingProduct.modifiers) {
                correspondingProduct.modifiers = [];
            }
            correspondingProduct.modifiers.push(modifier);
        }
    });

    return transformedArray;
}

export function calculatePmixSummary(data) {
    let totalQty = 0;
    let totalPrice = 0;
    for (const serviceTypeKey in data) {
        for (const groupKey in data[serviceTypeKey]) {
            const group = data[serviceTypeKey][groupKey];
            for (const itemKey in group) {
                const item = group[itemKey];

                totalQty += item.qty;
                totalPrice += item.total_price;
            }
        }
    }

    return { totalQty, totalPrice };
}

export function getAmountDue(total) {
    const otherCharges = Object.values(total?.otherCharges || {}).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const vatableSales = formatTwoDecimals(total.net) - formatTwoDecimals(total?.vatExemptSales) - formatTwoDecimals(total?.zeroRatedSales);
    return vatableSales +
        formatTwoDecimals(total?.vatExemptSales) +
        formatTwoDecimals(total?.zeroRatedSales) +
        formatTwoDecimals(total?.vat) +
        formatTwoDecimals(total?.serviceCharge) +
        formatTwoDecimals(otherCharges);
}

function setExactAmountForSinglePayment(orderObj) {
    if (orderObj.payments.length === 1) {
        orderObj.payments[0].exact_amount = USE_NEW_AMOUNT_DUE_FORMULA
            ? getAmountDue(orderObj.totals)
            : orderObj.totals.total;
    }

    return orderObj;
}

async function processMergedPendingVoid(orderObj, orderBridge, tdb) {
    const receiptIds = cloneDeep(orderObj?.receiptMergedWith || []);
    if (orderObj?.receiptMergedTo) {
        receiptIds.push(orderObj.receiptMergedTo)
    }

    const tableIds = cloneDeep(orderObj?.tableMergedWith || []);
    if (orderObj?.tableMergedTo) {
        tableIds.push(orderObj.tableMergedTo);
    }

    for (const receiptId of receiptIds) {
        if (receiptId) {
            const response = await orderBridge.getOrderById(receiptId);
            const order = JSON.parse(response.order_data);

            if (isNil(orderObj?.receiptMergedWith) && order?.receiptMergedWith) {
                receiptIds.concat(order.receiptMergedWith);
                tableIds.concat(order.tableMergedWith);
            }

            if (isNil(orderObj?.receiptMergedTo) && !receiptIds.includes(order.receiptMergedTo)) {
                receiptIds.push(order.receiptMergedTo);
                tableIds.push(order.tableMergedTo);
            }
        }
    }

    for (const receiptId of receiptIds) {
        if (receiptId) {
            const response = await orderBridge.getOrderById(receiptId);
            const mergeOrder = JSON.parse(response.order_data);
            mergeOrder.isVoided = true;
            mergeOrder.updateType = 'void';
            mergeOrder.orders.map(order => {
                order.isVoided = true;
                order.isCancelled = true;
                return order;
            });

            await orderBridge.upsertOrder(mergeOrder)
        }
    }

    for (const tableId of tableIds) {
        if (tableId) {
            await tdb.update({
                table_id: tableId ?? null,
                receipt_local_id: null
            });
        }
    }
}

async function updateTableData(action, orderObj) {
    const { SETTLE_ORDER, BILL_VOID, BILL_ORDER, PENDING_VOID } = OFFLOAD_RECEIPT_ACTION;
    const orderBridge = new OrderBridge();
    const tdb = new ServiceTableDetailBridge();
    const {receiptMergedWith, tableMergedWith} = orderObj;

    if ([SETTLE_ORDER,BILL_VOID].includes(action) ||
        (PENDING_VOID === action && orderObj?.isVoided)) {
        await tdb.update({
            table_id: orderObj?.tableId ?? null,
            receipt_local_id: null
        });
    }

    if (!OFFLOAD.sqliteOffloadPOSFE1300) {
        return orderObj;
    }

    if (PENDING_VOID === action) {
        await processMergedPendingVoid(orderObj, orderBridge, tdb);
    }

    if (BILL_ORDER === action && !isEmpty(receiptMergedWith)) {
        for (const id of receiptMergedWith || []) {
            await orderBridge.update({
                id,
                transaction_status_id: TRANSACTION_STATUS_ID.BILLED,
            })
        }
    }

    if ([SETTLE_ORDER, BILL_VOID].includes(action) && !isEmpty(receiptMergedWith)) {
        tableMergedWith.push(orderObj.tableId);

        for (const id of receiptMergedWith || []) {
            await orderBridge.update({
                id,
                transaction_status_id: TRANSACTION_STATUS_ID.MERGED,
            })
        }

        for (const table_id of tableMergedWith || []) {
            await tdb.update({
                table_id,
                receipt_local_id: null
            });
        }

        const orderResponse = await orderBridge.getRows({
            select: ['order_data'],
            whereIn: {
                id: receiptMergedWith.join(",")
            }
        });

        const orders = orderResponse.map(order => JSON.parse(order.order_data));
        const flattenedData = orders.flatMap(order => ({ kots: order.kots, pax: order.pax }));

        if (SETTLE_ORDER === action) {
            const pax = flattenedData.map(data => data.pax);
            pax.push(orderObj.pax);
            orderObj.pax = sumArray(pax);
        }

        orderObj.kots = Array.from(new Set([...flattenedData.flatMap(data => data.kots), ...cloneDeep(orderObj.kots)]));
    }

    return orderObj;
}

export async function mergeAndUpdatePax(activeOrder) {
    const orderBridge = new OrderBridge();
    const orderResponse = await orderBridge.getRows({
        select: ['order_data'],
        whereIn: {
            id: activeOrder.receiptMergedWith.join(",")
        }
    });
    const orders = orderResponse.map(order => JSON.parse(order.order_data));
    const pax = orders.map(data => data.pax);
    pax.push(activeOrder.pax);
    activeOrder.pax = sumArray(pax);

    return activeOrder;
}

function getReceiptProcessing() {
    return JSON.parse(sessionStorage.getItem('receiptProcessing') || '[]');
}

function setReceiptProcessing(receiptId) {
    if (!receiptId) {
        return;
    }

    const data = getReceiptProcessing();

    data.push(receiptId);

    sessionStorage.setItem('receiptProcessing', JSON.stringify(data));
}

function removeReceiptProcessing(receiptId) {
    if (!receiptId) {
        return;
    }
    
    const data = getReceiptProcessing();

    const index = data.findIndex(d => d == receiptId);

    data.splice(index, 1);

    sessionStorage.setItem('receiptProcessing', JSON.stringify(data));
}

export class OrderBridge extends DataOffloadBridge {
    constructor() {
        super('Order');
    }

    async upsertOrder(order, retry_count = 0) {
        const posDateBridge = new PosDateBridge();
        const business_date = await posDateBridge.getPosDate(order?.locationId ?? window.locationId);
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        const param = {
            id: order._id,
            order_data: JSON.stringify(order),
            retry_count,
            transaction_status_id: getTransactionStatusId(order, false),
            business_date,
            created_at: order?.createdAt || now,
            updated_at: now
        }

        return await this.sendMessage('UPSERT', { param });
    }

    async deleteOrdersOlderThan() {
        const param = {
            numberOfDays: SQLITE_OFFLOAD_DELETE_ORDERS_OLDER_THAN
        };
        return await this.sendMessage('DELETE_ORDERS_OLDER_THAN', { param });
    }

    async getPendingOrders(transaction_status_ids = null) {
        const posDateBridge = new PosDateBridge();
        const business_date = await posDateBridge.getPosDate(window.locationId);

        const param = {
            business_date,
            transaction_status_ids
        };

        const { rows } = await this.sendMessage('GET_PENDING_ORDERS', { param });

        return rows;
    }

    async getOrderById(id) {
        return await this.sendMessage('GET_ORDER_BY_ID', { id });
    }

    async deleteOrderById(id) {
        return await this.sendMessage('DELETE_ORDER_BY_ID', { id });
    }

    async getRows(params) {
        if (!OFFLOAD.sqliteOffloadPOSFE1300) {
            return null;
        }

        const {rows} = await this.sendMessage('GET_ROWS', { params });

        return rows;
    }

    async update(payload) {
        return await this.sendMessage('UPDATE_DATA', { payload });
    }
}

export class ReceiptContentBridge extends DataOffloadBridge {
    constructor() {
        super('ReceiptContent');
    }

    async updateData(payloads) {
        await this.sendMessage('UPDATE_DATA', { payloads });
    }

    async getRow(params) {
        return await this.sendMessage('GET_ROW', { params });
    }

    async getContentWithReceipt(params) {
        return await this.sendMessage('GET_CONTENT_WITH_RECEIPT', { params });
    }

    async generatePMIX(posDate, shift = null) {
        const where = {
            "r.business_date": posDate,
            "r.transaction_status_id": TRANSACTION_STATUS_ID.PAID
        };

        if (shift) {
            where["r.settled_shift_num"] = shift;
        }

        const outputObject = {};

        const brandBridge = new BrandBridge();
        const brands = await brandBridge.getAll();
        const serviceTypes = brands[0].service_types;

        const processProductMix = async (type, groupCategory, groupNameKey, nameKey, priceKey, qtyKey) => {
            for (const serviceType of serviceTypes) {
                const { rows } = await this.getContentWithReceipt({
                    where: { ...where, "rc.type": type, "rc.service_type_id": serviceType.id },
                });

                const productDetails = {};

                if (!isEmpty(rows)) {
                    rows.forEach(product => {
                        const groupName = `${groupCategory}: ${product[groupNameKey]}`;
                        const productName = product[nameKey];
                        const origPrice = product[priceKey];
                        const qty = product[qtyKey];

                        if (!productDetails[groupName]) {
                            productDetails[groupName] = {};
                        }

                        if (!productDetails[groupName][productName]) {
                            productDetails[groupName][productName] = USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT
                                ? { orig_price: origPrice, qty: qty, total_price: origPrice * qty, }
                                : qty;
                        } else {
                            if (USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                                productDetails[groupName][productName].qty += qty;
                                productDetails[groupName][productName].total_price += origPrice * qty;
                            } else {
                                productDetails[groupName][productName] += qty;
                            }
                        }
                    });

                    // Merge the productDetails under the same service type key
                    if (!outputObject[serviceType.service_name]) {
                        outputObject[serviceType.service_name] = {};
                    }

                    outputObject[serviceType.service_name] = {
                        ...outputObject[serviceType.service_name],
                        ...productDetails,
                    };
                }
            }
        };

        // products
        await processProductMix(
            "products",
            "Group Name",
            "product_group_name",
            "product_name",
            "product_price",
            "product_qty"
        );

        // modifiers
        await processProductMix(
            "modifiers",
            "Mod Group Name",
            "modifier_group_name",
            "modifier_name",
            "modifier_price",
            "modifier_qty"
        );

        return outputObject;
    }

    async deleteByLocalId(localId) {
        return await this.sendMessage('DELETE_BY_LOCAL_ID', { localId });
    }
}

export class ReceiptBridge extends DataOffloadBridge {
    constructor() {
        super('Receipt');
    }

    async upsertReceipt(action, orderObj, retryCount = 0) {
        const processing = getReceiptProcessing();
        if (processing.findIndex(d => d == orderObj._id) >= 0) {
            return;
        }

        setReceiptProcessing(orderObj._id);

        try {
            const { SETTLE_ORDER, BILL_VOID, PENDING_VOID } = OFFLOAD_RECEIPT_ACTION;
            const orderBridge = new OrderBridge();

            if (SETTLE_ORDER === action) {
                orderObj = setExactAmountForSinglePayment(orderObj);
                delete orderObj.splits;
            }

            if (ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM) {
                orderObj = await updateTableData(action, orderObj);
            }

            const result = await orderBridge.upsertOrder(orderObj, retryCount);

            if (isEmpty(result)) {
                console.error('Failed to upsert order', orderObj);
                swal({
                    icon: 'error',
                    title: 'Failed to save order',
                    text: 'The selected table is already occupied'
                }).then(() => location.reload());

                throw new Error('Failed to upsert order: table already occupied');
            }

            if (PENDING_VOID === action) {
                return;
            }

            const receipt = await createReceipt(action, orderObj);

            if (receipt) {
                await this.sendMessage('UPSERT', { receipt });
                const vatAdjustment = receipt?.vat_adjustment ?? null;
                const receiptContents = await createReceiptContent(orderObj, false, vatAdjustment);

                // Update the VAT adjustment in receipts table if there are line item discounts
                if (hasDiscountedLineItems(orderObj)) {
                    const transactions = receiptContents.filter(row => row.type === RECEIPT_CONTENT_TYPE.TRANSACTIONS);
                    const vat_adjustment = formatAmount(sumArray(transactions, 'vat_adjustment'));
                    await this.updateData([{
                        local_id: receipt.local_id,
                        vat_adjustment
                    }])
                }

                if ([ SETTLE_ORDER, BILL_VOID ].includes(action) || OFFLOAD.sqliteOffloadPOSFE1427) {
                    const originalReceipt = await createReceipt(action, orderObj, true);
                    if (originalReceipt) {
                        await this.sendMessage('UPSERT', {receipt: originalReceipt});
                    }

                    const rcb = new ReceiptContentBridge();

                    if (OFFLOAD.sqliteOffloadPOSFE1427) {
                        await rcb.deleteByLocalId(receipt.local_id);
                    }

                    await rcb.bulkImport(receiptContents);

                    if (BILL_VOID === action) {
                        const receiptContents = await createReceiptContent(orderObj, true, vatAdjustment);

                        if (OFFLOAD.sqliteOffloadPOSFE1427) {
                            await rcb.deleteByLocalId(originalReceipt.local_id);
                        }

                        if (receiptContents) {
                            await rcb.bulkImport(receiptContents);
                        }
                    }
                }
            }

            removeReceiptProcessing(orderObj._id);
        } catch (error) {
            console.error('An error occurred:', error);
            if (isNetworkStable()) {
                const locationId = orderObj?.locationId ?? window.locationId;
                const payload = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    url: window.location.href,
                }
                await storeToS3(payload, "SQLite-Errors", locationId);
            }
        } finally {
            removeReceiptProcessing(orderObj._id);
        }
    }

    async upsertVoidReceipt(receipt, void_receipt_num, orders) {
        receipt = cloneDeep(receipt);
        const rcb = new ReceiptContentBridge();
        const totals = orders.map(order => order?.preDiscountTotals ?? order.totals);

        const { rows } = await rcb.getRow({
            where: {
                receipt_local_id: receipt.local_id
            },
            whereIn: {
                type: "transactions, products, modifiers"
            }
        });

        const { ORIGINAL, VOIDED } = TRANSACTION_STATUS_ID;
        const originalLocalId = appendNumberToInteger(receipt.local_id, ORIGINAL);
        const modifiedReceipts = [];

        // ORIGINAL RECEIPT
        modifiedReceipts.push({
            ...receipt,
            id: null,
            transaction_status_id: ORIGINAL,
            local_id: originalLocalId,
            order_id: originalLocalId,
            synced_at: null,
        })

        // VOID RECEIPT
        modifiedReceipts.push({
            ...receipt,
            transaction_status_id: VOIDED,
            void_amount: formatAmount(sumArray(totals, 'total')),
            generated_void_amount: receipt.gross_sales,
            original_gross_sales: 0,
            tendered_amount: 0,
            discount_amount: 0,
            void_receipt_num,
            ...resetTotalsToZero(),
            synced_at: null,
        })

        await this.bulkImport(modifiedReceipts);

        const originalReceiptContents = [];
        const commonData = {
            id: null,
            receipt_content_id: null,
            transaction_id: null,
            receipt_id: null,
            receipt_local_id: originalLocalId,
            transaction_status_id: ORIGINAL,
            synced_at: null,
        };
        const generateUniqueId = () => uuidv4();
        const transformedArray = transformArray(rows);
        transformedArray.forEach(item => {
            const products = item.products;
            delete item.products;

            const transaction = Object.assign(
                {},
                item,
                commonData,
                { local_id: generateUniqueId() }
            );
            originalReceiptContents.push(transaction);

            products.forEach(p => {
                const modifiers = p.modifiers ?? [];
                delete p.modifiers;

                const product = Object.assign(
                    {},
                    p,
                    commonData,
                    { local_id: generateUniqueId(), transaction_local_id: transaction.local_id }
                );
                originalReceiptContents.push(product);

                modifiers.forEach(m => {
                    originalReceiptContents.push(Object.assign(
                        {},
                        m,
                        commonData,
                        { local_id: generateUniqueId(), transaction_local_id: transaction.local_id, receipt_content_local_id: product.local_id }
                    ));
                });
            });
        });

        const { PRODUCTS, MODIFIERS } = RECEIPT_CONTENT_TYPE;
        // VOID RECEIPT CONTENTS
        const receiptContents = rows.map(item => {
            const voidedQtyAndPrice = item.type === PRODUCTS
                ? { voided_qty: item.product_qty, voided_price: item.product_price, product_qty: 0, product_price: 0 }
                : (item.type === MODIFIERS ? { voided_qty: item.modifier_qty, voided_price: item.modifier_price } : {});

            return {
                ...item,
                transaction_status_id: VOIDED,
                cancellation_type_id: CANCELLATION_TYPE.VOID,
                ...voidedQtyAndPrice,
                ...resetTotalsToZero(),
                synced_at: null,
            };
        });

        await rcb.bulkImport([...receiptContents, ...originalReceiptContents]);
    }

    async updateData(payloads) {
        await this.sendMessage('UPDATE_DATA', { payloads });
    }

    async deleteReceiptsOlderThan() {
        const posDateBridge = new PosDateBridge();
        const business_date = await posDateBridge.getPosDate(window.locationId);

        const param = {
            business_date,
            numberOfDays: SQLITE_OFFLOAD_DELETE_RECEIPTS_OLDER_THAN
        };
        return await this.sendMessage('DELETE_RECEIPTS_OLDER_THAN', { param });
    }

    async getAllUnsyncedReceipts() {
        const { rows } = await this.sendMessage('GET_ALL_UNSYNCED_RECEIPTS');

        return rows;
    }

    async getUnsyncedReceiptsSortedByDate() {
        const { rows } = await this.sendMessage('GET_UNSYNCED_RECEIPTS_SORTED_BY_DATE', { param: {
                limit: SQLITE_OFFLOAD_MAX_RECEIPT_CONTENTS_COUNT
            }});

        return rows;
    }

    async getReceiptByLocalId(localId) {
        if (!localId) {
            return {};
        }

        return await this.sendMessage('GET_RECEIPT_BY_LOCAL_ID', { localId: parseInt(localId) });
    }

    async getReceiptsPaged(params = {}) {
        return await this.sendMessage('GET_RECEIPTS_PAGED', { params });
    }

    async getReceipts(params = {}) {
        const {rows} = await this.sendMessage('GET_RECEIPTS', { params });

        return rows;
    }

    async resetIsSyncing(posDate) {
        return await this.sendMessage('RESET_IS_SYNCING', { posDate });
    }

}
