<template>
  <div class="settings-page">
    <div class="d-flex flex-nowrap">
      <div class="px-lg-4 py-4 col-xl-8 d-flex align-items-center justify-content-between">
        <label>Settings Page</label>
        <sqlite-offload-force-sync v-if="isSqliteOffloadReceiptEnabled" class="d-none" />
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-xl-4 order-lg-12">
        <SettingsSidebar />
      </div>
      <div class="col-xl-3">
        <div class="tab-panel" id="no-selected" role="tabpanel">
            <div class="col-lg-4 col-md-12">

              <table class="dayend-tbl">
                <thead>
                  <tr>
                    <th class="cell100 column1">Denomination</th>
                    <th class="cell100 column2 pl-2">Bills</th>
                    <th class="cell100 column3 pl-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasCashFundPermission">
                    <denomination-item
                      v-for="denomination in billsDenomination"
                      :key="denomination"
                      :denomination="denomination"
                      :disabled="useWholeAmountInput"
                      @applyCashBreakdown="applyCashBreakdown"/>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">TOTAL CASH ON HAND: </td>
                      <td v-if="!useWholeAmountInput" class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td"> {{this.$filters.formatPrice(this.totalCash)}} </td>
                      <td v-else class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td">
                        <input type="number" v-model="totalCashOverride" />
                      </td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">+ CASH WITHDRAW: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.totalCashWithdraw)}}</td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- BEGINNING FUND: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.initialFund)}}</td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- ADDITIONAL FUND: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.totalAdditionalFund)}}</td>
                    </tr>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">= TOTAL CASH SALES: </td>
                      <td class="cell100 column3 text-center font-weight-bolder text-dark" id="x-read-cash-deposit-td">{{this.$filters.formatPrice(this.totalCashDeposit)}} </td>
                    </tr>
                  </template>
                  <template v-else>
                    <denomination-item
                      v-for="denomination in billsDenomination"
                      :key="denomination"
                      :denomination="denomination"
                      :disabled="useWholeAmountInput"
                      @applyCashBreakdown="applyCashBreakdown"/>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">TOTAL CASH: </td>
                      <td v-if="!useWholeAmountInput" class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td"> {{this.$filters.formatPrice(this.totalCash)}} </td>
                      <td v-else class="cell100 column3 text-center font-weight-bolder" id="z-read-total-cash-td">
                        <input type="number" v-model="totalCashOverride" />
                      </td>
                    </tr>
                    <tr class="body highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">- CASH FLOAT: </td>
                      <td class="cell100 column3 text-center font-weight-bolder" id="x-read-cash-float-td">{{this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId])}}</td>
                    </tr>
                    <tr class="highlighted-row-tb">
                      <td class="cell100 column2 text-dark font-weight-bolder" colspan="2" align="right">= TOTAL CASH DEPOSIT: </td>
                      <td class="cell100 column3 text-center font-weight-bolder text-dark" id="x-read-cash-deposit-td">{{this.$filters.formatPrice(this.totalCashDeposit)}} </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <label class="ml-0 mr-3 mt-2 align-self-center" id="useWholeAmountInput">
              <input type="checkbox" class="mr-0" v-model="useWholeAmountInput" /> OVERRIDE TOTAL CASH INPUT?
              </label>
            </div>
        </div>
      </div>
      <div v-if="!isSqliteOffloadReceiptEnabled && dataGenerationProgressPercentage < 1" class="data-generation-progress col-xl-5">
        <no-items
          icon="spinner"
          :icon-class="{ 'fa-spin': true }"
          :text="`Generating zread data ${ $filters.formatPrice(dataGenerationProgressPercentage * 100) }%`"
          />
      </div>
      <div v-else-if="isSqliteOffloadReceiptEnabled && progressText !== ''" class="data-generation-progress col-xl-5">
        <no-items
            icon="spinner"
            :icon-class="{ 'fa-spin': true, 'mb-5': true }"
            :text="progressText"
        />
      </div>
      <div v-else class="col-xl-5">
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-5">
            <div class="row">
              <div class="w-100">
                <table class="dayend-tbl table-responsive">
                  <thead>
                    <tr>
                      <th class="cell100 column1">Payment Types</th>
                      <th class="cell100 column2 pl-2 text-right">Expected</th>
                      <th class="cell100 column3 pl-2 text-right">Payment Amount</th>
                      <th class="cell100 column3 pl-2 text-right">Over/(Short)</th>
                    </tr>
                  </thead>
                  <tbody>

                    <payment-breakdown-item
                      v-for="(breakdown, index) in paymentMethodsWithoutGCExcess"
                      :key="index"
                      :paymentType="breakdown.name"
                      :expectedAmount=breakdown.amount
                      :isCash="breakdown.isCash"
                      :totalCashDeposit="totalCashDeposit"
                      :showSpotAudit="showSpotAudit"
                      :totalCashFloat="this.cashFloat[this.overrideTerminalId]"
                      @applyPaymentBreakdown="applyPaymentBreakdown"/>

                    <tr>
                      <td class="cell100 column1 text-dark font-weight-bolder">TOTALS</td>
                      <td class="cell100 column2 text-dark font-weight-bolder pl-2 text-right" id="x-read-ttl-sales"> <div class v-show="showSpotAudit">{{this.$filters.formatPrice(totalExpectedAmount)}}</div></td>
                      <td class="cell100 column3 text-right" id="x-read-payment-ttl"> {{this.$filters.formatPrice(totalPaymentAmount)}} </td>
                      <td class="cell100 column4 text-right " id="x-read-over-short-ttl-td" ><div class v-show="showSpotAudit">{{this.$filters.formatPrice(totalOverShortAmount)}}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-5">
            <div class="row">
              <div class="w-100">
                <table class="dayend-tbl">
                  <thead>
                    <tr>
                      <th class="cell100 column1">Detail</th>
                      <th class="cell100 column2 pl-2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="cell100 column1">Total Bills</td>
                      <td class="cell100 column2 pl-2" id="ttl-bill-td"> {{ this.totalBills }}</td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Avg per Check</td>
                      <td class="cell100 column2 pl-2" id="avg-check-td"> {{ this.$filters.formatPrice(this.avgPerCheck)  }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Total Pax</td>
                      <td class="cell100 column2 pl-2" id="ttl-pax-td"> {{ this.totalPax }}</td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Shift Total Amount</td>
                      <td class="cell100 column2 pl-2" id="ttl-amt-td"> {{ this.$filters.formatPrice(this.shiftTotalAmount) }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Total Amount</td>
                      <td class="cell100 column2 pl-2" id="ttl-amt-td"> {{ this.$filters.formatPrice(this.totalAmount) }} </td>
                    </tr>
                    <tr>
                      <td class="cell100 column1">Table Turned</td>
                      <td class="cell100 column2 pl-2" id="ttl-tableturn-td"> {{ this.totalTableTurned }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button
                class="btn btn-prim my-1"
                id="save-payment-ttl-btn"
                :disabled="!isCashInputted"
                v-text="'SAVE PAYMENT TOTALS'"
                @click="savePaymentTotals"
                />
            </div>
            <div class="col">
              <button class="btn btn-prim my-1" id="open-drawer-btn" @click="processCashDrawer">OPEN CASH DRAWER</button>
            </div>
            <div class="col">
              <button class="btn btn-prim my-1" :disabled="!isPaymentTotalsSaved" id="save-shift-btn" @click="dayEnd">Day End</button>
            </div>
            <div class="col">
              <button class="btn btn-can my-1" id="cancel-eod-btn" @click="cancelShiftChange">CANCEL</button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
                <input v-model="targetPosDate" type="date" class="form-control" name="z_read_date" id="z_read_date"
                    :max="maxDate"
                    onkeydown="return false">
            </div>
            <div class="col-md-6">
              <button class="btn btn-prim my-1" id="show-over-SC-btn" @click="clickShowSpotAudit">Show Spot Audit </button>
            </div>
            <div class="col-md-6" v-if="this.locationStatus == 'Inactive'">
              <button
                class="btn btn-prim my-1"
                id="show-over-SC-btn"
                :disabled="!selectedDateIsOlderThanPosDate"
                @click="printZRead(true)"
                >
                  PRINT EMPTY Z-READ
              </button>
            </div>
            <div class="col-md-6 d-flex justify-content-between" v-else>
              <button
                class="btn btn-prim my-1"
                id="show-over-SC-btn"
                style="width: 49%"
                :disabled="!selectedDateIsOlderThanPosDate"
                @click="printZRead(true)"
                >
                REPRINT Z-READ
              </button>
              <button
                class="btn btn-prim my-1"
                id="show-over-SC-btn"
                style="width: 49%"
                :disabled="!selectedDateIsOlderThanPosDate"
                @click="printProductMix(true)"
                >
                REPRINT PMIX
              </button>
            </div>
            <div class="col-md-6">
              <BPrintZReadSections
              :sections="recieptDetails.print_zread_sections"
              @printSections="printSeparateSections"
              />
            </div>
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
      <x-z-read-preview-modal
        reportType='zread'
        :recieptDetails="recieptDetails"
        :paymentMethods="paymentMethods"
        :incomeHeadSales="revenueBreakdown"
        :cashFloat="this.locationStatus == 'Inactive' ? 0.0 : cashFloat[this.overrideTerminalId]"
        :cashAdditional="this.locationStatus == 'Inactive' ? 0.0 : this.totalAdditionalFund"
        :cashWithdraw="this.locationStatus == 'Inactive' ? 0.0 : this.totalCashWithdraw"
        :lastKot="this.locationStatus == 'Inactive' ? 0 : lastKot"
        :lastBillNum="this.locationStatus == 'Inactive' ? 0 : lastBillNum"
        :totalPax="totalPax"
        :avgPerTransaction="avgPerCheck"
        :totalVatableSales="totalVatableSales"
        :totalSales="totalSales"
        :totalVatAmount="totalVatAmount"
        :totalNetSales="totalNetSales"
        :totalNetSalesWCharges="totalNetSalesWCharges"
        :totalVatExemptSales="totalVatExempSales"
        :totalZeroRatedSales="totalZeroRatedSales"
        :discountItems="totalPerDiscountItems"
        :serviceTypes="serviceTypesTotal"
        :totalCashValue="ttlCash"
        :totalCashSales="totalCashSales"
        :totalOverShort="totalOverShortAmount"
        :totalServiceCharge="totalServiceCharge"
        :totalOtherCharges="totalOtherCharges"
        :totalBeforeServiceCharge="totalBeforeServiceCharge"
        :totalWOVoidAndServiceCharge="totalWOVoidAndServiceCharge"
        :beginningSettlement="beginningSettlement"
        :endingSettlement="endingSettlement"
        :begBilledOrder="begBilledOrder"
        :endBilledOrder="endBilledOrder"
        :beginningBalance="beginningBalance"
        :endingBalance="endingBalance"
        :voidedSalesAmount="totalVoidedSales"
        :voidedBilledAmount="totalVoidedBilled"
        :beginningVoidAmount="beginningVoidAmount"
        :endingVoidAmount="endingVoidAmount"
        :beginningVoidOrder="begVoidOrder"
        :endingVoidOrder="lastVoidBillNum"
        :totalTransaction="totalTransaction"
        :beginningVoidNum="this.locationStatus == 'Inactive' ? 0 : beginningVoidNum"
        :endingVoidNum="endingVoidNum"
        :receiptDate="receiptDateString"
        :beginningOR="previousReceiptNum"
        :endingOR="lastReceiptNum"
        :totalGrossWithServiceCharge="totalGrossWithServiceCharge"
        :totalGrossWithServiceChargeWoVoid="totalGrossWithServiceChargeWoVoid"
        :totalVatAdjustment="totalVatAdjustment"
        :productMixData="generatePmixData()"
        :grandTotalPmix="generatePmixGrandTotal"
        :floats="cashFunds"
        :z-counter="zCounter"
        :locationStatus="this.locationStatus"
        :hourly-sales="this.hourlySales"
        :isPmixWPriceQtyAndAmountEnabled="isPmixWPriceQtyAndAmountEnabled"
        :has-cash-fund-permission="hasCashFundPermission"
        v-model="isXreadPreviewModalOpen"
      />
      <new-x-z-read-preview-modal
        reportType='zread'
        :recieptDetails="recieptDetails"
        :incomeHeadSales="revenueBreakdown"
        :paymentMethods="paymentMethods"
        :lastKot="this.locationStatus == 'Inactive' ? 0 : lastKot"
        :lastBillNum="this.locationStatus == 'Inactive' ? 0 : lastBillNum"
        :totalPax="totalPax"
        :avgPerTransaction="avgPerCheck"
        :totalVatableSales="totalVatableSales"
        :totalSales="totalSales"
        :totalVatAmount="totalVatAmount"
        :totalNetSales="totalNetSales"
        :totalNetSalesWCharges="totalNetSalesWCharges"
        :totalVatExemptSales="totalVatExempSales"
        :totalZeroRatedSales="totalZeroRatedSales"
        :discountItems="totalPerDiscountItems"
        :totalServiceCharge="totalServiceCharge"
        :totalOtherCharges="totalOtherCharges"
        :totalBeforeServiceCharge="totalBeforeServiceCharge"
        :totalWOVoidAndServiceCharge="totalWOVoidAndServiceCharge"
        :beginningSettlement="beginningSettlement"
        :endingSettlement="endingSettlement"
        :beginningBalance="beginningBalance"
        :endingBalance="endingBalance"
        :voidedSalesAmount="totalVoidedSales"
        :voidedBilledAmount="totalVoidedBilled"
        :beginningVoidAmount="beginningVoidAmount"
        :endingVoidAmount="endingVoidAmount"
        :endingVoidOrder="lastVoidBillNum"
        :totalTransaction="totalTransaction"
        :beginningVoidNum="this.locationStatus == 'Inactive' ? 0 : beginningVoidNum"
        :endingVoidNum="endingVoidNum"
        :receiptDate="receiptDateString"
        :endingOR="lastReceiptNum"
        :totalGrossWithServiceCharge="totalGrossWithServiceCharge"
        :totalGrossWithServiceChargeWoVoid="totalGrossWithServiceChargeWoVoid"
        :totalVatAdjustment="totalVatAdjustment"
        :z-counter="zCounter"
        :locationStatus="this.locationStatus"
        :floats="cashFunds"
        :hourly-sales="this.hourlySales"
        :has-cash-fund-permission="hasCashFundPermission"
        :productMixData="generatePmixData()"
        :isPmixWPriceQtyAndAmountEnabled="isPmixWPriceQtyAndAmountEnabled"
        v-model="isNewXreadPreviewModalOpen"
      />
      <eod-preview-modal
        reportType='eod'
        :recieptDetails="recieptDetails"
        :paymentMethods="paymentMethods"
        :incomeHeadSales="revenueBreakdown"
        :cashFloat="this.locationStatus == 'Inactive' ? 0.0 : cashFloat[this.overrideTerminalId]"
        :lastKot="this.locationStatus == 'Inactive' ? 0 : lastKot"
        :lastBillNum="this.locationStatus == 'Inactive' ? 0 : lastBillNum"
        :totalPax="totalPax"
        :avgPerTransaction="avgPerCheck"
        :totalVatableSales="totalVatableSales"
        :totalSales="totalSales"
        :totalVatAmount="totalVatAmount"
        :totalNetSales="totalNetSales"
        :totalNetSalesWCharges="totalNetSalesWCharges"
        :totalVatExemptSales="totalVatExempSales"
        :totalZeroRatedSales="totalZeroRatedSales"
        :discountItems="totalPerDiscountItems"
        :serviceTypes="serviceTypesTotal"
        :totalCashValue="totalCash"
        :totalCashSales="totalCashSales"
        :totalEODCashSales="totalEODCashSales"
        :totalNonCashSales="totalNonCashSales"
        :totalEODNonCashSales="totalEODNonCashSales"
        :totalOverShort="totalOverShortAmount"
        :totalServiceCharge="totalServiceCharge"
        :totalBeforeServiceCharge="totalBeforeServiceCharge"
        :beginningSettlement="beginningSettlement"
        :endingSettlement="endingSettlement"
        :begBilledOrder="begBilledOrder"
        :endBilledOrder="endBilledOrder"
        :beginningBalance="beginningBalance"
        :endingBalance="endingBalance"
        :voidedSalesAmount="totalVoidedSales"
        :voidedBilledAmount="totalVoidedBilled"
        :beginningVoidAmount="beginningVoidAmount"
        :endingVoidAmount="endingVoidAmount"
        :beginningVoidOrder="begVoidOrder"
        :endingVoidOrder="endVoidOrder"
        :totalTransaction="totalTransaction"
        :beginningVoidNum="this.locationStatus == 'Inactive' ? 0 : beginningVoidNum"
        :endingVoidNum="endingVoidNum"
        :receiptDate="receiptDateString"
        :beginningOR="previousReceiptNum"
        :endingOR="lastReceiptNum"
        :totalGrossWithServiceCharge = "totalGrossWithServiceCharge"
        :totalVatAdjustment="totalVatAdjustment"
        :nonRevenueOrders="nonRevenueOrders"
        :totalGrossNet="totalAmount"
        :cashierShiftsData="shiftChangeRecord"
        :productMixData="generatePmixData()"
        v-model="isEodPreviewModalOpen"
      />
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import DenominationItem from '@/spa/components/common/DenominationItem';
import PaymentBreakdownItem from '@/spa/components/common/PaymentBreakdownItem';
import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';
import { pick, sumBy, isEmpty, lowerCase, uniqBy, forEach, debounce, orderBy, get, concat, map, sum } from 'lodash';
import cloneDeep from 'rfdc/default';
import {
  getPaymentMethods,
  getReceiptDetails,
  logout,
  updateOrCreateAttendance
} from '@/spa/services/cashier-service';
import XZReadPreviewModal from '@/spa/components/modals/XZReadPreviewModal';
import NewXZReadPreviewModal from '@/spa/components/modals/NewXZReadPreviewModal';
import EodPreviewModal from "@/spa/components/modals/EodPreviewModal";
import { getAllDiscountItems } from '@/spa/services/discount-service';
import { print, openCashDrawer, getPrinterData } from "@/spa/services/printer-service";
import eodHtml from '@/spa/components/templates/print/eod.html';
import { convert } from 'html-to-text';
import {
  recordDayend,
  saveDayendData,
  getReprintData,
  getReprintDataReceiptBased,
  generateTxtFile,
  getProductMixData,
  getProductMixDataReceiptBased,
} from '@/spa/services/dayend-service';
import {
  getSyncedOrders,
  cleanupOlderOrders,
  cleanupOlderSettlements,
  storeShiftTable,
} from '@/spa/services/sync-service';
import { submitTransactionsToIkonomikal } from '@/spa/services/ikonomikal-service';
import moment from 'moment';
import { checkCloudConnection } from '@/spa/plugins/axios';
import { getTerminals } from "@/spa/services/cashier-service";
import { VAT_EXCL } from '../../../vue/helper/discount';

import NoItems from '@/spa/components/placeholders/NoItems';
import {
  DEXIE_TABLES,
  PERMISSION_TYPES,
  GET_BREAKDOWNS_FROM_DB as DEFAULT_GET_BREAKDOWNS_FROM_DB,
  ENABLE_NO_TRANSACTION_MODAL,
  ENABLE_ZREAD_TEXT_FILE_GENERATION,
  ENABLE_FORBID_DAYEND_NO_CASH_INPUT,
  LOCATION_ACCREDITATION_TYPE_ID,
  LOCATION_ACCREDITATION_TYPE,
  ENABLE_IKONOMIKAL_INTEGRATION,
  ENABLE_DAYEND_FTP,
  IS_ROBINSON_ACCREDITED,
  ENABLE_EPEI_REQUIRE_BEG_CASH_FUND,
  USE_SM_MARKETS_OR_FORMAT,
  ENABLE_SALES_CONSOLIDATOR,
  ENABLE_CASH_FUND_IMPROVEMENT,
  OFFLOAD,
  USE_RECEIPT_BASED,
  ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
  ENABLE_SQLITE_AUTO_BACK_UP_ON_DAY_END,
} from '@/spa/constants';

import BPrintZReadSections from '@/spa/components/common/BPrintZReadSections';
import {SeparateSectionMixin} from '@/spa/components/mixins/ReportMixin'
import {dbService} from "@/spa/services/db-service";
import { IS_NON_VAT } from "@/spa/constants";
let GET_BREAKDOWNS_FROM_DB = DEFAULT_GET_BREAKDOWNS_FROM_DB;
import {storeAuditLog, storeToS3} from '@/spa/services/logger-service';
import { clearStorage } from '@/spa/plugins/vuex';
import { clearAxiosCache } from '@/spa/plugins/axios';
import { runDbfDownloadFlow } from '../../services/dayend-dbf-service';
import { generateAndSendFile } from '@/spa/services/mall-accreditation-service';
import productMixHtml from '@/spa/components/templates/print/product_mix.html';
import { Logger } from "@/spa/helpers/Logger";
import SqliteOffloadForceSync from "@/spa/components/pages/components/SqliteOffloadForceSync";
import bus from "@/spa/utils/bus";
import {calculatePmixSummary} from "@/mobile_bridge/offload/offload-receipt";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {generateDateTime, TRANSACTION_STATUS_ID} from "@/mobile_bridge/offload/receipt-model";
import { MultiterminalUtilityBridge, PrimaryBroadcastUtilityBridge } from '@/mobile_bridge/multiterminal/bridges';
import seriesService from "@/spa/services/series-service";
import {BackupUtilityBridge} from "@/mobile_bridge/offload/backup";
import {SHIFT_TABLE_LOCAL_ID} from "@/mobile_bridge/offload/shift-table";

export default {

  components: {
    SettingsSidebar,
    DenominationItem,
    PaymentBreakdownItem,
    XZReadPreviewModal,
    NewXZReadPreviewModal,
    EodPreviewModal,
    NoItems,
    BPrintZReadSections,
    SqliteOffloadForceSync
  },

  mixins: [SeparateSectionMixin],

  data() {
    return {
      cashBreakDown: {},
      dayBilledOrders: [],
      billedOrdersPerPosDate: [],
      settledOrderPerDay: [],
      revenueBreakdown: [],
      revenueBreakdownPerDay: [],
      paymentBreakDown: [],
      computedPaymentBreakdown: [],
      totalPaymentAmount: 0,
      totalOverShortAmount: 0,
      paymentMethods: [],
      totalExpectedAmount: 0,
      showSpotAudit: false,
      isXreadPreviewModalOpen: false,
      isNewXreadPreviewModalOpen: false,
      isCashInputted: false,
      recieptDetails: {},
      totalVatExempSales: 0,
      totalZeroRatedSales: 0,
      totalVatableSales: 0,
      totalNetSales: 0,
      totalNetSalesWCharges: 0,
      totalVatAmount: 0,
      totalPerDiscountItems: [],
      serviceTypesTotal: [],
      totalCashSales: 0,
      totalNonCashSales: 0,
      totalEODCashSales: 0,
      totalEODNonCashSales: 0,
      totalServiceCharge: 0,
      totalOtherCharges: 0,
      totalVoidedSales: 0,
      totalBeforeServiceCharge: 0,
      totalWOVoidAndServiceCharge: 0,
      beginningSettlement: {},
      endingSettlement: {},
      begBilledOrder: {},
      endBilledOrder: {},
      dayVoidOrders: [],
      begVoidOrder:{},
      endVoidOrder: {},
      receiptDateString: '',
      orders: [],
      isPaymentTotalsSaved: false,
      targetPosDate: new Date().toISOString().split('T')[0],
      totalSales: 0,
      totalGrossWithServiceCharge: 0,
      totalGrossWithServiceChargeWoVoid: 0,
      totalVatAdjustment: 0,
      totalRegularVatAdjustment: 0,
      totalPwdScVatAdjustment: 0,
      accreditationTypeId: LOCATION_ACCREDITATION_TYPE_ID,
      endingVoidNum: 0,
      totalItemVoidedSales: 0,
      dateFilter: '',
      receiptDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      isEodPreviewModalOpen: false,
      nonRevenueOrders: [],
      debouncedGenerateData: () => null,
      dataGenerationProgressPercentage: 0,
      currentPosDateDataFromDb: {},
      reportTypeTitle: 'Z-Reading',
      sectionsValues: {
        header: '',
        over_short: '',
        pmix: '',
      },
      locationStatus: '',
      useWholeAmountInput: false,
      totalCashOverride: null,
      reportType: 'zread',
      hourlySales: {},
      isSqliteOffloadReceiptEnabled: OFFLOAD.sqliteOffloadReceipt,
      sqliteOffloadProductMix: {},
      progressText: "Checking data...",
      isPmixWPriceQtyAndAmountEnabled: USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
      sqliteBilledOrders: [],
    };
  },

  watch: {
    syncStatus: {
      handler(value) {
        if(value !== 'synced') return;
        this.reloadSyncedData()
          .then(async () => {
            this.debouncedGenerateData();
            window.__hideLoader();
          });
      },
      immediate: true,
    },
  },

  computed: {

    ...mapState([
        'activeBrandId',
        'queueStatus',
    ]),

    ...mapState({
      originalOrders: state => state.orders,
      userId: state => state.user.userId,
    }),

    ...mapState('user', ['terminalId', 'accountId', 'username', 'locationId']),

    ...mapState('settings', [
      'cashFloat',
      'terminalsCashFund',
      'billsDenomination',
      'shiftTable',
      'posDate',
      'beginningBalance',
      'beginningVoid',
      'beginningVoidAmount',
      'shiftTable',
      'previousVoidNum',
      'previousReceiptNum',
      'currentShift',
      'serviceTypes',
      'shiftChangeRecord',
      'dayEndRecord',
    ]),

    ...mapState('global', ['syncStatus']),

    ...mapGetters(['pendingOrders']),

    isQueueCompleted() {
      if (isEmpty(this.queueStatus)) {
        return true;
      }

      return !Object.values(this.queueStatus).some(s => s.is_sync == 0 || s == 0);
    },

    isNoCashDayendForbidden() {
      return ENABLE_FORBID_DAYEND_NO_CASH_INPUT;
    },

    overrideTerminalId() {
      const terminal = JSON.parse(sessionStorage.getItem('terminal'));
      return terminal?.id || this.terminalId;
    },

    zCounter() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.z_counter;
      }

      return 0;
    },

    ttlCash() {
      return sumBy(Object.values(this.currentPosDateDataFromDb?.cash_drawers ?? {}), 'ttl_cash_drawer') || this.totalCash;
    },

    totalCash() {
      var sum = 0;
      if(this.useWholeAmountInput) {
        sum = this.totalCashOverride ?? 0;
      } else {
        this.totalCashOverride = null
        for (var el in this.cashBreakDown ) {
          if(el in this.cashBreakDown) {
            sum += parseFloat( this.cashBreakDown[el] );
          }
        }
      }
      this.setIsCashInputted();
      return sum;
    },

    cashFunds() {
      return this.terminalsCashFund;
    },

    totalCashWithdraw() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.overrideTerminalId]["Withdraw"];
      }
      return 0;
    },

    totalAdditionalFund() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.overrideTerminalId]["Additional Fund"];
      }
      return 0;
    },

    initialFund() {
      if (this.hasCashFundPermission) {
        return this.cashFunds["Terminal " + this.overrideTerminalId]["Beginning Fund"];
      }
      return 0;
    },

    totalCashDeposit() {
      // Cash Float includes Beginning Fund and Additional Fund
      if (this.hasCashFundPermission) {
        return this.totalCash > 0 ? this.totalCash + this.totalCashWithdraw - this.initialFund - this.totalAdditionalFund : this.totalCash;
      }
      return this.totalCash > 0 ? this.totalCash - this.cashFloat[this.overrideTerminalId] : this.totalCash;
    },

    totalCashFloatRecorded() {
      return this.totalCash > 0 ? this.totalCash - this.totalCashDeposit : 0;
    },

    totalBills() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_paid_count;
      }

      return this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).length;
    },

    totalPax() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_guest_count;
      }

      const totalPax = this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.pax;
      }, 0);

      return totalPax;
    },

    totalTableTurned() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_table_turned_cout;
      }

      // this still not sure if we need to add voided transaction for table turned.
      return this.settledOrderPerDay.length;

    },

    shiftTotalAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ttl_gross_sales;
      }

      const shiftTotalAmount = this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return shiftTotalAmount;

    },

    voidedSalesAmount() {

      const voidedSalesAmount = this.dayVoidOrders.reduce((accumulator, object) => {
        let total = object.totals.rawPrice;
        if(object.totals.isScInclusive == 0) // exclusive
          total += object.totals.serviceCharge;

        return accumulator + total;

      }, 0);

      return voidedSalesAmount;

    },

    totalAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_total_amount;
      }

      const totalAmount = this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return totalAmount;

    },

    avgPerCheck() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.current_avg_per_trans;
      }

      const settledBilled = this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided);

      const shiftTotalAmount = settledBilled.reduce((accumulator, object) => {
        return accumulator + object.totals.total;
      }, 0);

      return shiftTotalAmount / settledBilled.length;

    },

    endingBalance() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ending_bal;
      }

      if (USE_SM_MARKETS_OR_FORMAT) {
        const grossAmount = this.totalAmount + this.totalDiscounts + this.totalVatAdjustment + this.totalServiceCharge;
        return this.beginningBalance + grossAmount;
      } else {
        return this.totalNetSales + this.beginningBalance;
      }
    },

    endingVoidAmount() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.ttl_void_amount;
      }

      return this.totalVoidedSalesAmount + this.beginningVoidAmount;
    },

    totalTransaction() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb.total_transaction_count;
      }

      return this.dayBilledOrders.length;
    },

    totalDiscounts() {

      const totalDiscounts = this.totalPerDiscountItems.reduce((accumulator, object) => {
        return accumulator + object.amount;
      }, 0);

      return totalDiscounts;
    },

    lastKotNum() {

      // last kot will accumulate once its settled or voided
      const billedAndVoidOrders = this.orders.filter(s => (s.isSettled == true || s.isVoided) && moment(s?.originShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter);
      if(billedAndVoidOrders.length > 0){
        const lastbilledAndVoidOrders = billedAndVoidOrders[billedAndVoidOrders.length - 1];
        const kots = lastbilledAndVoidOrders.kots;
        return Math.max(...kots);
      }

      return this.lastKot
    },

    hasPendingOrders() {
      return !isEmpty(this.pendingOrders);
    },

    totalVoidedSalesAmount() {
      return this.totalItemVoidedSales + this.voidedSalesAmount;
    },

    filterOrderPerDay () {
      if (OFFLOAD.sqliteOffloadReceipt) {
        return this.sqliteBilledOrders;
      }

      const billedOrders = uniqBy([...this.orders, ...this.originalOrders], '_id').filter(o => (moment(o?.billedShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter || moment(o?.originShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter));
      return billedOrders;
    },

    maxDate() {
      if (!this.posDate) return new Date().toISOString().split('T')[0];

      let d = new Date(this.posDate);
      d.setDate(d.getDate() - 1);
      return moment(d.toISOString()).format("YYYY-MM-DD");
    },

    selectedDateIsOlderThanPosDate() {
      return moment(this.targetPosDate).isBefore(this.posDate);
    },

    hasZread () {
      return this.filterOrderPerDay.length > 0;
    },

    paymentMethodsWithoutGCExcess() {
      return this.paymentMethods.filter(paymentMethod => paymentMethod.name !== 'GC EXCESS');
    },

    hasCashFundPermission() {
      return ENABLE_CASH_FUND_IMPROVEMENT || (this.$can(this.PERMISSIONS.CASH_FUND_MANAGEMENT) || false);
    },

    totalCashFloat() {
        const cashFloat = this.cashFloat[this.overrideTerminalId];
        let cashFund = typeof cashFloat === 'object'
            ? cashFloat.cash_float
            : cashFloat;

        if (this.hasCashFundPermission) {
            const cashFunds = this.cashFunds[`Terminal ${this.overrideTerminalId}`];
            cashFund -= cashFunds['Withdraw'];
        }

        return cashFund;
    },

    generatePmixGrandTotal() {
      if (GET_BREAKDOWNS_FROM_DB) {
        return this.currentPosDateDataFromDb?.product_mix_grand_total
      }

      return this.computePmixGrandTotal(this.generatePmixData());
    }
  },

  async mounted() {
    bus.on("dayEndGenerateData", async (value) => {
      if (value != null) {
        this.progressText = `Syncing data ${value}%`;

        if (value >= 100) {
          await this.fetchCurrentPosDateData();

          setTimeout(async() => {
            await this.generateData();
            this.computeTotalsInPaymentTypes();
          }, 1000)
        }

        return;
      }

      Swal.fire({
        text: "An error occurred during syncing. Please try again.",
        confirmButtonText: 'Retry Sync',
        allowOutsideClick: false,
        icon: 'error',
        customClass: {
          actions: 'swal-button-nowrap'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          bus.emit("triggerSqliteOffloadForceSync");
        }
      })
    })

    if (!OFFLOAD.sqliteOffloadReceipt) {
      await this.forceSyncOrders(this.posDate);
    }

    await this.fetchCurrentPosDateData();

    if (!OFFLOAD.sqliteOffloadReceipt) {
      this.debouncedGenerateData = debounce(this.generateData, 200);

      //if syncing or unsync show loading
      if(!this.isQueueCompleted) {
        this.transactionsNotSyncedAlert();
      }
    }

    if (!this.$can(this.PERMISSIONS.DAY_END) && !ENABLE_SALES_CONSOLIDATOR) {
        this.$swal.warning('You do not have permission to day end. Please contact your administrator.');
        this.$router.push({ name: 'home' });
        return;
    }

    if (!OFFLOAD.sqliteOffloadReceipt && this.hasPendingOrders) {
      this.showHasPendingAlert();
      return;
    }

    if (!OFFLOAD.sqliteOffloadReceipt) {
      const day_transaction_count = this.currentPosDateDataFromDb?.total_transaction_count ?? 0;
      const day_void_count = this.currentPosDateDataFromDb?.total_voided_count ?? 0;
      const all_count = day_transaction_count + day_void_count;
      if (ENABLE_NO_TRANSACTION_MODAL && all_count == 0) {
        this.showHasNoTransactionsAlert();
      }
    }

    this.dateFilter = moment(this.posDate).format("YYYY-MM-DD");

    if (OFFLOAD.sqliteOffloadReceipt) {
      const rb = new ReceiptBridge();
      const ob = new OrderBridge();
      const pendingOrders = await ob.getPendingOrders();
      const { PAID, VOIDED, ORIGINAL } = TRANSACTION_STATUS_ID;

      if (pendingOrders.length > 0) {
        await this.sqliteFetchPendingOrders();
        await this.showHasPendingAlert();
        return;
      }

      const unSyncedReceipts = OFFLOAD.useGetReceipts
        ? await rb.getReceipts({
          whereNotNull: ['bill_num'],
          whereNull: ['synced_at'],
          whereNotEqual: {
            is_syncing: 1
          },
          whereIn: {
            transaction_status_id: `${PAID}, ${VOIDED}, ${ORIGINAL}`
          },
        })
        : await rb.getAllUnsyncedReceipts();

      if (unSyncedReceipts.length > 0) {
        this.progressText = 'Syncing data 0%';
        bus.emit('triggerSqliteOffloadForceSync');

        return;
      }
    }

    await this.reloadSyncedData();

    await this.generateData();

    this.computeTotalsInPaymentTypes();

    if (window.TEST_IKONOMIKAL_INTEGRATION) {
      const shouldSend = window.TEST_IKONOMIKAL_INTEGRATION === 'submit';
      await submitTransactionsToIkonomikal(this.locationId, this.posDate, !shouldSend);
    }
  },

  methods: {
    ...mapMutations(['clearOrders', 'clearSettlements']),
    ...mapMutations('settings', ['addDayendRecord', 'setBeginningBalance', 'setBeginningVoidAmount']),
    ...mapActions(['forceSyncOrders', 'sqliteFetchPendingOrders']),

    async reloadSyncedData() {
      this.orders = await getSyncedOrders();
    },

    async fetchCurrentPosDateData() {
      try {
          if (USE_RECEIPT_BASED) {
            const { data } = await getReprintDataReceiptBased({
              zReadDate: this.posDate,
              posDate: this.posDate,
              locationId: this.locationId,
              terminalId: this.overrideTerminalId,
              userId: this.userId,
              dayEndData: {},
              shiftData: {},
              useSqliteOffload: OFFLOAD.sqliteOffloadReceipt
            });
            this.currentPosDateDataFromDb = data;
          } else {
            const { data } = await getReprintData(this.posDate);
            this.currentPosDateDataFromDb = data;
          }
          console.log({ data: cloneDeep(this.currentPosDateDataFromDb) });
        } catch (e) {
          console.error(e);
          Swal.fire({
            title: 'Error',
            text: 'Unable to fetch data from database. Please check your connection and refresh the page.',
            icon: 'error',
          }).then((result) => {
            if (result.isConfirmed) this.transactionsNotSyncedAlert(true);
          });
        }
    },

    resetData() {
      this.totalBeforeServiceCharge = 0;
      this.totalWOVoidAndServiceCharge = 0;
      this.totalCashSales = 0;
      this.totalEODCashSales = 0;
      this.totalEODNonCashSales = 0;
      this.totalExpectedAmount = 0;
      this.totalGrossWithServiceCharge = 0;
      this.totalGrossWithServiceChargeWoVoid = 0;
      this.totalItemVoidedSales = 0;
      this.totalNetSales = 0;
      this.totalNetSalesWCharges = 0;
      this.totalNonCashSales = 0;
      this.totalSales = 0;
      this.totalServiceCharge = 0;
      this.totalOtherCharges = 0;
      this.totalVatableSales = 0;
      this.totalVatAdjustment = 0;
      this.totalRegularVatAdjustment = 0;
      this.totalPwdScVatAdjustment = 0;
      this.totalVatAmount = 0;
      this.totalVatExempSales = 0;
      this.totalZeroRatedSales = 0;
    },

    async generateData(dateFilter = null) {
      if(dateFilter) {
        this.dateFilter = dateFilter;
      }

      let isReinstantiate = false;
      if(dateFilter !== moment(this.posDate).format("YYYY-MM-DD")) {
        isReinstantiate = true;
      }

      this.resetData();

      await this.$nextTick();

      this.filterOrderBilledPerPosDate();
      this.filterSettledOrderPerDay();
      await this.getSqliteOrders();

      this.setDataGenerationProgressPercentage(0);
      await this.filterVoidOrderPerDay();
      this.setDataGenerationProgressPercentage(1/7);
      await this.fetchPaymentMethods(isReinstantiate);
      this.setDataGenerationProgressPercentage(2/7);
      await this.generatePaymentBreakdown();
      this.setDataGenerationProgressPercentage(3/7);
      await this.generateRevenueBreakdownPerDay();
      this.setDataGenerationProgressPercentage(4/7);
      await this.parseReceiptDetails();
      this.setDataGenerationProgressPercentage(5/7);
      await this.fetchDiscountItems();
      this.setDataGenerationProgressPercentage(6/7);
      await this.generateZReadDetails();
      this.setDataGenerationProgressPercentage(1);

      this.progressText = "";

      if (OFFLOAD.sqliteOffloadReceipt) {
        const rcb = new ReceiptContentBridge();
        this.sqliteOffloadProductMix = await rcb.generatePMIX(this.posDate);
      }
    },

    setDataGenerationProgressPercentage(percentage) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        this.progressText = `Generating Z-Read data ${ this.$filters.formatPrice(percentage * 100) }%`;

        return;
      }

      this.dataGenerationProgressPercentage = percentage;
    },

    async fetchDiscountItems() {

      this.totalPerDiscountItems = [];

      try {
          const response = await getAllDiscountItems();
          this.totalPerDiscountItems = response.data.discounts
              .map(d => ({ name: d.discount_name, amount: 0}));
      } catch (e) {
          console.error(e);
      }

    },

    async showHasPendingAlert() {
      await Swal.fire({
          icon: 'error',
          title: 'You still have pending transactions!',
          text: 'Please settle all transactions before filing your Day End report.',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1b75bb',
          customClass: { actions: 'print-swal-action' },
      });

      this.$router.push({ name: 'pending' });
    },

    async showHasNoTransactionsAlert() {
      await Swal.fire({
          icon: 'error',
          title: 'You do not have any transactions!',
          text: 'No synced transactions for this business date yet. Are you sure you want to dayend?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          confirmButtonColor: '#1b75bb',
          cancelButtonColor: '#f47070',
          customClass: { actions: 'print-swal-action' },
      }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
          } else {
            this.$router.push({ name: 'home' });
          }
      })
    },

    // copy-paste from BasePage.vue
    async saveAuditLog(description) {
      const params = {
        locationId: window.locationId,
        billingId: null,
        transactionId: null,
        terminalId: this.$tempTerminalId,
        title: description,
        action: description,
        userId: window.userId,
        businessDate: tempPosDate,
        description
      }

      try {
        await storeAuditLog(params);
      } catch(e) {
        console.error(e);
      }
    },

    async fetchPaymentMethods(isReinstantiate = false) {
        if(isReinstantiate) {
          this.paymentMethods = [];
        }
        try {
            const response = await getPaymentMethods();
            this.paymentMethods = response.data
                .filter(p => p.brand_id == this.activeBrandId)
                .map(p => ({ name: p.payment_name, amount: 0, isCash: p.payment_name === "CASH"}));
        } catch (e) {
            console.error(e);
        }
    },

    async generatePaymentBreakdown() {
      let gcExcess = {amount: 0, count: 0};

      if (GET_BREAKDOWNS_FROM_DB) {
        this.paymentMethods = Object.keys(this.currentPosDateDataFromDb.tender_summary)
          .map(name => ({ name, amount: this.currentPosDateDataFromDb.tender_summary[name], isCash: name.toUpperCase() === "CASH"}));

          this.totalCashSales = 0;
          this.totalNonCashSales = 0;
          this.totalEODCashSales = 0;
          this.totalEODNonCashSales = 0;

        this.paymentMethods.forEach((p, index) => {
          if (p.name.toUpperCase() === 'CASH') {
            this.totalCashSales += parseFloat(p.amount);
            this.totalEODCashSales += parseFloat(p.amount);
          } else {
            this.totalNonCashSales += parseFloat(p.amount);
            this.totalEODNonCashSales += parseFloat(p.amount);
          }
          this.totalExpectedAmount += parseFloat(p.amount);
          this.paymentMethods[index].count = get(this.currentPosDateDataFromDb, `tender_counts.${p.name}`, 0);
        });

        return;
      }

      //reset values to 0
      this.totalExpectedAmount = 0;
      this.totalEODCashSales = 0;
      this.totalCashSales = 0;
      this.totalEODNonCashSales = 0;
      this.totalNonCashSales = 0;

      this.dayBilledOrders.forEach((fs) => {
        if (fs.isVoided) return;
        if (!fs.payments || fs.payments.length === 0) {
          fs.payments = fs.splits?.map(s => s.payments || []).flat() || [];
        }
        fs.payments.forEach((p, i) => {
          let objIndex = this.paymentMethods.findIndex((obj) => obj.name == p.method);

          let amount = parseFloat(p.exact_amount);
          if (i === fs.payments.length - 1) {
            amount -= parseFloat(fs.change ?? 0);
          }

          if (objIndex < 0) {
            this.paymentMethods.push({
              name: p.method,
              amount: 0,
              isCash: p.method.toUpperCase() === "CASH",
            });
            objIndex = this.paymentMethods.length - 1;
          }

          this.paymentMethods[objIndex].amount += amount;
          this.paymentMethods[objIndex].count = this.paymentMethods[objIndex].count
            ? this.paymentMethods[objIndex].count + 1
            : 1;
          this.totalExpectedAmount += amount;
          if (this.paymentMethods[objIndex].name == "CASH") {
            this.totalEODCashSales += amount;
            this.totalCashSales += amount;
          } else {
            this.totalEODNonCashSales += amount;
            this.totalNonCashSales += amount;
          }
        }, this);

        if (this.checkIfHasGcExcess(fs)) {
          gcExcess.amount += fs.change;
          gcExcess.count += 1;
        }
      });

      if(gcExcess.amount) {
        this.paymentMethods = [
            ...this.paymentMethods,
            { name: 'GC EXCESS', amount: gcExcess.amount, isCash: false, count: gcExcess.count }
        ]
      }
    },

    async parseReceiptDetails() {
        try {
            let receiptResponse = await getReceiptDetails();
            this.recieptDetails = Object.values(receiptResponse.data).find(d=>d.brand_id == this.activeBrandId);
        } catch (error) {
            console.log(error);
        }

        return {};
    },

    async generateZReadDetails() {
      if (GET_BREAKDOWNS_FROM_DB) {
        this.nonRevenueOrders = []; // have to get from local
        this.hourlySales = this.currentPosDateDataFromDb.hourly_sales;
        this.serviceTypesTotal = Object.keys(this.currentPosDateDataFromDb.service_types)
          .map(name => ({
            name,
            amount: this.currentPosDateDataFromDb.service_types[name].value,
            count: this.currentPosDateDataFromDb.service_types[name].count,
          }));
        this.totalBeforeServiceCharge = this.currentPosDateDataFromDb.gross_wo_svc_charge;
        this.totalWOVoidAndServiceCharge = this.currentPosDateDataFromDb.gross_wo_svc_charge_and_void_sales;
        this.totalGrossWithServiceCharge = this.currentPosDateDataFromDb.gross_sales_w_svc;
        this.totalGrossWithServiceChargeWoVoid = this.currentPosDateDataFromDb.gross_sales_w_svc_wo_void_sales;
        this.totalItemVoidedSales = this.currentPosDateDataFromDb.void_balance;
        this.totalNetSales = this.currentPosDateDataFromDb.ttl_net_sales;
        this.totalNetSalesWCharges = this.currentPosDateDataFromDb.ttl_net_sales_w_charges;
        this.totalPerDiscountItems = Object.keys(this.currentPosDateDataFromDb.discounts)
          .map(name => ({
            name,
            amount: this.currentPosDateDataFromDb.discounts[name],
          }));
        this.totalSales = this.currentPosDateDataFromDb.ttl_gross_sales;
        this.totalServiceCharge = this.currentPosDateDataFromDb.paid_svc_charge;
        this.totalOtherCharges = this.currentPosDateDataFromDb.ttl_other_charges;
        this.totalVoidedSales = this.currentPosDateDataFromDb.void_balance;
        this.totalVoidedBilled = this.currentPosDateDataFromDb?.void_billed_balance?? 0;
        this.totalVatableSales = this.currentPosDateDataFromDb.ttl_vatable_sales;
        this.totalVatAdjustment = this.currentPosDateDataFromDb.vat_adjustment;
        this.totalRegularVatAdjustment = this.currentPosDateDataFromDb.regular_vat_adjustment;
        this.totalPwdScVatAdjustment = this.currentPosDateDataFromDb.pwd_sc_vat_adjustment;
        this.totalVatAmount = this.currentPosDateDataFromDb.ttl_vat_amount;
        this.totalVatExempSales = this.currentPosDateDataFromDb.vats['Vat Exempt Sales'];
        this.totalZeroRatedSales = this.currentPosDateDataFromDb.vats['Zero Rated Sales'];

        this.beginningSettlement = {
          bill_num: this.currentPosDateDataFromDb.first_bill,
          receipt_num: this.currentPosDateDataFromDb.beg_invoice,
          void_bill_num: this.currentPosDateDataFromDb.beg_void,
        };
        this.begBilledOrder = this.beginningSettlement;
        this.endingSettlement = {
          bill_num: this.currentPosDateDataFromDb.ending_bill ?? this.currentPosDateDataFromDb.first_bill,
          receipt_num: this.currentPosDateDataFromDb.end_invoice ?? this.currentPosDateDataFromDb.beg_invoice,
          void_bill_num: this.currentPosDateDataFromDb.end_void ?? this.currentPosDateDataFromDb.beg_void,
        };
        this.endBilledOrder = this.endingSettlement;

        this.begVoidOrder = this.begBilledOrder;
        this.endVoidOrder = this.endBilledOrder;
        this.beginningVoidNum = this.begVoidOrder.void_bill_num;
        this.endingVoidNum = this.endVoidOrder.void_bill_num;

        this.setBeginningBalance(this.currentPosDateDataFromDb.beginning_bal);
        this.setBeginningVoidAmount(this.currentPosDateDataFromDb.beg_void_amt);

        this.locationStatus = this.currentPosDateDataFromDb.location_status

        return;
      }

      this.serviceTypesTotal = [];

      this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).forEach(
        sbo => {
          let vatExemptSales = sbo.totals.vatExemptSales || 0;
          let zeroRatedSales = sbo.totals.zeroRatedSales || 0;
          this.totalVatExempSales += vatExemptSales;
          this.totalZeroRatedSales += zeroRatedSales;
          this.totalSales += sbo.totals.total;
          this.totalNetSales += sbo.totals.net;
          this.totalVatAmount += sbo.totals.vat;
          this.totalServiceCharge += sbo.totals.serviceCharge;

          let grossWoSc = sbo.totals.rawPrice;
          let grossWithSC = sbo.totals.rawPrice;

          if(sbo.totals.isScInclusive == 1){ // inclusive
            grossWoSc -= sbo.totals.serviceCharge;
          } else { // exclusive
            grossWithSC += sbo.totals.serviceCharge;
          }

          //other charges
          if(sbo.totals.otherCharges) {
            for(const chargeName in sbo.totals.otherCharges) {
              this.totalOtherCharges += sbo.totals.otherCharges[chargeName];
            }
          }

          this.totalBeforeServiceCharge += grossWoSc;
          this.totalGrossWithServiceCharge += grossWithSC
          this.totalGrossWithServiceChargeWoVoid += grossWithSC

          let vatEx = vatExemptSales + zeroRatedSales;
          this.totalVatableSales += (sbo.totals.net - vatEx);

          if (USE_SM_MARKETS_OR_FORMAT) {
            this.totalNetSalesWCharges = this.totalNetSales + this.totalVatAmount + this.totalServiceCharge + this.totalOtherCharges;
          } else {
            this.totalNetSalesWCharges = this.totalSales;
          }

          sbo.orders.filter(s => !s.isVoided).forEach(o => {
            let serviceName = this.serviceTypes.filter(st => st.id === o.serviceTypeId)[0].service_name;

            let serviceTypeIndex = this.serviceTypesTotal.findIndex((obj => obj.name == serviceName));
            if(serviceTypeIndex < 0) {
              this.serviceTypesTotal.push({ name: serviceName, amount: o.totals.net, count: 1 })
            } else {
              this.serviceTypesTotal[serviceTypeIndex].amount += o.totals.net;
              this.serviceTypesTotal[serviceTypeIndex].count++;
            }
          });

          // get non revenue orders
          let nonRev = sbo.orders.filter(o => o.product.product_group.product_category.is_non_revenue == 1);
          if(nonRev.length > 0) {
            this.nonRevenueOrders[sbo._id] = nonRev;
          }

          sbo.orders.filter(s => !s.isVoided).forEach(
            o => {
              let objIndex = null;
              if('discount' in o) {
                objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == o.discount.discount_name));
                this.totalPerDiscountItems[objIndex].amount += o.discount.discountAmount;
              } else if('comp' in o) {
                objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == o.comp.compName));
                this.totalPerDiscountItems[objIndex].amount += o.comp.compAmount;
              }

              // POSUAR-165
              if (USE_SM_MARKETS_OR_FORMAT && o.discount) {
                this.totalVatExempSales += o.discount.discountAmount;
              }
            }
          )

          sbo.orders.filter(s => s.isVoided && !s.isCancelled).forEach(
            o => {
              const totals = o.preVoidTotals ?? o.totals;
              this.totalItemVoidedSales += parseFloat(totals.total);
              let voidedGrossWoSc = totals.rawPrice;
              let voidedGrossWithSC = totals.rawPrice;

              if(sbo.totals.isScInclusive == 1){ // inclusive
                voidedGrossWoSc -= totals.serviceCharge;
              } else { // exclusive
                voidedGrossWithSC += totals.serviceCharge;
              }

              this.totalBeforeServiceCharge += voidedGrossWoSc;
              this.totalGrossWithServiceCharge += voidedGrossWithSC;
            }
          )

          if(sbo.billDiscount) {

            let discountAmount = 0;

            if(sbo.billDiscount.amount)
              discountAmount = sbo.billDiscount.amount;

            let objIndex = this.totalPerDiscountItems.findIndex((obj => obj.name == sbo.billDiscount.discount.discount_name));

            if (objIndex < 0) {
              this.totalPerDiscountItems.push({ name: sbo.billDiscount.discount.discount_name, amount: 0 });
              objIndex = this.totalPerDiscountItems.length - 1;
            }

            this.totalPerDiscountItems[objIndex].amount += discountAmount;

            // POSUAR-165
            if (USE_SM_MARKETS_OR_FORMAT && sbo.billDiscount) {
              this.totalVatExempSales += sbo.billDiscount.amount;
            }
          }

          this.totalVatAdjustment = this.totalPerDiscountItems.reduce((a, b) => {
            if (VAT_EXCL.includes(b.name)) {
              return a + b.amount;
            }
            return a;
          }, 0) * 0.6; // 12%/20% = 60%

          this.totalRegularVatAdjustment = this.totalPerDiscountItems.reduce((a, b) => {
            if (!VAT_EXCL.includes(b.name)) {
              return a + b.amount;
            }
            return a;
          }, 0) * 0.12;

          this.totalPwdScVatAdjustment = this.totalVatAdjustment;
        }
      )

      const filteredSettlements = orderBy(this.dayBilledOrders, 'receipt_num');

      if(filteredSettlements.length > 0) {
        const totalSettlements = filteredSettlements.length;
        this.beginningSettlement = filteredSettlements[0];
        this.endingSettlement = filteredSettlements[totalSettlements -1];
      } else {
        this.beginningSettlement.receipt_num = this.lastReceiptNum;
        this.endingSettlement.receipt_num = this.lastReceiptNum;
      }

      if(this.dayBilledOrders.length > 0) {
        const totalBilled = this.dayBilledOrders.length;
        this.begBilledOrder = this.dayBilledOrders[0];
        this.endBilledOrder = this.dayBilledOrders[totalBilled - 1];
      } else {
        this.begBilledOrder.bill_num = this.lastBillNum;
        this.endBilledOrder.bill_num = this.lastBillNum;
      }

      let voidOrders = this.dayVoidOrders.filter(s => s.void_bill_num != null);
      voidOrders.sort((a, b) => a.void_bill_num - b.void_bill_num);

      if(voidOrders.length > 0) {
        this.begVoidOrder = voidOrders[0];
        this.beginningVoidNum = this.begVoidOrder.void_bill_num;
        this.endingVoidNum = voidOrders[voidOrders.length - 1].void_bill_num;
      } else {
        this.beginningVoidNum = this.lastVoidBillNum;
        this.endingVoidNum = this.lastVoidBillNum;
      }

      this.dayVoidOrders.forEach(
        vo => {

          let grossWithoutSc = vo.totals.rawPrice;
          let grossWithSC = vo.totals.rawPrice;

          if(vo.totals.isScInclusive == 1){ // inclusive
            grossWithoutSc -= vo.totals.serviceCharge;
          } else { // exclusive
            grossWithSC += vo.totals.serviceCharge;
          }
          this.totalBeforeServiceCharge += grossWithoutSc;
          this.totalGrossWithServiceCharge += grossWithSC;
        }
      );
    },

    applyCashBreakdown(data) {

      this.cashBreakDown[data.denomination] = parseFloat(data.totalAmount);

    },

    // Check if has cash sale this day
    dayEndHasCashSale() {
      const allOrders = concat([], this.orders, this.originalOrders);
      return allOrders.some(order =>
        order
        && order.isSettled
        && !order.isVoided
        && order.originShiftTable?.pos_date == this.posDate
        && order.payments?.some(o => o.amount > 0 && o.type == 'cash')
      );
    },

    setIsCashInputted(num) {
        /**
         * check if has cash sale
         * Before save payment cash float must settle if don't have cash sale this day
         */
      // (this.useWholeAmountInput && num !== 0) the idea of this statement is, when cashier ticked the override, total cash value does not reset to zero
      // this additional basis align to applyPaymentBreakdown since in that function has the accurate total cash amount
      Logger.debug(`Override Cash Input: ${this.useWholeAmountInput}`)
      Logger.debug(`Total Cash: ${this.totalCash}`)
      Logger.debug(`Total Payment: ${this.totalPaymentAmount}`)
      Logger.debug(`Parameter pass: ${num}`)
      Logger.debug(`Transaction data this pos date: ${this.posDate} data: ${JSON.stringify(this.dayEndHasCashSale())}`)

      if (!isEmpty(this.dayEndHasCashSale())) {
        Logger.debug(`Transaction count of this pos date: ${this.posDate} count: ${this.dayEndHasCashSale()?.length}`)
        this.isCashInputted = ((this.useWholeAmountInput && num !== 0) ? this.totalCashFloat < this.totalCash : this.totalCashFloat < num);
      } else {
        Logger.debug(`No sale this pos date: ${this.posDate}`)
        this.isCashInputted = ((this.useWholeAmountInput && num !== 0) ? this.totalCashFloat <= this.totalCash : this.totalCashFloat <= num);
      }

    },

    applyPaymentBreakdown(data) {

      this.computedPaymentBreakdown[data.paymentType] = pick(data, ['paymentAmount', 'overShortAmount','paymentType']);
      this.computeTotalsInPaymentTypes();
      // to meet the requirements instead of data.paymentAmount change to total cash amount
      this.setIsCashInputted(this.totalCash);
    },

    generateDayEndData() {
      const samePosDateOrders = OFFLOAD.sqliteOffloadReceipt
        ? this.sqliteBilledOrders.filter(item => item.isSettled && !item.isVoided)
        : uniqBy([...this.orders, ...this.originalOrders], '_id').filter(o => moment(o.originShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter && !o.isVoided);

      let data = {};
      data.cash_float = this.accountId == '1' ? '0.00' : this.cashFloat[this.overrideTerminalId];
      data.cash_deposit = this.totalCashDeposit;
      data.cash_float_recorded = this.totalCashFloatRecorded;

      data.total_sales = 0;
      samePosDateOrders.forEach(o => data.total_sales += o.totals?.total ?? 0);

      data.total_payment = 0;
      data.cash_sales = 0;
      const tender_summary = {};
      let totalNonCash = 0;
      this.dayBilledOrders.filter(s => s.originTerminalId == this.overrideTerminalId && moment(s.orders[0].billedShiftTable?.pos_date).format("YYYY-MM-DD") === this.dateFilter).forEach(s => s.payments?.forEach(pm => {
        if (pm.method in tender_summary) {
          tender_summary[pm.method] += pm.amount;
        } else {
          tender_summary[pm.method] = pm.amount;
        }
        const pname = lowerCase(pm.method);
        if (pname == 'cash') {
          return;
        }

        data[`${pname}_expected`] = pm.amount;
        data[`${pname}_payment`] = this.computedPaymentBreakdown[pm.method]?.paymentAmount || 0;
        totalNonCash += this.computedPaymentBreakdown[pm.method]?.paymentAmount || 0;
      }));

      data.cash_sales = this.totalCashDeposit;
      data.total_payment = this.totalCashDeposit + totalNonCash;

      data.over_short = this.totalOverShortAmount;

      tender_summary['CASH'] -= Math.abs(data.over_short);

      const shift_data = {};
      shift_data.total_bills = `${this.orders.length}`;
      shift_data.average_check = `${data.total_sales / 2}`;
      shift_data.total_pax = `${sumBy(this.orders, 'pax')}`;
      shift_data.total_amount = `${data.total_sales}`;

      const is_reprint = 'false';
      const is_preview = 'false';
      const z_read_date = new Date().toISOString();

      return {
        day_end: data,
        terminal_id: ENABLE_SALES_CONSOLIDATOR ? this.terminalId : 1,
        locationId: this.locationId,
        userId: this.userId,
        is_reprint,
        is_preview,
        z_read_date,
        zReadDate: z_read_date,
        pos_date: moment(this.posDate).format("YYYY-MM-DD"),
        tender_summary,
        shift_data,
        use_sqlite_offload: OFFLOAD.sqliteOffloadReceipt,
      };
    },

    computeTotalsInPaymentTypes() {
      let totalAmount = 0; // this will need to restart the computation
      let overShort = 0;
      const breakdowns = Object.values(this.computedPaymentBreakdown);
      totalAmount += sumBy(breakdowns, b => parseFloat(b.paymentAmount));
      overShort += sumBy(breakdowns, b => parseFloat(b.overShortAmount));

      if(this.cashFloat[this.overrideTerminalId] > 0 && overShort === 0 && this.totalCash === 0) {
        overShort = (this.cashFloat[this.overrideTerminalId] + this.totalCashSales - this.totalCashWithdraw) * -1;
      }

      this.totalPaymentAmount = this.locationStatus == 'Inactive' ? 0.0 : totalAmount;
      this.totalOverShortAmount = this.locationStatus == 'Inactive' ? 0.0 : overShort;
    },

    filterVoidOrderPerDay() {
      this.dayVoidOrders = this.filterOrderPerDay
        .filter(order => order.isVoided && moment(order?.billedShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter)
        .map(o => ({ ...o, void_bill_num: o.void_bill_num || o.void_receipt_num }));
    },

    filterOrderBilledPerPosDate() {

      this.dayBilledOrders = this.filterOrderPerDay.filter(function(order) {
        if(order.isBilled && moment(order?.billedShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter)
          return true;
      },this);

    },

    filterSettledOrderPerDay() {
      const billedOrders = uniqBy([...this.orders, ...this.originalOrders], '_id').filter(o => (moment(o?.billedShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter || moment(o?.originShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter));

      this.settledOrderPerDay = billedOrders.filter(function(order) {
        if(order.isSettled && moment(order?.billedShiftTable?.pos_date).format("YYYY-MM-DD") == this.dateFilter)
          return true;
      },this);

    },

    generatePmixData() {
      if (OFFLOAD.sqliteOffloadReceipt) {
        return this.sqliteOffloadProductMix;
      }

      let data = {};
      this.settledOrderPerDay.forEach(so => {
        so.orders.filter(order => !order.isVoided).forEach(o => {
          let serviceType = so.serviceType;
          let groupName = "Group Name: " + o.product.product_group.group_name;
          let modifiersArr =  o.product.hasModifier == 1 ? o.product.forcedMods.concat(o.product.unforcedMods) : {};
          if(!data[serviceType]) {
            data[serviceType] = {};
          }

          if(!data[serviceType][groupName]) {
            data[serviceType][groupName] = {};
          }

          if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {

            if(!data[serviceType][groupName][o.product.product_name]) {
              data[serviceType][groupName][o.product.product_name] = []
            }
            data[serviceType][groupName][o.product.product_name]['qty'] ??= 0;
            data[serviceType][groupName][o.product.product_name]['orig_price'] ??= 0;
            data[serviceType][groupName][o.product.product_name]['total_price'] ??= 0;

            data[serviceType][groupName][o.product.product_name]['qty'] += o.quantity;
            if(o.isOpenItem) {
              data[serviceType][groupName][o.product.product_name]['orig_price'] = parseFloat(o.product.price ?? "0");
              data[serviceType][groupName][o.product.product_name]['total_price'] = (o.activePrice * data[serviceType][groupName][o.product.product_name]['qty']);
            } else {
              data[serviceType][groupName][o.product.product_name]['orig_price'] = parseFloat(o.product.pricings[0]?.prod_price ?? "0");
              data[serviceType][groupName][o.product.product_name]['total_price'] = data[serviceType][groupName][o.product.product_name]['orig_price'] * data[serviceType][groupName][o.product.product_name]['qty'];
            }

            //loop through modifiers array
            forEach(modifiersArr, ma => {
              let modGroupName = "Mod Group Name: " + ma.mod_group_name;
              if(!data[serviceType][modGroupName]) {
                data[serviceType][modGroupName] = {};
              }

              if(!data[serviceType][modGroupName][ma.modifier_name]) {
                data[serviceType][modGroupName][ma.modifier_name] = []
              }

              data[serviceType][modGroupName][ma.modifier_name]['qty'] ??= 0;
              data[serviceType][modGroupName][ma.modifier_name]['orig_price'] ??= 0;
              data[serviceType][modGroupName][ma.modifier_name]['total_price'] ??= 0;

              data[serviceType][modGroupName][ma.modifier_name]['qty'] += ma.quantity;
              data[serviceType][modGroupName][ma.modifier_name]['orig_price'] = parseFloat(ma.mod_price ?? "0");
              data[serviceType][modGroupName][ma.modifier_name]['total_price'] = data[serviceType][modGroupName][ma.modifier_name]['orig_price'] * data[serviceType][modGroupName][ma.modifier_name]['qty'];
            });
          } else {
            data[serviceType][groupName][o.product.product_name] ??= 0;
            data[serviceType][groupName][o.product.product_name] += o.quantity;
            //loop through modifiers array
            forEach(modifiersArr, ma => {
              let modGroupName = "Mod Group Name: " + ma.mod_group_name;
              if(!data[serviceType][modGroupName]) {
                data[serviceType][modGroupName] = {};
              }

              data[serviceType][modGroupName][ma.modifier_name] ??= 0;
              data[serviceType][modGroupName][ma.modifier_name] += ma.quantity;
            });
          }
        });
      });

      return data;
    },

    cancelShiftChange() {
      this.$router.push({ name: 'home' });
    },

    async clickShowSpotAudit() {
      const response = await this.$openApproverModal(PERMISSION_TYPES.SHOW_SPOT_AUDIT);
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Show Spot Audit cancelled');
          }

          return;
      }
      this.showSpotAudit = true;
    },

    async savePaymentTotals() {
      if (!(await checkCloudConnection())) {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to save the payment totals.');
          return;
      }

      const response = await this.$openApproverModal(PERMISSION_TYPES.DAY_END);
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Save Payments cancelled');
          }

          return;
      }

      const dayendData = this.generateDayEndData();
      try {
        await saveDayendData(dayendData);
        Swal.fire({
          title: 'Success',
          text: 'Payments saved!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        this.isPaymentTotalsSaved = true;
        await this.fetchCurrentPosDateData();
      } catch (e) {
        Swal.fire({
          title: 'Error',
          text: e.message,
          icon: 'error',
        });
      }
    },

    transactionsNotSyncedAlert(shouldRedirect = false) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        this.$router.push({ name: 'settings' });
        return;
      }
      Swal.fire({
        title: 'Transactions are not yet fully synced. Please wait for sync to finish for accurate reports.',
        icon: 'warning',
      }).then((result) => {
        if (result.isConfirmed && shouldRedirect) this.$router.push({ name: 'home' });
      });
    },

    async dayEnd() {
      if (window.enableOfflineMode) {
        this.$swal.warning('Please switch to online mode to proceed with DAYEND.');
        return;
      }

      if(!this.isQueueCompleted) return this.transactionsNotSyncedAlert();

      // EPOS-705: If there’s no Cash Sales AND no Beginning Cash Fund amount is 0, prevent Day End
      if (ENABLE_EPEI_REQUIRE_BEG_CASH_FUND && this.totalCash == 0 && this.cashFloat[this.overrideTerminalId] == 0) {
        return this.showRequireCashFundAlert();
      }

      let appBasedMultiterminal = false;

      if (window.MosaicPosAppSupportedFeatures?.multiterminal >= 2) {
        const mub = new MultiterminalUtilityBridge();
        const status = await mub.getStatus();

        appBasedMultiterminal = status.mode == 'primary';
      }

      if (appBasedMultiterminal) {
        await new PrimaryBroadcastUtilityBridge().forceSatelliteLogout();
      } else {
        const terminal = JSON.parse(sessionStorage.getItem('terminal'))
        if (await checkCloudConnection() && terminal?.is_shared_terminal && !ENABLE_SALES_CONSOLIDATOR) {
          const {data: {data}} = await getTerminals()
          let ids = data.filter(({is_active}) => is_active).map(j => j.terminal_id).join(', ');
          if (ids) {
            await this.$swal.warning(`Please logout in terminal ${ids}`);
            return
          }
        }
      }

      const response = await this.$openApproverModal(PERMISSION_TYPES.DAY_END);
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Day End cancelled');
          }

          return;
      }

      let eodButton = '';
      let width = '33em';
      let zPreview = 'PREVIEW';
      let zStyle = ''
      let zMargin = ''
      if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
          eodButton += '<button type="button" class="swal2-deny swal2-styled swal2-default-outline mr-lg-5 eod-preview-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);"><div id="eod-preview-btn">PREVIEW EOD</div></button>'
          width = '47em';
          zPreview = '<div id="de-preview-btn">PREVIEW ZREAD</div>'
          zStyle = 'de-preview-btn1'
          zMargin = 'margin-right: 10px !important;'
      }

      Swal.fire({
            title: 'Do you want to proceed with DAY END?',
            width,
            text: "Clicking Yes will proceed to printing of Z-reading and app logout.",
            iconHtml: '<div class="de-icon"><i class="fas fa-question"></i></div>',
            closeButtonHtml: '<i class="fas fa-times-circle"></i>',
            showCloseButton: true,
            showConfirmButton: false,
            footer: '<div class="swal2-actions void-swal-action d-flex flex-wrap flex-lg-nowrap" style="display: flex;">' +
                '<div class="swal2-loader"></div>' +
                '<button type="button" class="swal2-deny swal2-styled swal2-default-outline se-preview-btn '+zStyle+'" aria-label="" style="display: inline-block;  background-color: rgb(27, 117, 187); '+zMargin+'" @click="clickPreview">'+zPreview+'</button>' +
                eodButton +
                '<button type="button" class="swal2-cancel swal2-styled swal2-default-outline se-cancel-btn" aria-label="" style="display: inline-block; background-color: rgb(244, 112, 112);">NO</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline se-yes-no-print-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);">YES</button>' +
                '<button type="button" class="swal2-confirm swal2-styled swal2-default-outline se-yes-with-print-btn" aria-label="" style="display: inline-block; background-color: rgb(27, 117, 187);"><div id="se-yes-and-print-span">YES & PRINT Z-READ</div></button>' +
                '</div>',
            didOpen: () => {
              const btnPreview = document.querySelector('.se-preview-btn')
              const btnPreviewEod = document.querySelector('.eod-preview-btn')
              const btnCancel = document.querySelector('.se-cancel-btn')
              const btnYesNoPrint = document.querySelector('.se-yes-no-print-btn')
              const btnYesWithPrint = document.querySelector('.se-yes-with-print-btn')

              btnPreview.addEventListener('click', () => {
                  Swal.close()
                  this.clickPreview();
              }, Swal)

              if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
                btnPreviewEod.addEventListener('click', () => {
                  Swal.close()
                  this.clickPreviewEod();
                })
              }

              btnCancel.addEventListener('click', () => {
                Swal.close()
              })

              btnYesNoPrint.addEventListener('click', () => {
                this.clickYesNoPrint();
              })

              btnYesWithPrint.addEventListener('click', () => {
                this.clickYesWithPrint();
              })

            },
            customClass: {
                icon: 'de-swal-icon',
                actions: 'void-swal-action',
                popup: 'de-popup',
                footer: 'de-footer',
                htmlContainer: 'de-container',
                closeButton: 'de-close'
            }
        })
    },

    clickPreview() {
      this.receiptDateString = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if(this.$can(this.PERMISSIONS.OLD_ZREAD_FORMAT)) {
        this.isXreadPreviewModalOpen = true;
      } else {
        this.isNewXreadPreviewModalOpen = true;
      }
    },

    clickPreviewEod() {
      this.receiptDateString = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.isEodPreviewModalOpen = true;
    },

    clickYesNoPrint() {
      this.shiftEnd();

    },

    clickYesWithPrint() {
      this.shiftEnd(true);
    },

    async shiftEnd(print = false) {
      window.__showLoader();

      if (!(await checkCloudConnection())) {
        this.$swal.warning('No internet Connection, Kindly connect to the internet to proceed with DAYEND.');
        window.__hideLoader();
        return;
      }

      if (this.$can(this.PERMISSIONS.EMPLOYEE_CLOCK_IN)) {
          const attendances = await dbService.fetchItems(DEXIE_TABLES.ATTENDANCES, [['clock_out_time', '=', null]]) || [];
          if (attendances.length > 0) {
              window.__hideLoader();
              this.$swal.info(`All clocked-in users will be automatically clocked out.`)
                  .then(async () => {
                    window.__showLoader();
                    await this.forcedClockedOutUser(attendances)
                    await this.printTimeClockLogs()
                    await this.shiftEnd(print)
                  });
              return;
          }
      }

      // POS2-1633 disable the validation of transactions for now
      // if(!this.hasZread) {
      //   Swal.fire({
      //     title: 'Error',
      //     text: 'The selected POS date\'s zread is not available offline. Please try again in online mode.',
      //     icon: 'error',
      //   });

      //   return;
      // }

      try {
        // Create an instance of PosDateBridge to interact with POS date operations
        const posDateBridge = new PosDateBridge();

        // Retrieve the current POS date before clearing sessionStorage
        const activePosDate = await posDateBridge.getPosDate(this.locationId);

        // Increment the current date by 1 day and format it
        const incrementedDate = moment(activePosDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');

        // Update the app's POS date with the incremented date
        await posDateBridge.updateAppPosDate({
          location_id: this.locationId,
          pos_date: incrementedDate
        });

        if (OFFLOAD.sqliteOffloadShiftTable) {
          const stb = new ShiftTableBridge();
          await stb.resetShift(this.locationId);
        }
      } catch (e) {
        if (isNetworkStable()) {
          const payload = {
            message: e.message,
            name: 'DayEndSettings > ' + e.name,
            stack: e.stack,
            url: window.location.href,
          }
          await storeToS3(payload, "SQLite-Errors", window.locationId);
        }
      }

      if (!OFFLOAD.sqliteOffloadReceipt) {
        await this.forceSyncOrders(this.posDate);
      }

      const dayendData = this.generateDayEndData();

      if(print) {
        await this.printZRead();
        await this.printProductMix(false);
      }

      try {
        await recordDayend(dayendData);
        this.addDayendRecord(dayendData); //store in state

        const day_transaction_count = this.currentPosDateDataFromDb?.total_transaction_count ?? 0;
        const day_void_count = this.currentPosDateDataFromDb?.total_voided_count ?? 0;
        const all_count = day_transaction_count + day_void_count;
        if (all_count == 0) {
          await this.saveAuditLog("day-end no transaction")
        }

        if (ENABLE_ZREAD_TEXT_FILE_GENERATION) {
          const result = await generateTxtFile(this.locationId, moment(this.posDate).format('YYYY-MM-DD'));
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `${LOCATION_ACCREDITATION_TYPE}.zip`;
          link.click();
        }
      } catch (e) {
        Swal.fire({
          title: 'Error',
          text: e.message,
          icon: 'error',
        });
        window.__hideLoader();
        return;
      }

      if(ENABLE_DAYEND_FTP) {
        try {
          const response = await generateAndSendFile(this.locationId, this.posDate);
          window.__hideLoader();

          if(!response.data) {
            throw new Error('FTP Sending Failed');
          }

          if(IS_ROBINSON_ACCREDITED) {
            this.$swal.info('Sales file successfully sent to RLC server');
          } else {
            this.$swal.info('Sales file successfully sent.');
          }
        } catch (err) {
          if(IS_ROBINSON_ACCREDITED) {
            this.$swal.warning('Sales file is not sent to RLC server. Please contact your POS vendor.');
          } else {
            this.$swal.warning('Sales file is not sent. Please contact your POS vendor.');
          }
        }

        window.__hideLoader();
        this.disableButtons();
        await this.delay(5000);
      }

      //print eod
      if (this.$can(this.PERMISSIONS.EOD_REPORT)) {
          await this.printEOD();
      }

      if (ENABLE_IKONOMIKAL_INTEGRATION) {
        this.$swal.info('Please wait while we sync your data to Ikonomikal.');
        await submitTransactionsToIkonomikal(this.locationId, this.posDate);
      }

      try {
        // Create an instance of PosDateBridge to interact with POS date operations
        const posDateBridge = new PosDateBridge();

        // Retrieve the current POS date before clearing sessionStorage
        const activePosDate = await posDateBridge.getPosDate(this.locationId);

        await runDbfDownloadFlow(activePosDate);
      } catch (err) {
        console.error('DBF download failed:', err);
      }

      try {
          if (ENABLE_SQLITE_AUTO_BACK_UP_ON_DAY_END) {
            const backupBridge = new BackupUtilityBridge();
            backupBridge.uploadDatabase();
          }

          this.$store.commit('user/resetState');
          this.$store.commit('global/resetState');
          this.$store.commit('modals/resetState');
          this.$store.commit('settings/resetState');
          this.$store.commit('resetState');
          sessionStorage.clear();
          localStorage.clear();
          await clearStorage();
          await clearAxiosCache();
          const dbs = await indexedDB.databases();
          dbs.forEach(item => indexedDB.deleteDatabase(item.name));
          await logout();

          window.__hideLoader();
          window.location.href = '/'
        } catch (error) {
          console.error(error);
          window.__hideLoader();
      }
    },

    disableButtons() {
      const btnPreview = document.querySelector('.se-preview-btn')
      const btnPreviewEod = document.querySelector('.eod-preview-btn')
      const btnCancel = document.querySelector('.se-cancel-btn')
      const btnYesNoPrint = document.querySelector('.se-yes-no-print-btn')
      const btnYesWithPrint = document.querySelector('.se-yes-with-print-btn')

      if(btnPreview) btnPreview.disabled = true;
      if(btnPreviewEod) btnPreviewEod.disabled = true;
      if(btnCancel) btnCancel.disabled = true;
      if(btnYesNoPrint) btnYesNoPrint.disabled = true;
      if(btnYesWithPrint) btnYesWithPrint.disabled = true;
    },

    async forcedClockedOutUser(attendances) {
        try {
            const results = await Promise.all(attendances.map(attendance => {
                attendance.clock_out_time = moment().format('YYYY-MM-DD HH:mm:ss');
                dbService.saveIdbItem(DEXIE_TABLES.ATTENDANCES, attendance)
                updateOrCreateAttendance(attendance)
            }));
            return results.every(result => result);
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async printTimeClockLogs() {
        const attendances = await dbService.fetchItems(DEXIE_TABLES.ATTENDANCES);
        const cashiers = await dbService.fetchItems(DEXIE_TABLES.USERS);

        const transformedData = {};

        attendances.forEach(item => {
            const { user_id } = item;

            if (!transformedData[user_id]) {
                transformedData[user_id] = [];
            }

            transformedData[user_id].push(item);
        });

        let formattedText = `TIME CLOCK LOG\n\n`;
        for (const key in transformedData) {
            transformedData[key].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            const cashier = cashiers.find(cashier => cashier.id == key) || {};

            formattedText += `${cashier?.first_name || ''} ${cashier?.last_name || ''}\n`;
            formattedText += `CLOCK IN             CLOCK OUT\n` +
                transformedData[key].map(item => {
                    return `${item.clock_in_time?.padEnd(8)}  ${item.clock_out_time?.padEnd(8) || ''}`;
                }).join('\n');
            formattedText += '\n\n';
        }

        const printData = {
          print_type: 'PlainText',
          html: formattedText
        };

        print(printData, {});
    },

    async clearCurrentDayData() {
        await cleanupOlderOrders();
        await cleanupOlderSettlements();
        this.clearOrders();
        this.clearSettlements();
    },

    getRevenueBreakdown(categories) {
      return Object.keys(categories).map(name => ({
        name,
        qty: categories[`${name.toLowerCase()}_qty`],
        amount: categories[name],
        daywise: categories[name],
      })).filter(item => item.qty !== undefined);
    },

    async generateRevenueBreakdownPerDay() {
      if (GET_BREAKDOWNS_FROM_DB) {
        this.revenueBreakdown = this.getRevenueBreakdown(this.currentPosDateDataFromDb.categories);
        this.revenueBreakdownPerShift = this.revenueBreakdown;
        this.revenueBreakdownPerDay = this.revenueBreakdown;
        this.totalDaywise = Object.values(this.currentPosDateDataFromDb.categories).reduce((a, b) => a + b, 0);
        this.totalShiftwise = this.totalDaywise;
        return;
      }

      let revenueBD = [];
      let totalRevenuePerDay = 0;
      this.dayBilledOrders.filter(s => s.isSettled == true && !s.isVoided).forEach(
        sbo => sbo.orders.filter(s => !s.isVoided).forEach(
          o => {
            let totalNet = o.totals.net;

            if(sbo.billDiscount) {
              if(sbo.billDiscount.discount.discount_rate)
                totalNet -= ((totalNet * sbo.billDiscount.discount.discount_rate) / sbo.pax) * sbo.billDiscount.discountQuantity;
              else if(sbo.billDiscount.discount.discount_amount)
                totalNet -= sbo.billDiscount.discount.discount_amount / sbo.orders.filter(s => !s.isVoided).length;
            }

            let revenueObj = {
              name : o.product.category,
              amount : totalNet,
            }
            revenueBD.push(revenueObj);
          }
        )
      )
      var result = [];
      revenueBD.reduce(function(res, value) {
        if (!res[value.name]) {
          res[value.name] = { name: value.name, daywise: value.amount};
          result.push(res[value.name])
        } else {

          res[value.name].daywise += value.amount;

        }
        totalRevenuePerDay += value.amount;
        return res;
      }, {});

      this.revenueBreakdown = result;
      this.totalShiftwise = totalRevenuePerDay;

    },

    padWithZero(num) {

      return String(num || 0).padStart(20, '0');

    },

    createValueLabelRow(label, value, valueFormatter = v => this.$filters.formatPrice(v)) {
      return `<tr><td valign="top">${label}</td><td valign="top">${valueFormatter(value)}</td></tr>`;
    },

    generateDiscountString(discounts) {
      return discounts.map(discount => {
        return this.createValueLabelRow(discount.name, discount.amount);
      }).join("\n");
    },

    generateServiceTypeString(serviceTypes) {
      return serviceTypes.map(serviceType => {
        return `
          <tr><td valign="top">${serviceType.name}</td><td valign="top">${this.$filters.formatPrice(serviceType.amount)}</td></tr>
          <tr><td valign="top">Count:</td><td valign="top">${serviceType.count}</td></tr>
        `;
      }).join("\n");
    },

    generateIncomeHeadSalesString(revenueBreakdown) {
      return revenueBreakdown.map(incomeHead => {
        return this.createValueLabelRow(incomeHead.name, incomeHead.daywise);
      }).join("\n");
    },

    generatePaymentMethodString(paymentMethods) {
      return paymentMethods.map(paymentMethod => {
        return this.createValueLabelRow(paymentMethod.name, paymentMethod.amount);
      }).join("\n");
    },

    generateProductMixString(mixData, grandTotalPmix) {
      let productMixSummary = Object.keys(mixData).length > 0 ? `
          <tr>
            <td colspan="2">
              <br>
            </td>
          </tr>
          <tr>
            <td colspan="2">
                PRODUCT MIX SUMMARY
            </td>
          </tr>
      ` : '';

      for (let serviceType in mixData) {
          productMixSummary += `
              <tr>
                    <td valign="top" colspan="2" class="pt-4">Service Type: ${serviceType}</td>
              </tr>
          `;
          for (let groupName in mixData[serviceType]) {
              productMixSummary += `
                  <tr>
                        <td valign="top" colspan="2" class="pt-2">${groupName}</td>
                  </tr>`;
                  if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                      productMixSummary += `
                      <tr>
                          <td valign="top" colspan="2">Description</td>
                      </tr>
                      `;
                  } else {
                      productMixSummary += `
                      <tr>
                          <td valign="top">Description</td>
                          <td valign="top">Qty</td>
                      </tr>
                      `;
                  }
              for (let productName in mixData[serviceType][groupName]) {
                if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                    productMixSummary += `
                        <tr>
                            <td colspan="2" valign="top">${productName}</td>
                        </tr>
                        <tr>
                            <td colspan="2" valign="top">${String(mixData[serviceType][groupName][productName].qty).padStart(25, ' ')} ${String(mixData[serviceType][groupName][productName].orig_price.toFixed(2)).padStart(15, ' ')} ${String(mixData[serviceType][groupName][productName].total_price.toFixed(2)).padStart(15, ' ')}</td>
                        </tr>
                    `;
                } else {
                    productMixSummary += `
                    <tr>
                        <td valign="top">${productName}</td>
                        <td valign="top">${mixData[serviceType][groupName][productName]}</td>
                    </tr>
                    `;
                }
              }
              if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                  productMixSummary += `
                  <tr>
                      <td valign="top" class="pt-3" colspan="2">Total Quantity: ${Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b.qty, 0) }</td>
                  </tr>
                  <tr>
                      <td valign="top" class="pt-3" colspan="2">Total Amount: ${this.$filters.formatPrice(Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b.total_price, 0))}</td>
                  </tr>
                  `;
              } else {
                productMixSummary += `
                    <tr>
                        <td valign="top" class="pt-3">Total Quantity:</td>
                        <td valign="top" class="pt-1">${Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b, 0) }</td>
                    </tr>
                `;
              }

              productMixSummary += `
                <tr>
                  <td valign="top" colspan="2">
                        -------------------------------
                  </td>
                </tr>`;
          }
          productMixSummary += '<tr><td colspan="2"></td></tr>';
      }

      let totalQty = grandTotalPmix?.grand_total_qty;
      let totalPrice = grandTotalPmix?.grand_total_amount;

      if (OFFLOAD.sqliteOffloadReceipt) {
        const total = calculatePmixSummary(mixData);
        totalQty = total.totalQty;
        totalPrice = total.totalPrice;
      }

      productMixSummary += `
          <tr>
              <td valign="top" class="pt-3">Grand Total Qty:</td>
              <td valign="top" class="pt-1">${ totalQty }</td>
          </tr>`;
      if (USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
          productMixSummary += `
              <tr>
                  <td valign="top" class="pt-3">Grand Total Amt:</td>
                  <td valign="top" class="pt-1">${ this.$filters.formatPrice(totalPrice) }</td>
              </tr>
          `;
      }

      return productMixSummary;
    },

    generateVatAdjustmentString(ttlVatAdj, ttlRegularVatAdj, ttlPwdScVatAdj) {

      let vatAdjustmentStr = '';

      if(LOCATION_ACCREDITATION_TYPE_ID == 1) {
        vatAdjustmentStr += `
                <tr>
                    <td valign="top">REGULAR VAT ADJUSTMENT </td>
                    <td valign="top">${this.$filters.formatPrice(ttlRegularVatAdj ?? 0)}</td>
                </tr>
                <tr>
                    <td valign="top">PWD & SC VAT ADJUSTMENT </td>
                    <td valign="top">${this.$filters.formatPrice(ttlPwdScVatAdj ?? 0)}</td>
                </tr>
                `;
        } else {
          vatAdjustmentStr += `
                    <tr>
                        <td valign="top">VAT ADJUSTMENT </td>
                        <td valign="top">${this.$filters.formatPrice(ttlVatAdj ?? 0)}</td>
                    </tr>
                  `;
        }

      return vatAdjustmentStr;
    },

    getOldGrandTotalString(value) {
      return this.createValueLabelRow('OLD GRAND TOTAL ', value);
    },

    toValueLabelMap(obj, valueKey = 'amount', labelKey = 'name', valueGetter = (obj, key) => obj[key]) {
      return Object.keys(obj).map(key => {
        return {
          [labelKey]: key,
          [valueKey]: valueGetter(obj, key),
        }
      });
    },

    mapServiceType(serviceTypes) {
      return Object.keys(serviceTypes)
          .map(name => ({
            name,
            amount: serviceTypes[name].value,
            count: serviceTypes[name].count,
          }));
    },

    async generateReprintData(isReprint = false, ignoreCache = false) {
      const dayendData = this.dayEndRecord.find(d => d.pos_date == this.dateFilter);

      if (USE_RECEIPT_BASED) {
        const { data } = await getReprintDataReceiptBased({
          zReadDate: isReprint ? this.dateFilter : this.posDate,
          posDate: isReprint ? this.dateFilter : this.posDate,
          locationId: this.locationId,
          terminalId: this.overrideTerminalId,
          userId: this.userId,
          dayEndData: {
            ...(dayendData?.day_end ?? {
              cash_float: this.cashFloat[this.overrideTerminalId],
              cash_sales: this.totalCashSales + this.totalCashWithdraw,
              over_short: this.totalOverShortAmount,
            }),
          },
          shiftData: dayendData?.shift_data || {},
          ignoreCache,
          useSqliteOffload: OFFLOAD.sqliteOffloadReceipt
        });
        return data;
      } else {
        const { data } = await getReprintData(this.dateFilter);
        return data;
      }
    },

    async generateProductMixData(selectedPosDate) {
      if (USE_RECEIPT_BASED) {
        const { data } = await getProductMixDataReceiptBased({
          zReadDate: selectedPosDate,
          posDate: selectedPosDate,
          locationId: this.locationId,
          terminalId: this.overrideTerminalId,
          userId: this.userId,
        });
        return data;
      } else {
        const { data } = await getProductMixData(selectedPosDate);
        return data;
      }
    },

    async printZRead(isReprint = false, paused = true) {
      if (isReprint && paused) {
        const response = await this.$openApproverModal(PERMISSION_TYPES.REPRINT_Z_READ);
        if (response.success) {
            await this.printZRead(isReprint, false)
        }
        return;
      }

      const incomeHeadSalesStr = () => {
        return this.revenueBreakdown.map(incomeHead => `
          <tr>
            <td valign="top">${incomeHead.name}</td>
            <td valign="top">${incomeHead.qty}</td>
            <td valign="top">${this.$filters.formatPrice(incomeHead.daywise)}</td>
          </tr>`
        ).join("\n");
      };

      //set filterDate to target date
      this.dateFilter = moment(this.targetPosDate).format("YYYY-MM-DD");
      const dayendData = this.dayEndRecord.find(d => d.pos_date == this.dateFilter);

      //get separate sections
      let separateSections = JSON.parse(this.recieptDetails?.print_zread_sections ?? '[]');

      let overShortString = !separateSections.includes('over_short') ? this.getSectionHtml('over_short') : '';

      if(isReprint || GET_BREAKDOWNS_FROM_DB) {
        if (await checkCloudConnection()) {
          let fromOnline
          try {
            fromOnline = await this.generateReprintData(isReprint, true);
            this.revenueBreakdown = this.getRevenueBreakdown(fromOnline.categories);
          } catch {
            Swal.fire({
              title: 'Error',
              text: 'The selected POS date has no reprint data.',
              icon: 'error',
            });

            return;
          }

          const cashDrawerInfo = {
              ___TOTAL_CASH_VALUE___: this.$filters.formatPrice(sumBy(Object.values(fromOnline.cash_drawers), 'ttl_cash_drawer')),
              ___TOTAL_CASH_FLOAT___: this.$filters.formatPrice(sumBy(Object.values(fromOnline.cash_drawers), 'cash_float')),
              ___TOTAL_CASH_SALES___: this.$filters.formatPrice(fromOnline.tender_summary.CASH),
              ___OVER_SHORT___: overShortString.replace('___TOTAL_OVER_SHORT___', this.$filters.formatPrice(sumBy(Object.values(fromOnline.cash_drawers), 'over_short'))),
            };

          let cashFundSection = '';
          if (this.cashFunds && this.hasCashFundPermission) {
            cashFundSection = `
                <tr>
                    <td colspan="2"> ------------------------------- </td>
                </tr>
                <tr>
                    <td valign="top" colspan="2" class="text-left">Cash Funds</td>
                </tr>`;
            cashFundSection += this.createValueLabelRowWithHeader(this.cashFunds);
            cashFundSection += `
                <tr>
                    <td colspan="2"> ------------------------------- </td>
                </tr>`;
          }

          const receiptPrintedDate = USE_SM_MARKETS_OR_FORMAT && fromOnline?.created_at
            ? fromOnline?.created_at
            : new Date();
            
          print(this.generatePrintData({
            ___REPORT_TYPE___ : this.reportTypeTitle,
            __REPRINT__: isReprint && !USE_SM_MARKETS_OR_FORMAT ? "REPRINT" : "",
            ___ACCOUNT_NAME___: this.recieptDetails?.account_name ?? '',
            ___LOCATION___: this.recieptDetails?.location ?? '',
            ___LOCATION_ADDRESS___: this.recieptDetails?.location_address ?? '',
            ___PREFIX_VAT_REG___: fromOnline.is_non_vat ? 'Non-' : '',
            ___VAT_REG_TIN___: this.recieptDetails?.location_vat_reg ?? '',
            ___MIN___: this.recieptDetails?.lp_min ?? '',
            ___SERIAL_NO___: this.recieptDetails?.lp_serial ?? '',
            ___BEGINNING_BALANCE___: this.$filters.formatPrice(fromOnline.beginning_bal),
            ___ENDING_BALANCE___: this.$filters.formatPrice(fromOnline.ending_bal),
            ___BEGINNING_OR___: this.padWithZero(fromOnline.beg_invoice),
            ___ENDING_OR___: this.padWithZero(fromOnline.end_invoice),
            ___BEGINNING_BILL___: this.padWithZero(fromOnline.first_bill),
            ___ENDING_BILL___: this.padWithZero(fromOnline.ending_bill),
            ___BEGINNING_VOID___: this.padWithZero(fromOnline.beg_void),
            ___ENDING_VOID___: this.padWithZero(fromOnline.end_void),
            ___BEGINNING_VOID_AMOUNT___: this.$filters.formatPrice(fromOnline.beg_void_amt),
            ___ENDING_VOID_AMOUNT___: this.$filters.formatPrice(fromOnline.ttl_void_amount ?? fromOnline.beg_void_amt),
            ___GROSS_SECTION___: this.grossSection
              .replace('___GROSS_W_SERVICE_CHARGE___', this.$filters.formatPrice(fromOnline.gross_sales_w_svc))
              .replace('___GROSS_W_SERVICE_CHARGE_WO_VOID___', this.$filters.formatPrice(fromOnline.gross_sales_w_svc_wo_void_sales))
              .replace('___GROSS_WO_SERVICE_CHARGE_WO_VOID_SECTION___', fromOnline.paid_svc_charge != 0 ? `<tr>
                  <td valign="top">Gross w/o Service Charge</td>
                  <td valign="top">${this.$filters.formatPrice(fromOnline.gross_wo_svc_charge_and_void_sales)}</td>
              </tr>` : ''),
            ___SERVICE_CHARGE_SECTION___: fromOnline.paid_svc_charge != 0 ? this.serviceChargeSection
              .replace('___SERVICE_CHARGE___', this.$filters.formatPrice(fromOnline.paid_svc_charge)) : '',
            ___OTHER_CHARGES___: this.createValueLabelRow('Other Charges ', fromOnline.ttl_other_charges),
            ___TOTAL_DISCOUNTS__: this.$filters.formatPrice(fromOnline.ttl_discount_amount),
            ___VOIDED_SALES___: this.$filters.formatPrice(fromOnline.void_balance),
            ___VOIDED_BILLED___: this.$filters.formatPrice(fromOnline.void_billed_balance),
            ___VATABLE_SALES___: this.$filters.formatPrice(fromOnline.ttl_vatable_sales),
            ___VAT_AMOUNT___: this.$filters.formatPrice(fromOnline.ttl_vat_amount),
            ___VAT_EXEMPT_SALES___: this.$filters.formatPrice(fromOnline.vats['Vat Exempt Sales']),
            ___ZERO_RATED_SALES___: this.$filters.formatPrice(fromOnline.vats['Zero Rated Sales']),
            ___NET_SALES___: this.$filters.formatPrice(fromOnline.ttl_net_sales),
            ___CASH_FLOATS___: cashFundSection,
            ___CASH_SALES_SECTION___: this.cashSalesSection
              .replace('___TOTAL_CASH_SALES___', this.$filters.formatPrice(fromOnline.tender_summary.CASH))
              .replace('___TOTAL_CASH_SALES_EXPECTED___', this.$filters.formatPrice(fromOnline.tender_summary.CASH))
              .replace('___TOTAL_CASH_SALES_DECLARED___', this.$filters.formatPrice(sumBy(Object.values(fromOnline.cash_drawers), c => c.ttl_cash_drawer - c.cash_float))),
            ...cashDrawerInfo,
            ___LAST_KOT___: this.locationStatus == 'Inactive' ? 0 : await seriesService.getLastKotNum(),
            ___LAST_BILL_NUM___: this.locationStatus == 'Inactive' ? 0 : (fromOnline.ending_bill ?? 0),
            ___TRANSACTION_COUNT___: fromOnline.trans_count,
            ___TOTAL_PAX___: fromOnline.ttl_guest_count,
            ___AVERAGE_PER_TRANSACTION___: this.$filters.formatPrice(fromOnline.avg_per_trans),
            ___GRAND_TOTAL___: this.$filters.formatPrice(fromOnline.ending_bal),
            ___POS_DATE___: isReprint ? moment(this.dateFilter).format("YYYY-MM-DD") : moment(this.posDate).format("YYYY-MM-DD"),
            ___SHIFT_NO___: fromOnline.shift_number?? this.shiftTable.shift,
            ___TERMINAL_ID___: fromOnline.location_pos.terminal_id,
            ___GENERATED_BY___: this.generatedBy,
            ___SUB_TOTAL_TITLE___: fromOnline.paid_svc_charge != 0 ? 'Net Sales w/ VAT & SC' : 'Net Sales w/ VAT',
            ___SUB_TOTAL___: this.$filters.formatPrice(fromOnline.ttl_net_sales_w_charges),
            ___DISCOUNT_ITEMS___ : this.generateDiscountString(this.toValueLabelMap(fromOnline.discounts)),
            ___SERVICE_TYPES___  : this.generateServiceTypeString(this.mapServiceType(fromOnline.service_types)),
            ___HOURLY_SALES___  : this.generateHourlySalesString(fromOnline.hourly_sales, this.locationId),
            ___INCOME_HEAD_SALES___ : incomeHeadSalesStr(),
            ___PAYMENT_METHODS___ : this.generatePaymentMethodString(this.toValueLabelMap(fromOnline.tender_summary)),
            ___PRODUCT_MIX_SUMMARY___: this.$can(this.PERMISSIONS.OLD_ZREAD_FORMAT) && this.$can(this.PERMISSIONS.PRINT_PMIX) && !separateSections.includes('pmix') && this.locationStatus != 'Inactive' ? this.generateProductMixString(fromOnline.product_mix_summary, fromOnline.product_mix_grand_total) : '',
            ___REPORT_TIMESTAMP_SECTION___: this.reportTimestampSection
              .replace('___RECIEPT_PRINTED_DATE___', moment(receiptPrintedDate).format("YYYY-MM-DD HH:mm:ss"))
              .replace('___RECIEPT_PRINTED_DATE_ONLY___', moment(receiptPrintedDate).format("YYYY-MM-DD"))
              .replace('___RECIEPT_PRINTED_TIME_ONLY___', moment(receiptPrintedDate).format("HH:mm:ss")),
            ___Z_COUNTER___: this.createValueLabelRow('Z-COUNTER ', fromOnline.z_counter, v => `${v}`),
            ___OLD_GRAND_TOTAL___: this.getOldGrandTotalString(fromOnline.beginning_bal),
            ___VAT_ADJUSTMENT___ : this.generateVatAdjustmentString(fromOnline.vat_adjustment, fromOnline.regular_vat_adjustment, fromOnline.pwd_sc_vat_adjustment),
          }), {});

          return;
        } else if (dayendData) {
          await this.generateData();
        } else {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to reprint previous zreads.');
          return;
        }
      }

      let discountItemsStr = this.generateDiscountString(this.totalPerDiscountItems);
      let serviceTypeStr = this.generateServiceTypeString(this.serviceTypesTotal);
      let paymentMethodsStr = this.generatePaymentMethodString(this.paymentMethods);
      let productMixSummary = this.generateProductMixString(this.generatePmixData(), this.generatePmixGrandTotal);
      let vatAdjustmentStrings = this.generateVatAdjustmentString(this.totalVatAdjustment, this.totalRegularVatAdjustment, this.totalPwdScVatAdjustment);

      let cashFloat = isReprint ? dayendData.day_end.cash_float : this.cashFloat[this.overrideTerminalId];
      let cashSales = isReprint ? dayendData.day_end.cash_sales : this.totalCashSales + this.totalCashWithdraw;
      let overShort = isReprint ? dayendData.day_end.over_short : this.totalOverShortAmount;
      let totalCashValue = isReprint ? dayendData.day_end.cash_deposit + dayendData.day_end.cash_float: this.totalCash + this.totalCashWithdraw;

      let cashFundSection = '';
      if (this.cashFunds) {
        cashFundSection = this.createValueLabelRowWithHeader(this.cashFunds);
      }

      const payload = {
        ___REPORT_TYPE___ : this.reportTypeTitle,
        __REPRINT__: isReprint && !USE_SM_MARKETS_OR_FORMAT ? "REPRINT" : "",
        ___ACCOUNT_NAME___: this.recieptDetails?.account_name ?? '',
        ___LOCATION___: this.recieptDetails?.location ?? '',
        ___LOCATION_ADDRESS___: this.recieptDetails?.location_address ?? '',
        ___PREFIX_VAT_REG___: IS_NON_VAT ? 'Non-' : '',
        ___VAT_REG_TIN___: this.recieptDetails?.location_vat_reg ?? '',
        ___MIN___: this.recieptDetails?.lp_min ?? '',
        ___SERIAL_NO___: this.recieptDetails?.lp_serial ?? '',
        ___BEGINNING_BALANCE___: this.$filters.formatPrice(this.beginningBalance),
        ___ENDING_BALANCE___: this.$filters.formatPrice(this.endingBalance),
        ___BEGINNING_OR___: this.padWithZero(this.previousReceiptNum),
        ___ENDING_OR___: this.padWithZero(this.lastReceiptNum),
        ___BEGINNING_BILL___: this.padWithZero(this.begBilledOrder.bill_num),
        ___ENDING_BILL___: this.padWithZero(this.endBilledOrder.bill_num),
        ___BEGINNING_VOID___: this.padWithZero(this.previousVoidNum),
        ___ENDING_VOID___: this.padWithZero(this.endingVoidNum),
        ___BEGINNING_VOID_AMOUNT___: this.$filters.formatPrice(this.beginningVoidAmount),
        ___ENDING_VOID_AMOUNT___: this.$filters.formatPrice(this.endingVoidAmount),
        ___GROSS_SECTION___: this.grossSection
          .replace('___GROSS_W_SERVICE_CHARGE___', this.$filters.formatPrice(this.totalGrossWithServiceCharge))
          .replace('___GROSS_W_SERVICE_CHARGE_WO_VOID___', this.$filters.formatPrice(this.totalGrossWithServiceChargeWoVoid))
          .replace('___GROSS_WO_SERVICE_CHARGE_WO_VOID_SECTION___', this.totalServiceCharge != 0 ? `<tr>
                <td valign="top">Gross w/o Service Charge</td>
                <td valign="top">${this.$filters.formatPrice(this.totalWOVoidAndServiceCharge)}</td>
            </tr>` : ''),
        ___SERVICE_CHARGE_SECTION___: this.totalServiceCharge != 0 ? this.serviceChargeSection
          .replace('___SERVICE_CHARGE___', this.$filters.formatPrice(this.totalServiceCharge)) : '',
        ___OTHER_CHARGES___: this.createValueLabelRow('Other Charges ', this.totalOtherCharges),
        ___TOTAL_DISCOUNTS__: this.$filters.formatPrice(this.totalDiscounts),
        ___VOIDED_SALES___: this.$filters.formatPrice(this.totalVoidedSales),
        ___VOIDED_BILLED___: this.$filters.formatPrice(this.totalVoidedBilled),
        ___VATABLE_SALES___: this.$filters.formatPrice(this.totalVatableSales),
        ___VAT_AMOUNT___: this.$filters.formatPrice(this.totalVatAmount),
        ___VAT_EXEMPT_SALES___: this.$filters.formatPrice(this.totalVatExempSales),
        ___ZERO_RATED_SALES___: this.$filters.formatPrice(this.totalZeroRatedSales),
        ___NET_SALES___: this.$filters.formatPrice(this.totalNetSales),
        ___TOTAL_CASH_VALUE___: this.$filters.formatPrice(totalCashValue),
        ___TOTAL_CASH_FLOAT___: this.$filters.formatPrice(cashFloat),
        ___CASH_FLOATS___: cashFundSection,
        ___CASH_SALES_SECTION___: this.cashSalesSection
              .replace('___TOTAL_CASH_SALES___', this.$filters.formatPrice(cashSales))
              .replace('___TOTAL_CASH_SALES_EXPECTED___', this.$filters.formatPrice(cashSales))
              .replace('___TOTAL_CASH_SALES_DECLARED___', this.$filters.formatPrice(totalCashValue - cashFloat)),
        ___OVER_SHORT___: overShortString.replace('___TOTAL_OVER_SHORT___', this.$filters.formatPrice(overShort)),
        ___LAST_KOT___: this.locationStatus == 'Inactive' ? 0 : (this.lastKot ?? 0),
        ___LAST_BILL_NUM___: this.locationStatus == 'Inactive' ? 0 : (this.lastBillNum ?? 0),
        ___TRANSACTION_COUNT___: this.totalTransaction,
        ___TOTAL_PAX___: this.totalPax,
        ___AVERAGE_PER_TRANSACTION___: this.$filters.formatPrice(this.avgPerCheck),
        ___GRAND_TOTAL___: this.$filters.formatPrice(this.endingBalance),
        ___POS_DATE___: isReprint ? moment(this.dateFilter).format("YYYY-MM-DD") : moment(this.posDate).format("YYYY-MM-DD"),
        ___SHIFT_NO___: this.shiftTable.shift,
        ___TERMINAL_ID___: this.shiftTable.terminal_id,
        ___GENERATED_BY___: this.generatedBy,
        ___SUB_TOTAL_TITLE___: this.totalServiceCharge != 0 ? 'Net Sales w/ VAT & SC' : 'Net Sales w/ VAT',
        ___SUB_TOTAL___: this.$filters.formatPrice(this.totalNetSalesWCharges),
        ___DISCOUNT_ITEMS___ : discountItemsStr,
        ___SERVICE_TYPES___  : serviceTypeStr,
        ___HOURLY_SALES___   : this.generateHourlySalesString(this.hourlySales, this.locationId),
        ___INCOME_HEAD_SALES___ : incomeHeadSalesStr(),
        ___PAYMENT_METHODS___ : paymentMethodsStr,
        ___PRODUCT_MIX_SUMMARY___: this.$can(this.PERMISSIONS.PRINT_PMIX)  && !separateSections.includes('pmix') && this.locationStatus != 'Inactive' ? productMixSummary : '',
        ___REPORT_TIMESTAMP_SECTION___: this.reportTimestampSection
          .replace('___RECIEPT_PRINTED_DATE___', moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
          .replace('___RECIEPT_PRINTED_DATE_ONLY___', moment(new Date()).format("YYYY-MM-DD"))
          .replace('___RECIEPT_PRINTED_TIME_ONLY___', moment(new Date()).format("HH:mm:ss")),
        ___OLD_GRAND_TOTAL___: this.getOldGrandTotalString(this.beginningBalance),
        ___VAT_ADJUSTMENT___ : vatAdjustmentStrings,
      };

      let printConfig = {};

      print(this.generatePrintData(payload), printConfig);

    },

    async printProductMix(isReprint, paused = true) {
      //get separate sections
      let separateSections = JSON.parse(this.recieptDetails.print_zread_sections);
      let canPrintCurrentPmix = this.$can(this.PERMISSIONS.PRINT_PMIX) && !this.$can(this.PERMISSIONS.OLD_ZREAD_FORMAT) && !isReprint && !separateSections.includes('pmix') && this.locationStatus != 'Inactive';
      let canPrintPreviousPmix = this.$can(this.PERMISSIONS.REPRINT_PMIX) && isReprint;

      if (!this.$can(this.PERMISSIONS.REPRINT_PMIX) && isReprint) {
        this.$swal.warning('You do not have permission to reprint product mix. Please contact your administrator.');
        return;
      }

      if (paused && isReprint) {
        const response = await this.$openApproverModal(PERMISSION_TYPES.REPRINT_PMIX);
        if (response.success) {
            await this.printProductMix(isReprint, false)
        }
        return;
      }

      this.dateFilter = moment(this.targetPosDate).format("YYYY-MM-DD");
      const selectedPosDate = isReprint ? this.dateFilter : moment(this.posDate).format("YYYY-MM-DD");

      if(GET_BREAKDOWNS_FROM_DB) {
        if (await checkCloudConnection()) {
          let fromOnline;
          try {
            fromOnline = await this.generateProductMixData(selectedPosDate);

            if (fromOnline.product_mix_summary.length == 0 && isReprint) {
              this.$swal.warning('No transactions found on ' + moment(selectedPosDate).format('LL'));
              return;
            }
          } catch (err) {
            console.log(err);
            return;
          }

          if (canPrintPreviousPmix || canPrintCurrentPmix) {
            print(this.generatePrintData({
              ___PRODUCT_MIX_SUMMARY___: this.generateProductMixString(fromOnline.product_mix_summary, fromOnline.product_mix_grand_total),
            }, productMixHtml), {});
          }

          return;
        } else {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to reprint product mix.');
          return;
        }
      }

      let generatedPmixData = this.generatePmixData();

      if (Object.keys(generatedPmixData).length === 0 && generatedPmixData.constructor === Object && isReprint) {
        this.$swal.warning('No transactions found on ' + moment(this.dateFilter).format('LL'));
        return;
      }

      let productMixSummary = this.generateProductMixString(this.generatePmixData(), this.generatePmixGrandTotal);
      const payload = {
        ___PRODUCT_MIX_SUMMARY___: productMixSummary,
      };

      let printConfig = {};

      if (canPrintPreviousPmix || canPrintCurrentPmix) {
        print(this.generatePrintData(payload, productMixHtml), printConfig);
      }
    },

    async processCashDrawer() {
      let printerData = await getPrinterData();
      openCashDrawer(printerData);
    },

    async printEOD(reportType = 'eod') {
      let printTemplate = eodHtml;

      let isReprint = false;

      let ccrSeries = `<tr>
            <td valign="top">Beg. SI#</td>
            <td valign="top">${this.padWithZero(this.beginningSettlement.receipt_num)}</td>
        </tr>
        <tr>
            <td valign="top">End. SI#</td>
            <td valign="top">${ this.padWithZero(this.endingSettlement.receipt_num) }</td>
        </tr>
        <tr>
            <td valign="top">Beg. CheckID#</td>
            <td valign="top"></td>
        </tr>
        <tr>
            <td valign="top">End. CheckID#</td>
            <td valign="top"></td>
        </tr>
        <tr>
            <td valign="top">Beg. Void Amt.</td>
            <td valign="top">${ this.$filters.formatPrice(this.beginningVoidAmount) }</td>
        </tr>
        <tr>
            <td valign="top">End. Void Amt.</td>
            <td valign="top">${ this.$filters.formatPrice(this.endingVoidAmount) }</td>
        </tr>`;

      let salesNetDiscString = this.serviceTypesTotal.map((st) => {
              return `<tr>
                  <td class="indent-2" style="width: 50%">${ st.name }</td>
                  <td class="text-right" style="width: 30%">${ this.$filters.formatPrice(st.amount) }</td>
                  <td style="width: 20%"></td>
                </tr>`
          }).join('');

      let serviceTypesNet = this.serviceTypesTotal.reduce((accumulator, st) => {
            return accumulator + st.amount;
          }, 0);

      let totalSalesIncomeAndCharge = serviceTypesNet + this.totalServiceCharge;

      let totalSettlement = this.totalEODCashSales + this.totalEODNonCashSales;

      let nonCashPaymentMethods = this.paymentMethods.filter(p => p.name != 'CASH');

      let nonCashRevString = nonCashPaymentMethods.map((nc) => {
        return `<tr>
          <td class="indent-2">${ nc.name }</td>
          <td class="text-right">${ nc.count ?? 0  }</td>
          <td class="text-right">${ this.$filters.formatPrice(nc.amount) }</td>
        </tr>`
      }).join('');

      let nonCashTotalsObj = {
              totalCount: nonCashPaymentMethods.reduce((accumulator, p) => {
                return accumulator + (p.count ?? 0);
              }, 0),
              totalAmount: nonCashPaymentMethods.reduce((accumulator, p) => {
              return accumulator + (p.amount ?? 0);
            }, 0),
          };

      let nonRevItemStr = '';

      let nonRevenueItems = () => {
        let nonRevItems = {};

        for (const prop in this.nonRevenueOrders) {
          this.nonRevenueOrders[prop].forEach(o => {
            if(nonRevItems[o.product.product_name]) {
              nonRevItems[o.product.product_name] += o.totals.total;
            } else {
              nonRevItems[o.product.product_name] = o.totals.total;
            }
          });
        }

        for(const prop in nonRevItems) {
          nonRevItemStr += `<tr>
              <td class="indent-2" style="width: 50%;">${ prop }</td>
              <td style="width: 30%" class="text-right">${ this.$filters.formatPrice(nonRevItems[prop]) }</td>
              <td style="width: 20%"></td>
            </tr>`;
        }

        return nonRevItems;
      }

      let nonRevTotal = () => {
        let total = 0;

        let items = nonRevenueItems();

        for (const prop in items) {
          total += items[prop];
        }

        return total;
      };

      let cashierShifts = () => {
        let data = {};

        this.shiftChangeRecord.forEach(cs => {
          let name = cs?.shiftTable?.user?.last_name + ' ' + cs?.shiftTable?.user?.first_name;
          if(data[name]) {
            data[name] += cs.over_short;
          } else {
            data[name] = 0;
          }
        });

        return data;
      };

      let totalCashForDeposit = reportType !== 'eod' ? this.totalEODCashSales + this.cashFloat[this.overrideTerminalId] - this.totalCashWithdraw : this.totalEODCashSales;

      let cashierShiftsString = `<tr>
            <td colspan="2" valign="top">Employee Over (Short)</td>
        </tr>`;
      let csArr = cashierShifts();
      for(const key in csArr) {
        cashierShiftsString += `<tr>
          <td class="indent-3" style="width: 50%">${key}</td>
          <td style="width: 30%" class="text-right
          ">
          ${this.$filters.formatPrice(csArr[key])}
          </td>
          <td style="width: 20%"></td>
        </tr>`
      }

      let cashierCheckoutSum = reportType !== 'eod' ? `<tr>
            <td colspan="2" valign="top">CHECKOUT SUMMARY</td>
        </tr>
        <tr>
            <td colspan="2"><br></td>
        </tr>
        <tr>
            <td colspan="2">CASH SETTLEMENT</td>
        </tr>
        <tr>
            <td valign="top">CALCULATED CASH OWED</td>
            <td valign="top">${this.$filters.formatPrice(totalCashForDeposit)}</td>
        </tr>
        <tr>
            <td valign="top">CALCULATED CASH DECLARED</td>
            <td valign="top">${this.$filters.formatPrice(0)}</td>
        </tr>
        <tr>
            <td valign="top">Cash Over (Short)</td>
            <td valign="top">${this.$filters.formatPrice(0)}</td>
        </tr>
        <tr>
            <td colspan="2" height="10"></td>
        </tr>` : '';

      let cashierCheckoutFooter = reportType !== 'eod' ? `<table id="z-footer">
          <tr>
              <td valign="top" style="font-size: 10px;">Employee</td>
              <td valign="top" style="font-size: 10px;"></td>
          </tr>
          <tr>
              <td colspan="2">
                  <br>
                  <br>
              </td>
          </tr>
          <tr>
              <td valign="top" style="font-size: 10px;">Cash Received By</td>
              <td valign="top" style="font-size: 10px;"></td>
          </tr>
      </table>` : '';

      let employeeCashTrans1 = reportType !== 'eod' ? `<tr>
            <td valign="top">Employee Cash Transactions</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId] - this.totalCashWithdraw)}</td>
        </tr>` : '';

      let employeeCashTrans2 = reportType !== 'eod' ? `<tr>
            <td colspan="2" valign="top">Employee Cash Transactions</td>
        </tr>
        <tr>
            <td valign="top">Cash Fund</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId] - this.totalCashWithdraw)}</td>
        </tr>
        <tr>
            <td valign="top">Cash Bleed 1</td>
            <td valign="top">0</td>
        </tr>
        <tr>
            <td valign="top">Total Employee Cash Transactions</td>
            <td valign="top">${this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId] - this.totalCashWithdraw)}</td>
        </tr>` : '';

      let cashierShiftInfo = reportType !== 'eod' ? `<tr>
            <td valign="top">Cashier Name:</td>
            <td valign="top">${this.username}</td>
        </tr>
        <tr>
            <td valign="top">Shift:</td>
            <td valign="top">${this.currentShift.shift}</td>
        </tr>
        <tr>
            <td valign="top">POS #:</td>
            <td valign="top">${this.shiftTable.terminal_id}</td>
        </tr>` : '';

      let totalGrossSales = this.totalAmount + this.totalDiscounts;
      let grossEndBalance = this.beginningBalance + totalGrossSales;
      let totalPunchedSales = totalGrossSales + this.totalVoidedSalesAmount;

      let employeeTotalOverShort = reportType === 'eod' ? `<tr>
            <td valign="top">Total Employee Over (Short)</td>
            <td valign="top">${this.$filters.formatPrice(this.totalOverShortAmount)}</td>
        </tr>`: '';

      let toReplace = {
        ___REPORT_TYPE___ : "EOD",
        __REPRINT__: isReprint ? "REPRINT" : "",
        ___DATE_AND_TIME___: moment(this.receiptDate).format("LLL"),
        ___STORE_CODE___: '',
        ___BRAND_BRANCH___: this.recieptDetails.account_name + " " + this.recieptDetails.location,
        ___DOB___: moment(this.posDate).format("LLL"),
        ___BIR_MIN___: this.recieptDetails.lp_min,
        ___CASHIER_NAME___: this.username,
        ___SHIFT___: this.currentShift.shift,
        ___POS_NUMBER___: this.shiftTable.terminal_id,
        ___BEGINNING_OPENREADING___: "Opening Reading",
        ___ENDING_CLOSEREADING___: "Closing Reading",
        ___GROSS_SALES_BEGINNING___: this.$filters.formatPrice(this.beginningBalance),
        ___GROSS_SALES_ENDING___: this.$filters.formatPrice(grossEndBalance),
        ___CCR_SERIES___: reportType !== "eod" ? ccrSeries : '',
        ___TOTAL_GROSS_SALES___: this.$filters.formatPrice(totalGrossSales),
        ___TOTAL_PUNCHED_SALES___: this.$filters.formatPrice(totalPunchedSales),
        ___GROSS_WO_SERVICE_CHARGE___: this.$filters.formatPrice(this.totalBeforeServiceCharge),
        ___TOTAL_SVC_CHARGE_AMT___: this.$filters.formatPrice(this.totalServiceCharge),
        ___TOTAL_DISCOUNT___: this.$filters.formatPrice(this.totalDiscounts),
        ___TOTAL_VOID_SALES___: this.$filters.formatPrice(this.totalVoidedSales),
        ___SALES_NET_DISCOUNT___: salesNetDiscString,
        ___TOTAL_SALES_INCOME_AND_CHARGE___: this.$filters.formatPrice(totalSalesIncomeAndCharge),
        ___VATABLE_SALES___: this.$filters.formatPrice(this.totalVatableSales),
        ___TOTAL_VAT_AMT___: this.$filters.formatPrice(this.totalVatAmount),
        ___TOTAL_SETTLEMENT___: this.$filters.formatPrice(totalSettlement),
        ___REVENUE_TOTAL_CASH___: this.$filters.formatPrice(this.totalEODCashSales),
        ___REVENUE_TOTAL_NON_CASH___: this.$filters.formatPrice(this.totalEODNonCashSales),
        ___NONCASH_REVENUE_SETTLEMENT___: nonCashRevString,
        ___TOTAL_NON_CASH_COUNT___: nonCashTotalsObj.totalCount,
        ___TOTAL_NON_CASH_AMT___: this.$filters.formatPrice(nonCashTotalsObj.totalAmount),
        ___TOTAL_NON_REVENUE_AMT___: this.$filters.formatPrice(nonRevTotal()),
        ___NON_REVENUE_ITEMS___: nonRevItemStr,
        ___NON_REVENUE_TOTAL_SETTLEMENT___: this.$filters.formatPrice(0),
        ___NON_REVENUE_TOTAL_CASH___: this.$filters.formatPrice(0),
        ___NON_REVENUE_TOTAL_NON_CASH___: this.$filters.formatPrice(0),
        ___NON_REVENUE_SETTLEMENT___: '',
        ___TOTAL_CASH_FOR_DEPOSIT___: this.$filters.formatPrice(totalCashForDeposit),
        ___TOTAL_NON_CASH_FOR_DEPOSIT___:this.$filters.formatPrice(this.totalEODNonCashSales) ,
        ___CASH_FUND___: this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId]),
        ___TOTAL_GROSS_NET___: this.$filters.formatPrice(this.totalAmount),
        ___TOTAL_NET_SALES___: this.$filters.formatPrice(serviceTypesNet),
        ___TOTAL_CASH_VALUE___: this.$filters.formatPrice(this.totalCash),
        ___TOTAL_CASH_FLOAT___: this.$filters.formatPrice(this.cashFloat[this.overrideTerminalId]),
        ___TOTAL_CASH_SALES___: this.$filters.formatPrice(this.totalCashSales + this.totalCashWithdraw),
        ___TOTAL_OVER_SHORT___: this.$filters.formatPrice(this.totalOverShortAmount),
        ___ACCOUNTABILITY_CASHIERS___: reportType === "eod" ? cashierShiftsString : '',
        ___TOTAL_CASH_DECLARED___: this.$filters.formatPrice(0),
        ___TOTAL_CASH_OVER_SHORT___: this.$filters.formatPrice(0),
        ___CASHIER_CHECKOUT_SUM___: cashierCheckoutSum,
        ___CASHIER_CHECKOUT_FOOTER___: cashierCheckoutFooter,
        ___EMPLOYEE_CASH_TRANSACTIONS1___: employeeCashTrans1,
        ___EMPLOYEE_CASH_TRANSACTIONS2___: employeeCashTrans2,
        ___CASHIER_SHIFT_INFO___: cashierShiftInfo,
        ___EMPLOYEE_TOTAL_OVER_SHORT___: employeeTotalOverShort,
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
        printTemplate = printTemplate.replace(RE, function(matched) {
            return toReplace[matched];
        });

      console.log(
                convert(printTemplate,
                    {
                        wordwrap: 130,
                        preserveNewlines: true,
                    })
                );

      let printData = {
                print_type: 'EOD',
                html: printTemplate,
            };

      let printConfig = {
      };

      print(printData, printConfig);
    },

    checkIfHasGcExcess(settlement) {
      const vouchers = settlement.payments.filter(payment => payment.type === 'voucher');

      if(!vouchers.length) {
        return false;
      }

      if(sumBy(vouchers, 'amount') > settlement.breakdown.total) {
        return true
      }

      return false
    },

    async delay(time = 5000) {
      await new Promise(resolve => setTimeout(resolve, time));
    },

    async getSqliteOrders () {
      if (!OFFLOAD.sqliteOffloadReceipt) {
        return;
      }

      const { BILLED, PAID, VOIDED, ORIGINAL } = TRANSACTION_STATUS_ID;
      const orders = await (new OrderBridge()).getPendingOrders(`${BILLED}, ${PAID}, ${VOIDED}, ${ORIGINAL}`);
      this.sqliteBilledOrders = orders.map(order => JSON.parse(order.order_data));
    }
  },
}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}


:deep(#useWholeAmountInput) {
  font-weight: bold;
  white-space: nowrap;
}

.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>
