<template>
    <div class="table-details-page d-flex">
        <div class="col-12 col-lg-8 py-4 px-3">
            <div class="d-flex align-items-center"
               :class="{
                  'mb-4': offload == null,
                  'mb-2': offload != null
               }"
            >
                <button
                    class="btn btn-primary btn-lg mr-2"
                    :disabled="isFetching"
                    @click="triggerManualFetch">
                    <i
                        class="fa fa-sync-alt"
                        :class="{ rotating: isFetching }"
                        />
                </button>

                <input
                    v-model="menuQuery"
                    class="form-control col"
                    :class="{ 'has-query': menuQuery.length > 0 }"
                    type="text"
                    placeholder="Type to search menu"
                    @input="handleSearchMenu"
                />
            </div>

            <div class="btn-group mb-4 flex-container d-none d-lg-flex flex-wrap" role="group">
                <button
                    v-for="category in categories"
                    :key="`category-${category}`"
                    type="button"
                    class="btn btn-lg"
                    :class="{
                        'btn-primary': category.id === activeCategory,
                        'btn-light': category.id !== activeCategory,
                    }"
                    @click="toggleActiveCategory(category)"
                >
                    {{ category.name }}
                </button>
            </div>

            <div class="btn-group mb-4 flex-container d-none d-lg-flex flex-wrap" role="group">
                <button
                    v-for="group in filteredGroups"
                    :key="`group-${group}`"
                    type="button"
                    class="btn"
                    :class="{
                        'btn-primary': group.id === activeGroup,
                        'btn-light': group.id !== activeGroup,
                    }"
                    @click="toggleActiveGroup(group)"
                >
                    {{ group.name }}
                </button>
            </div>

            <div class="mb-3 d-lg-none">
                <label class="dropdown-label mx-0">Category</label>
                <select class="form-control" @change="onCategoryDropdownChange($event.target.value)" v-model="activeCategory">
                    <option v-for="category in categories"
                        :key="`category-${category}`"
                        :value="category.id">
                        {{ category.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3 d-lg-none">
                <label class="dropdown-label mx-0">Sub-category</label>
                <select class="form-control" @change="onGroupDropdownChange($event.target.value)" v-model="activeGroup">
                    <option v-for="group in filteredGroups"
                        :key="`group-${group}`"
                        :value="group.id">
                        {{ group.name }}
                    </option>
                </select>
            </div>

      <div class="btn-group mb-4 flex-container d-flex flex-wrap" role="group"
        v-if="activeGroup && filteredSubGroups.length > 0">
        <button v-for="subGroup in filteredSubGroups" :key="`sub-group-${subGroup}`" type="button" class="btn"
          :class="{
            'btn-primary': subGroup.id === activeSubGroup,
            'btn-light': subGroup.id !== activeSubGroup,
          }" @click="toggleActiveSubGroup(subGroup)">
          {{ subGroup.name }}
        </button>
      </div>

      <div v-if="canSortByPopularity" class="btn-group flex-container d-flex flex-wrap" role="group" :class="{
        'mb-4': offload == null,
      }">
        Sort by:
        <button v-for="sbpInterval in sortByPopularityInterval" :key="`sort-by-${sbpInterval}`" type="button"
          class="btn" :class="{
            'btn-primary': sbpInterval.id === activeSortByPopularityInterval,
            'btn-light': sbpInterval.id !== activeSortByPopularityInterval,
          }" @click="toggleActiveSortByPopularityInterval(sbpInterval)">
          {{ sbpInterval.name }}
        </button>
      </div>

      <hr />

      <no-items v-if="isFilteredProductsGreaterThanMax" icon="search"
        text="Please select a category or search a product" />

      <no-items v-else-if="offload != null && offloadProducts.length === 0"
        :icon="offloadProductsLoading ? 'spinner' : ''"
        :text="!offloadProductsLoading && offloadProducts.length === 0 ? 'No items found' : ''" />

      <div v-else class="product-items-container">
      <div class="products-container flex-container d-flex flex-wrap"
        v-bind:class="{ 'disabled-container': disabledContainer }">
        <m-product v-for="product in filteredProducts" :product="product"
          :is-product-available="isProductAvailable(product)" :key="`product-${product.id}`"
          @productSelected="addOrderItem(product)" :apply-custom-style="offload != null" />

        <m-open-item v-if="openProduct" @clickedOpenItemModal="openOpenItemModal()" />

        <m-product v-for="index in Math.min(12, filteredProducts.length)" class="flex-spacer"
          :product="filteredProducts[0]" :is-product-available="isProductAvailable(filteredProducts[0])"
          :key="`product-spacer-${index}`" />
      </div>
    </div>

      <pagination v-if="offload != null && offloadProducts.length > 0" :class="{ 'mt-4': totalItems <= 6 }"
        :current-page="productParams.page" :items-per-page="productParams.itemsPerPage" :total-items="totalItems"
        @onPageChange="fetchOffloadProducts" />
    </div>


    <orders-panel ref="ordersPanel" :orders="orderItems" :service-type="serviceType"
      :pax="$can(PERMISSIONS.PAX_DISABLED) ? 1 : pax" :table-id="tableId || activeOrder.tableId"
      :order-detail="activeOrder.orderDetail" :kots="activeOrder.kots" :edit="Boolean(orderId)"
      :channel-name="channelName" consolidate-orders @clearOrders="clearOrders" @saveOrders="saveOrders"
      @saveItemRequest="saveItemRequest" @saveOrderDetail="saveOrderDetail" @updatePax="updatePax"
      @applyDiscount="applyDiscount" @unsavedOrdersRearranged="updateOrderItems" />

    <customized-products-modal v-model="isCustomizedProductsModalOpen" :productName="activeProduct?.product_name"
      :customizedProducts="activeProduct?.customized_products" :serviceType="serviceType"
      @saveSelectedValues="saveCustomizedProductSummary" />

    <modifiers-modal v-model="isModifiersModalOpen" :modifiers="activeModifiers" @modsSelected="addModifiers" />

    <pax-modal v-model="isPaxModalOpen" :current-pax="pax" @paxSelected="savePax" />
    <open-item-modal v-model="isOpenItemModalOpen" @saveOpenItemProduct="addOpenItemProduct" />
  </div>
  <div id="kotHTML" style="display: none">{{ kotHTMLContent }}</div>
</template>

<script>
import {
  uniqBy,
  map,
  orderBy,
  get,
  findIndex,
  uniq,
  isEqual,
  pick,
} from "lodash";
import cloneDeep from "rfdc/default";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { checkCloudConnection } from "@/spa/plugins/axios";

import {
  getReceiptDetails,
  getOwnBrands,
} from "@/spa/services/cashier-service";

import {
  getAllProducts,
  getPopularProducts,
  getAllModifiers,
  getTaxes,
  getProductPricings,
  getPromos,
} from "@/spa/services/product-service";

import { print } from "@/spa/services/printer-service";

import { convert } from "html-to-text";

import kotHtml from "@/spa/components/templates/print/kot.html";
import MProduct from "@/spa/components/common/MProduct";
import OrdersPanel from "@/spa/components/panels/OrdersPanel";
import ModifiersModal from "@/spa/components/modals/ModifiersModal";
import CustomizedProductsModal from "@/spa/components/modals/CustomizedProductsModal";
import PaxModal from "@/spa/components/modals/PaxModal";
import MOpenItem from "@/spa/components/common/MOpenItem";
import OpenItemModal from "@/spa/components/modals/OpenItemModal";
import { toIsoString } from "@/spa/utils/date-format";
import { getLatestSeries } from "@/spa/services/sync-service";
import {
    POS_DATE_STATUSES,
    MAX_PRODUCT_COUNT_FOR_ALL,
    ENABLE_POPULARITY_SORT,
    SAVE_LINE_ITEM_SEPARATOR_POSITIONS,
    LINE_ITEM_SEPARATOR_CHAR_COUNT,
    IS_NON_VAT,
    USER_TYPE_IDS,
    ENABLE_PARENT_CHILD_CLASSIFICATION,
    IS_ROBINSON_ACCREDITED,
    OFFLOAD,
    ACTIVE_ACCOUNT_TYPE,
    ACCOUNT_TYPES,
} from "@/spa/constants";
import { kotGroupOrdersByServiceType } from "@/spa/utils/print";
import NoItems from "@/spa/components/placeholders/NoItems.vue";
import NetworkMixin from "@/spa/components/mixins/NetworkMixin";
import seriesService from "@/spa/services/series-service";
import Pagination from "@/spa/components/common/Pagination";
import moment from "moment";
import {OFFLOAD_RECEIPT_ACTION} from "@/mobile_bridge/offload/offload-receipt";
import {generateDateTime} from "@/mobile_bridge/offload/receipt-model";

// DO NOT DELETE!!! might be use in the future
// import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Navigation, Slide } from 'vue3-carousel';

export default {
  name: "ProductsPage",

  components: {
    MProduct,
    OrdersPanel,
    ModifiersModal,
    PaxModal,
    MOpenItem,
    OpenItemModal,
    NoItems,
    CustomizedProductsModal,
    Pagination,


    // DO NOT DELETE!!! might be use in the future
    // Carousel,
    // Slide,
    // Navigation,
  },

  mixins: [NetworkMixin],

  props: {
    tableId: {
      type: String,
      default: "",
    },
    serviceType: {
      type: String,
      default: "Dine-in",
    },
    channelName: {
      type: String,
      default: "",
    },
    orderId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isCartVisible: true,
      cartDisplay: "d-none d-lg-block",
      products: [],
      categories: [],
      groups: [],
      subGroups: [],
      activeGroup: "",
      activeSubGroup: "",
      activeCategory: "",
      activeSortByPopularityInterval: 1,
      menuQuery: "",
      orderDetail: "",
      orderCustomerName: null,
      modifiers: {},
      activeModifiers: [],
      activeProduct: null,
      isModifiersModalOpen: false,
      isOpenItemModalOpen: false,
      isCustomizedProductsModalOpen: false,
      pax: 0,
      isPaxModalOpen: false,
      orderItems: [],
      kotHTMLContent: "",
      taxData: [],
      pricings: [],
      brands: [],
      promos: [],
      promoGroups: [],
      isFetching: false,
      openProduct: null,
      disabledContainer: this.orderId ? false : true,
      tempModifier: null,
      isMidnightOperation: false,
      operatingHours: null,

      offload: OFFLOAD.sqliteOffloadProduct ? new ProductBridge() : null,
      offloadGroups: [],
      offloadProducts: [],
      offloadProductsLoading: false,
      productParams: {
        id: null,
        categoryId: null,
        groupId: null,
        subGroupId: null,
        productName: null,
        page: 1,
        itemsPerPage: 14,
      },
      totalItems: 0,
    };
  },

  beforeDestroy() {
    window.eventBus.off("show-cart", this.handleCartToggle);
  },

  computed: {
    cartDisplay() {
      return this.isCartVisible ? "d-none d-lg-block" : "";
    },

    ...mapState([
      "orders",
      "activeBrandId",
      "activeServiceTypeId",
      "activeChannelId",
      "lastKot",
      "offlineTransactionCount",
      "dataContainer",
      "user",
    ]),

    ...mapState("user", ["terminalId", "locationId"]),

    ...mapState("settings", [
      "shiftTable",
      "currentShift",
      "cashFloat",
      "hasCashFloat",
    ]),

    ...mapGetters(["activeOrder"]),

    ...mapGetters("user", ["multiTerminalType"]),

    ...mapState("sqlite", ["initialData"]),

    filteredGroups() {
      const filteredPromoGroup = () => {
        return this.groups.filter(
          (g) => this.promoGroups.includes(g.id) || g.id == ""
        );
      };

      if (OFFLOAD.sqliteOffloadProduct) {
        return this.offloadGroups;
      }

      if (this.activeCategory === 0) {
        //for promos
        return filteredPromoGroup();
      }

      if (!this.activeCategory) return this.groups;
      return this.groups.filter(
        (g) => !g.category || g.category === this.activeCategory
      );
    },

    filteredSubGroups() {
      const filtered = this.subGroups.filter(
        (g) => g.group_id === this.activeGroup
      );
      if (filtered.length === 0) {
        return [];
      } else {
        return [{ id: "", name: "ALL" }, ...filtered];
      }
    },

    sortByPopularityInterval() {
      return [
        { id: 1, name: "Default", value: "default" },
        { id: 2, name: "Top today", value: 1 },
        { id: 3, name: "Top weekly", value: 7 },
        { id: 4, name: "Top monthly", value: 30 },
      ];
    },

    hasActiveFilter() {
      return (this.activeGroup && this.activeCategory) || this.menuQuery;
    },

    isFilteredProductsGreaterThanMax() {
      if (this.hasActiveFilter) return false;
      if (MAX_PRODUCT_COUNT_FOR_ALL === 0) return false;

      if (OFFLOAD.sqliteOffloadProduct) {
        return this.offloadProducts.length > MAX_PRODUCT_COUNT_FOR_ALL;
      }

      return this.filteredProducts.length > MAX_PRODUCT_COUNT_FOR_ALL;
    },

    filteredProducts() {
      if (OFFLOAD.sqliteOffloadProduct) {
        return this.offloadProducts;
      }

      let products = this.products;
      if (this.activeCategory === 0) {
        products = this.activeGroup
          ? this.promos.filter((p) => p.product_group_id === this.activeGroup)
          : this.promos;
      } else {
        if (this.activeGroup) {
          const hasActiveSubGroup = (p) => {
            if (this.activeSubGroup) {
              return this.activeSubGroup == p.product_sub_group_id;
            }
            return true;
          };
          products = this.products.filter(
            (p) =>
              p.product_group_id === this.activeGroup &&
              hasActiveSubGroup(p) &&
              p.product_tag != "Open"
          );
        } else if (this.activeCategory) {
          products = this.products.filter(
            (p) =>
              p.product_group.product_category_id === this.activeCategory &&
              p.product_tag != "Open"
          );
        }

        if (this.menuQuery) {
          products = products.filter((p) =>
            p.product_name.toLowerCase().includes(this.menuQuery.toLowerCase())
          );
        }
      }

      return products;
    },

    isPaxEnabled() {
      // Future enhancement, enable pax for specific service types only
      if (this.$can(this.PERMISSIONS.PAX_DISABLED)) {
        this.disabledContainer = false;
        return false;
      }
      this.disabledContainer = false;
      return true;
    },

    canSortByPopularity() {
      // Temporarily disable this for the APK version
      return ENABLE_POPULARITY_SORT && !OFFLOAD.sqliteOffloadProduct;
    },

    serviceTypes() {
      if (!this.activeBrandId) return [];

      const activeBrand = this.brands.find(
        (brand) => brand.id === this.activeBrandId
      );

      return activeBrand ? activeBrand.service_types : [];
    },

    noNetwork() {
      return tempTerminalId != 1 && !this.isOnline;
    },
  },

  async beforeMount() {
    if (OFFLOAD.sqliteOffloadProduct) {
      await this.getProductCategoriesAndGroups();
    }
  },

  async mounted() {
    window.eventBus.on("show-cart", this.handleCartToggle);

    if (!this.$can(this.PERMISSIONS.PLACE_ORDER)) {
      this.$swal.warning(
        "You do not have permission to place order. Please contact your administrator."
      );
      this.$router.push({ name: "home" });
      return;
    }

    if (this.hasNoCashFund()) {
      this.$swal.warning(
        "Cash fund is required before processing transaction. Please set up initial cash fund."
      );
      this.$router.push({
        name: "home",
        params: { forceCashFund: this.terminalId == 1 },
      });
      return;
    }
    if (this.hasNoCashFund()) {
      Swal.fire({
        icon: 'error',
        title: 'You did not yet define the Beginning Cash Fund!',
        text: 'Kindly set the Beginning Cash Fund amount before processing a transcaction',
        confirmButtonText: 'OK'
      });
      this.$router.push({ name: 'home', params: { forceCashFund: this.terminalId == 1 } });
    }

    if (this.forceCashFundInputForCashierOrWaitstaff()) {
      this.$swal.warning('Cash fund is required before processing transaction. Please set up initial cash fund.');
      this.$router.push({ name: 'home', params: { forceCashFund: this.terminalId == 1 } });
      return;
    }

    // this.checkIfMidnightOperation();
    if (this.checkMandatoryDayend()) return;
    if (this.checkIfInOperatingHours()) return;

    if (!OFFLOAD.sqliteOffloadProduct) {
      window.__showLoader();
    }

    await this.populateBrandId();
    await this.populateServiceTypeId();
    await this.fetchTaxes();
    await this.fetchPricings();
    await this.fetchAllProducts();
    await this.fetchPromos();
    this.parseCategories();
    this.parseGroups();
    this.parseSubGroups();
    this.parseExistingOrders();

    this.fetchAllModifiers();
    this.parseReceiptDetails();

    this.isPaxModalOpen = !this.orderId && this.isPaxEnabled;
    this.pax = this.activeOrder.pax ?? 0;
    window.__hideLoader();

    window.addEventListener("beforeunload", () => this.beforeUnload());
  },

  methods: {
    handleCartToggle() {
      this.isCartVisible = !this.isCartVisible;
      this.cartDisplay =
        this.cartDisplay === "d-none d-lg-block" ? "d-block" : "";
    },

    ...mapMutations([
      "setActiveOrderId",
      "setActiveBrandId",
      "setActiveServiceTypeId",
      "incrementOfflineTransactionCount",
      "updateOrder",
      "addOrder",
      "addOrUpdateDataContainer",
      "setLastKot",
      "incrementLastKot",
    ]),

    ...mapMutations("global", ["setPosStatus"]),

    ...mapActions(["sqliteUpsertReceipt", "checkAndSyncSqliteOffloadTables"]),

    beforeUnload() {
      this.clearOrders();
      if (this.activeOrder.billDiscount && "ordersPanel" in this.$refs) {
        this.$refs.ordersPanel.applyBillDiscount(this.activeOrder.billDiscount);
      }
    },

    hasNoCashFund(){
      return !this.hasCashFloat;
    },

    forceCashFundInputForCashierOrWaitstaff(){
      const cashierAndWaitstaffIds = [USER_TYPE_IDS.CASHIER, USER_TYPE_IDS.WAITSTAFF];
      return cashierAndWaitstaffIds.includes(this.user.userTypeId) && !this.hasCashFloat;
    },

    async fetchOwnBrands() {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.brands = this.initialData.brands;
        return;
      }

      try {
        const response = await getOwnBrands();
        this.brands = response.data;
      } catch (e) {
        console.error(e);
        this.brands = [];
      }
    },

    async populateBrandId() {
      await this.fetchOwnBrands();
      if (this.activeBrandId) return;

      if (this.brands.length === 1) {
        this.setActiveBrandId(this.brands[0].id);
      } else {
        this.$router.push({ name: "home" });
      }
    },

    async populateServiceTypeId() {
      if (this.activeServiceTypeId) return;

      const serviceTypeObj = this.serviceTypes.find(
        (st) => st.service_name === this.serviceType
      );

      if (serviceTypeObj) {
        this.setActiveServiceTypeId(serviceTypeObj.id);
      } else {
        this.$router.push({ name: "home" });
      }
    },

    checkMandatoryDayend() {
      if (IS_ROBINSON_ACCREDITED) {
        return false;
      }

      const isMandatoryDayEnd = sessionStorage.getItem('is_mandatory_day_end');

      if (
        isMandatoryDayEnd == "1" &&
        !window.tempPosAndCalendarDateMatches &&
        !this.isMidnightOperation
      ) {
        try {
          // try to spawn notif popup
          this.setPosStatus({ show: true, status: POS_DATE_STATUSES.OVER });
        } catch (e) {
          // do nothing if global method not exposed
        }

        this.$router.go(-1);
        return true;
      }

      return false;
    },

    checkIfInOperatingHours() {
      if (!IS_ROBINSON_ACCREDITED) {
        return false;
      }

      //disregards OPERATING HOURS - Robinsons tenants wont be able to punch past 4AM the next Calendar Date
      let posDate = moment(sessionStorage.getItem('posDate'), 'YYYY-MM-DD');
      let maxOperatingHours = posDate.add(1, 'day').add(4, 'hours');

      if (maxOperatingHours.isBefore(moment())) {
        this.setPosStatus({ show: true, status: POS_DATE_STATUSES.OVER });
        this.$router.go(-1);
        return true;
      }
      return false;
      // if(!this.operatingHours) return false;

      // const currentTime = moment();
      // // let posDate = moment(sessionStorage.getItem('posDate'), 'YYYY-MM-DD');
      // let startTime = moment(this.operatingHours.startTime, 'hh:mm A');
      // let endTime = moment(this.operatingHours.endTime, 'hh:mm A');

      // if (this.isMidnightOperation) {
      //   startTime = moment(`${posDate.format('YYYY-MM-DD')} ${startTime.format('hh:mm A')}`);
      //   endTime = moment(`${posDate.add(1, 'days').format('YYYY-MM-DD')} ${endTime.format('hh:mm A')}`);

      //   if(!currentTime.isBetween(startTime, endTime)) {
      //     this.setPosStatus({ show:true, status: POS_DATE_STATUSES.OVER});
      //     this.$router.go(-1);
      //     return true;
      //   }
      // }

      // if(
      //   !this.isMidnightOperation &&
      //   !currentTime.isBetween(startTime, endTime)
      // ) {
      //   this.setPosStatus({ show:true, status: POS_DATE_STATUSES.OVER});
      //   this.$router.go(-1);
      //   return true;
      // }
      // return false;
    },

    checkIfMidnightOperation() {
      const operatingHours = JSON.parse(
        sessionStorage.getItem("current_operating_hours")
      );
      if (!operatingHours || !IS_ROBINSON_ACCREDITED) return;

      let startTime = moment(operatingHours.startTime, "hh:mm A");
      let endTime = moment(operatingHours.endTime, "hh:mm A");

      this.operatingHours = operatingHours;
      if (endTime.isBefore(startTime)) {
        this.isMidnightOperation = true;
      }
    },

    async fetchAllProducts(refresh = false) {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.offloadProducts = [];
        if (refresh) {
          const key = "productDetailLastFetchAt";
          const storage = new ScopedNativeStorage(window.locationId);
          const storageDate = await storage.get(key);
          const params = {
            isEnableSqlite: OFFLOAD.sqliteOffloadProduct,
            lastFetchAt: storageDate || null,
          };

          const response = await getAllProducts(refresh, false, params);
          const { values } = response.data;
          await this.offload.bulkImport(values);

          storage.put(key, moment().format("YYYY-MM-DD HH:mm:ss"));
        }

        return;
      }

      window.__showLoader();
      const oldProducts = this.products;
      this.isFetching = true;
      try {
        this.products = [];
        this.openProduct = null;

        const response = await getAllProducts(refresh);
        this.products = orderBy(
          response.data.values.filter(
            (p) => p.brand_id == this.activeBrandId && p.product_tag != "Open"
          ),
          "available",
          "desc"
        );

        this.openProduct = response.data.values.find(
          (p) => p.product_group?.group_name === "Open"
        );

        this.parseCategories();

        window.__hideLoader();
        this.isFetching = false;
      } catch (e) {
        this.products = oldProducts;
        console.error(e);
        window.__hideLoader();
        this.isFetching = false;
      }
    },

    async fetchPopularProducts(value) {
      if (OFFLOAD.sqliteOffloadProduct) return;

      window.__showLoader();
      const oldProducts = this.products;
      this.isFetching = true;
      try {
        this.products = [];
        this.openProduct = null;

        const response = await getPopularProducts(value);
        this.products = orderBy(
          response.data.values.filter(
            (p) => p.brand_id == this.activeBrandId && p.product_tag != "Open"
          ),
          "available",
          "desc"
        );

        this.openProduct = response.data.values.find(
          (p) => p.product_group?.group_name === "Open"
        );

        window.__hideLoader();
        this.isFetching = false;
      } catch (e) {
        this.products = oldProducts;
        console.error(e);
        window.__hideLoader();
        this.isFetching = false;
      }
    },

    async parseReceiptDetails() {
      try {
        let receiptResponse = await getReceiptDetails();
        let details = Object.values(receiptResponse.data).find(
          (d) => d.brand_id == this.activeBrandId
        );
        this.receiptDetails = details;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPricings(refresh = false) {
      if (OFFLOAD.sqliteOffloadProduct) {
        if (refresh) {
          const key = "productPricingLastFetchAt";
          const storage = new ScopedNativeStorage(window.locationId);
          const storageDate = await storage.get(key);
          const params = {
            lastFetchAt: storageDate || null,
          };

          const response = await getProductPricings(refresh, false, params);
          const values = response.data;
          const pb = new PriceBridge();
          await pb.bulkImport(values);

          storage.put(key, moment().format("YYYY-MM-DD HH:mm:ss"));
        }

        return;
      }

      try {
        const response = await getProductPricings(refresh);
        this.pricings = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchTaxes() {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.taxData = this.initialData.taxes;

        return;
      }

      try {
        const response = await getTaxes();
        this.taxData = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchAllModifiers(refresh = false) {
      if (OFFLOAD.sqliteOffloadModifier) {
        if (refresh) {
          const key = "modifierDetailLastFetchAt";
          const storage = new ScopedNativeStorage(window.locationId);
          const storageDate = await storage.get(key);
          const params = {
            isEnableSqlite: OFFLOAD.sqliteOffloadModifier,
            lastFetchAt: storageDate || null,
            appVersionCode: window.MosaicPosAppVersionNumbers?.versionCode,
          };

          const response = await getAllModifiers(refresh, false, params);
          const values = response.data;
          const pmb = new ProductModifierBridge();
          await pmb.bulkImport(values);

          storage.put(key, moment().format("YYYY-MM-DD HH:mm:ss"));
        }

        await this.fetchOffloadProducts(this.productParams.page);
        return;
      }

      try {
        const response = await getAllModifiers(refresh);
        this.modifiers = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchPromos() {
      if (OFFLOAD.sqliteOffloadProduct) return;

      try {
        const response = await getPromos();
        this.promos = response.data.map((o) => ({
          ...o,
          isPromo: true,
          promo_id: o.id,
          ...this.products.find((p) => p.id == o.main_item_id),
        }));

        this.promos.forEach((p) => this.promoGroups.push(p.product_group_id));
      } catch (e) {
        console.error(e);
      }
    },

    async parseCategories() {
      if (OFFLOAD.sqliteOffloadProduct) return;

      this.categories = [
        { id: "", name: "ALL" },
        ...orderBy(
          uniqBy(
            map(this.products, (p) => ({
              id: p.product_group.product_category_id,
              name: p.category,
            })),
            "id"
          ),
          "name"
        ),
      ];

      if (this.promos.length > 0)
        this.categories.push({ id: 0, name: "PROMO" });
    },

    parseGroups() {
      if (OFFLOAD.sqliteOffloadProduct) return;

      this.groups = [
        { id: "", category: "", name: "ALL" },
        ...orderBy(
          uniqBy(
            map(this.products, (p) => ({
              id: p.product_group_id,
              category: p.product_group.product_category_id,
              name: p.product_group.group_name,
            })),
            "id"
          ),
          "name"
        ),
      ];
    },

    parseSubGroups() {
      if (OFFLOAD.sqliteOffloadProduct) return;

      this.subGroups = [
        ...orderBy(
          uniqBy(
            map(
              this.products,
              (p) =>
                p.product_sub_group_id && {
                  group_id: p.product_group_id,
                  id: p.product_sub_group_id,
                  name: p.product_sub_group_name,
                }
            ).filter((item) => item),
            "id"
          ),
          "name"
        ),
      ];
    },

    parseExistingOrders() {
      this.setActiveOrderId(this.orderId);
      this.orderDetail = this.activeOrder.orderDetail;
      this.orderCustomerName = this.activeOrder.orderCustomerName;

      this.$nextTick(() => {
        this.orderItems = cloneDeep(this.activeOrder.orders);
      });
    },

    onCategoryDropdownChange(categoryId) {
      console.log('Category!', categoryId);

      let category = this.categories.find(cat => cat.id == categoryId);
      this.setActiveCategory(category);
      this.setActiveGroup({ id: '' });
    },

    onGroupDropdownChange(groupId) {
      console.log('Group!', groupId);

      let group = this.filteredGroups.find(grp => grp.id == groupId);
      this.setActiveGroup(group);
    },

    async setActiveCategory(category = null) {
      this.activeCategory = category ? category.id : "";

      // from sqlite
      if (OFFLOAD.sqliteOffloadProduct) {
        this.menuQuery = "";
        this.offloadGroups = [];
        const group = new ProductGroupBridge();
        const id = this.activeCategory !== 0 ? this.activeCategory || null : 0;

        const groups = await group.getGroups(id, this.activeBrandId);
        if (groups.length > 0) {
          this.offloadGroups = [{id: "", name: "ALL"}, ...groups];
        }

        if (category) {
          await this.fetchOffloadProducts();
        }
      }
    },

    async toggleActiveCategory(category) {
      this.activeGroup = "";
      if (this.activeCategory === category.id) {
        this.setActiveCategory({ id: '' });
      } else {
        this.setActiveCategory(category);
      }
    },

    async setActiveGroup(group) {
      this.activeGroup = group.id;
      this.activeSubGroup = "";

      // from sqlite
      if (OFFLOAD.sqliteOffloadProduct) {
        this.menuQuery = "";
        await this.fetchOffloadProducts();
      }
    },

    async toggleActiveGroup(group) {
      if (this.activeGroup === group.id) {
        this.setActiveGroup('')
      } else {
        this.setActiveGroup(group);

        if (group.category) {
          if (this.activeCategory != 0) {
            //if not promos
            this.activeCategory = group.category;
          }
        }
      }      
    },

    toggleActiveSubGroup(subGroup) {
      if (this.activeSubGroup === subGroup.id) {
        this.activeSubGroup = "";
      } else {
        this.activeSubGroup = subGroup.id;
      }
    },

    toggleActiveSortByPopularityInterval(sbpInterval) {
      if (this.activeSortByPopularityInterval === sbpInterval.id) {
        this.activeSortByPopularityInterval = "";
      } else {
        this.activeSortByPopularityInterval = sbpInterval.id;
      }

      this.fetchPopularProducts(sbpInterval.value);
    },

    async openModifiersModal(modifiers = [], productId = null) {
      let filtered = [];
      if (!OFFLOAD.sqliteOffloadModifier) {
        filtered = cloneDeep(modifiers);

        if (this.activeBrandId || this.activeOrder.brandId) {
          filtered = filtered.filter(
            (modifier) =>
              modifier.brand_id == this.activeOrder.brandId ||
              modifier.brand_id == this.activeBrandId
          );
        }

        if (this.activeServiceTypeId || this.activeOrder.serviceTypeId) {
          filtered = filtered.filter(
            (modifier) =>
              modifier.service_type_id == this.activeOrder.serviceTypeId ||
              modifier.service_type_id == this.activeServiceTypeId
          );
        }

        if (this.activeChannelId || this.activeOrder.channelId) {
          filtered = filtered.filter(
            (modifier) =>
              modifier.service_channel_id == this.activeOrder.channelId ||
              modifier.service_channel_id == this.activeChannelId
          );
        }

        if (filtered.length === 0) {
          filtered = uniqBy(modifiers, "modifier_detail_id");
        }
      } else {
        const pmb = new ProductModifierBridge();
        const lb = new LocationBridge();

        const service_type_id = this.activeServiceTypeId || this.activeOrder.serviceTypeId || null;
        const service_channel_id = this.activeChannelId || this.activeOrder.channelId || null;
        const payload = {
          service_type_id,
          service_channel_id,
          product_detail_id: productId,
        };

        const isModifierPricingMigrated = await lb.getColumnValue('is_modifier_pricing_migrated') == 1;
        if (OFFLOAD.sqliteOffloadPOSFE1222 && isModifierPricingMigrated) {
          payload.is_available = 1;
        }

        filtered = await pmb.getProductModifiers(payload);
      }

      this.activeModifiers = filtered;
      this.isModifiersModalOpen = true;
    },

    openOpenItemModal() {
      this.isOpenItemModalOpen = true;
    },

    async addOpenItemProduct(prod) {
      const openGroup = this.openProduct?.product_group;

      const openItem = {
        hasModifier: false,
        product_sub_group_id: null,
        product_name: prod.product_name,
        product_desc: "Open Food",
        image: "images/",
        product_tag: "Open",
        product_group_id: openGroup.id,
        id: null,
        category: "Food",
        tax_data: this.taxData,
        price: prod.product_price,
        product_group: openGroup,
      };

      if (OFFLOAD.sqliteOffloadProduct) {
        openItem.product_category_id = openGroup.product_category_id;
      }

      this.saveProductOrder(openItem);

      this.isOpenItemModalOpen = false;
    },

    addModifiers(modifiers) {
      this.isModifiersModalOpen = false;

      if (this.customizedProductAvailable(this.activeProduct)) {
        this.tempModifier = modifiers;
      } else {
        this.saveProductOrder({
          ...this.activeProduct,
          ...modifiers,
        });

        this.addSecondItem(this.activeProduct);
      }
    },

    saveCustomizedProductSummary(customizedProductSummary) {
      this.isCustomizedProductsModalOpen = false;

      if (this.tempModifier) {
        this.saveProductOrder({
          ...this.activeProduct,
          customizedProductSummary,
          ...this.tempModifier,
        });

        this.tempModifier = null;

        this.addSecondItem(this.activeProduct);
      } else {
        this.saveProductOrder({
          ...this.activeProduct,
          customizedProductSummary,
        });
      }
    },

    areProductSkusEqual(product1, product2) {
      const relevantFields = [
        "id",
        "forcedMods",
        "unforcedMods",
        "product_name",
        "price",
        "customizedProductSummary",
      ];
      return isEqual(
        pick(product1, relevantFields),
        pick(product2, relevantFields)
      );
    },

    async saveProductOrder(product) {
      const existing = findIndex(
        this.orderItems,
        (o) =>
          !o.kot &&
          !o.isVoided &&
          o.serviceTypeId == this.activeServiceTypeId &&
          this.areProductSkusEqual(o.product, product)
      );

      const payload = product;
      if (!payload.pricings) {
        payload.pricings = OFFLOAD.sqliteOffloadProduct
          ? payload.id
            ? await this.fetchOffloadPrices({ product_detail_id: payload.id })
            : []
          : this.pricings.filter((p) => p.product_detail_id == payload.id);
      }
      if (!payload.tax_data) {
        payload.tax_data = cloneDeep(this.taxData);
      }

      if (payload.tax_data) {
        payload.tax_data = cloneDeep(this.taxData);
      }

      if (existing > -1) {
        this.orderItems[existing].quantity++;
      } else {
        this.orderItems.push({
          product: payload,
          quantity: 1,
          activePrice: this.findActivePricing(product),
          activeSc: this.findActiveSc(product),
          activeVat: this.findActiveVat(product),
          request: "",
          isOpenItem: product.product_tag == "Open",
          isCancelled: false,
          productCreatedAt: toIsoString(new Date()),
          serviceTypeId:
            this.serviceTypes.find((i) => i.service_name === this.serviceType)
              ?.id || this.activeServiceTypeId,
          serviceName: this.serviceType,
        });
      }

      this.reapplyBillDiscount();
    },

    updateOrderItems(orderItems) {
      this.orderItems = [...this.activeOrder.orders, ...orderItems];
    },

    findActivePricing(product) {
      if (product.product_tag == "Open") {
        return product.price;
      }

      if (!product.pricings) return 0;

      return (
        product.pricings.find(
          (p) =>
            p.service_type_id == this.activeServiceTypeId &&
            (!this.activeChannelId ||
              p.service_channel_id == this.activeChannelId)
        )?.prod_price ?? 0
      );
    },

    findActiveSc(product) {
      return Object.values(product.tax_data).find(
        (td) =>
          td.service_type == this.serviceType &&
          td.tax_name?.toLowerCase() === "service charge"
      );
    },

    findActiveVat(product) {
      return Object.values(product.tax_data).find(
        (td) => td && td.tax_name === "VAT"
      );
    },

    async reapplyBillDiscount() {
      if (this.activeOrder.billDiscount) {
        await this.$nextTick();
        this.$refs.ordersPanel.applyBillDiscount(this.activeOrder.billDiscount);
      }
    },

        async addOrderItem(product) {
            if(product.isPromo && this.activeOrder.billDiscount) {
                this.$swal.error('Cannot Add promo item in order with bill level discount. Remove bill level discount first.');
                return;
            }

      if (product.isPromo || product.isSecondItem) {
        //re-assigning of item/product name for promo items
        let productName = "";

        if (OFFLOAD.sqliteOffloadProduct) {
          productName = product.product_name;
        } else {
          productName = product.isSecondItem
            ? product.second_items.product_name
            : product.main_item.product_name;
        }

        const itemNumber = product.isSecondItem ? "2" : "1";
        const promoCode = `(${itemNumber}P${product.promo_id})`;

        if (!product.product_name.includes(promoCode)) {
          product.product_name += promoCode;
        }
      }

      if (this.customizedProductAvailable(product) && ENABLE_PARENT_CHILD_CLASSIFICATION) {
        this.activeProduct = product;
        this.isCustomizedProductsModalOpen = true;

        if(product.hasModifier != 1) {
          this.reapplyBillDiscount();
          return;
        }
      }

      if (product.hasModifier == 1) {
        this.activeProduct = product;

        const modifiers = !OFFLOAD.sqliteOffloadModifier
          ? this.modifiers[product.id]
          : [];
        await this.openModifiersModal(modifiers, product.id);

        this.reapplyBillDiscount();
        return;
      }

      this.saveProductOrder(product);
      this.addSecondItem(product);
      this.reapplyBillDiscount();
    },

    async addSecondItem(product) {
      if (product.isPromo) {
        //for second Items
        const pricings = OFFLOAD.sqliteOffloadProduct
          ? await this.fetchOffloadPrices({
            product_detail_id: product.second_item_id,
          })
          : this.pricings.filter(
            (p) => p.product_detail_id == product.second_item_id
          );

        let secondItem = {};
        if (OFFLOAD.sqliteOffloadProduct) {
          const params = cloneDeep(this.productParams);
          params.id = product.second_item_id;
          const { data } = await this.offload.getProducts(params);
          if (data.length > 0) {
            const {
              promo_name,
              promo_description,
              promo_amount,
              promo_percentage,
              promo_type,
              second_item_id,
              isPromo,
              promo_id,
              ...secondItemPayload
            } = data[0];
            secondItem = secondItemPayload;
          }
        } else {
          secondItem = this.products.find(
            (p) => p.id == product.second_item_id
          );
        }

        const secondProduct = {
          second_items: product?.second_items,
          promo_id: product.promo_id,
          promo_name: product.promo_name,
          promo_description: product.promo_description,
          promo_type: product.promo_type,
          promo_percentage: product.promo_percentage,
          promo_amount: product.promo_amount,
          id: product.second_item_id,
          pricings,
          isSecondItem: true,
          isPromo: false,
          ...secondItem,
        };

        this.addOrderItem(secondProduct);
      }
    },

    clearOrders() {
      if (!this.orderId) {
        this.orderItems = [];
        this.separatorPositions = [];
      } else {
        this.orderItems = this.orderItems.filter((o) => o.kot);
        this.separatorPositions = [
          ...(this.activeOrder.separatorPositions ?? []),
        ];
      }
      this.reapplyBillDiscount();
    },

    saveItemRequest(itemRequest) {
      this.orderItems[itemRequest.targetItemIndex].request =
        itemRequest.request;
    },

    saveOrderDetail(details) {
      this.orderDetail = details.orderDetail;
      this.orderCustomerName = details.orderCustomerName;
    },

    async getLatestKot() {
      if (!(await checkCloudConnection())) return null;
      try {
        const response = await getLatestSeries();
        return response.data.kot ? response.data.kot + 1 : null;
      } catch (e) {
        console.log(e);
      }
    },

    async saveOrders(data) {
      const tdb = new ServiceTableDetailBridge();
      const hasNewItems = data.lineItems.some((o) => !o._id);
      if (!hasNewItems) {
        if (this.orderId && data.separatorPositions) {
          this.separatorPositions = data.separatorPositions;
          this.updateOrder({
            orderId: this.orderId,
            order: {
              separatorPositions: data.separatorPositions,
            },
          });
        }

        this.postOrderRedirect(this.orderId || "");
        return;
      }

      const hasNegativeQuantity = this.orderItems.some((o) => o.quantity < 0);
      if (hasNegativeQuantity) {
        this.$swal.warning("Negative quantity not allowed");
        return;
      }

      window.__showLoader();
      this.incrementOfflineTransactionCount();

      let newKot = await seriesService.getAndIncrementKotNum() + 1;

      let newOrder = {};
      const kots = this.orderId ? [...this.activeOrder.kots, newKot] : [newKot];

      const kotBreakdowns = data.parsedAmounts.reduce((acc, amount) => {
        if (!amount.kotNum) amount.kotNum = newKot;
        if (!kots.includes(amount.kotNum)) return acc;

        const kIndex = acc.findIndex((a) => a.kotNum == amount.kotNum);
        if (kIndex >= 0) {
          acc[kIndex].vat += amount.vat;
          acc[kIndex].serviceCharge += amount.serviceCharge;
          acc[kIndex].net += amount.net;
          acc[kIndex].total += amount.total;
        } else {
          acc.push({
            kotNum: amount.kotNum,
            vat: amount.vat,
            serviceCharge: amount.serviceCharge,
            net: amount.net,
            total: amount.total,
          });
        }

        return acc;
      }, []);

      const pax = this.$can(this.PERMISSIONS.PAX_DISABLED) ? 1 : this.pax;
      if (this.orderId) {
        const oldOrder = this.orders.find((o) => o && o._id == this.orderId);
        newOrder = {
          ...oldOrder,
          orders: this.orderItems.map((o, i) => ({
            ...o,
            kot: o.kot || newKot,
            totals: data.parsedAmounts[i],
          })),
          kots,
          orderDetail: this.orderDetail,
          orderCustomerName: this.orderCustomerName,
          kotBreakdowns,
          totals: data.grandTotals,
          pax,
          transactionCreatedAtByKot: {
            ...oldOrder.transactionCreatedAtByKot,
            [newKot]: toIsoString(new Date()),
          },
        };
      } else {
        newOrder = {
          _id: new Date().getTime(),
          pax,
          locationId: this.locationId,
          orders: this.orderItems.map((o, i) => ({
            ...o,
            kot: newKot,
            totals: data.parsedAmounts[i],
          })),
          kots,
          orderDetail: this.orderDetail,
          orderCustomerName: this.orderCustomerName,
          isSettled: false,
          serviceType: this.serviceType,
          channelName: this.channelName,
          tableId: this.tableId,
          tableName: data.tableName,
          brandId: this.activeBrandId,
          serviceTypeId: this.activeServiceTypeId,
          serviceName: this.activeOrder.serviceType,
          channelTypeId: this.activeChannelId,
          kotBreakdowns,
          totals: data.grandTotals,
          occupiedAt: new Date().getTime(),
          transactionCreatedAtByKot: {
            [newKot]: toIsoString(new Date()),
          },
          originTerminalId: this.terminalId,
          originShiftTable: cloneDeep(this.currentShift),
        };

        if (OFFLOAD.sqliteOffloadTableDetail && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM) {
          await tdb.update({
            table_id: this.tableId,
            receipt_local_id: String(newOrder._id)
          });
        }
      }

      if (SAVE_LINE_ITEM_SEPARATOR_POSITIONS) {
        newOrder.separatorPositions = data.separatorPositions;
      }

      newOrder.isNonVat = IS_NON_VAT;

      //get product categories and put them in an array
      let orderProductCategories = uniq(
        map(newOrder.orders, (o) =>
          get(o, "product.product_group.product_category_id")
        )
      );

      //generate kot printing html
      let kotPrintObj = await this.getKOTPrintObj(
        newOrder,
        newKot,
        orderProductCategories,
        data.adjustedSeparatorPositions
      );

      newOrder.orderIdentifier = kotPrintObj.orderIdentifier;

      this.kotHTMLContent = kotPrintObj.printTemplate;

      let printData = {
        print_type: "KOT",
        html: this.kotHTMLContent,
        orders: newOrder.orders.map((o) => o.product),
        kotStrArr: kotPrintObj.kotStrArr,
      };

      let printConfig = {
        location_id: this.receiptDetails.location_id,
        kotItemObject: kotPrintObj.kotItemObject,
      };
      await print(printData, printConfig);

      if (!this.orderId && OFFLOAD.sqliteOffloadReceipt) {
        newOrder.createdAt = generateDateTime();
      }

      if (this.orderId) {
        this.updateOrder({ orderId: this.orderId, order: newOrder });
      } else {
        this.addOrder(newOrder);
      }

      if (OFFLOAD.sqliteOffloadReceipt) {
        const orderId = this.orderId ? this.orderId : newOrder._id;

        this.sqliteUpsertReceipt({
          orderId,
          action: OFFLOAD_RECEIPT_ACTION.ADD_ORDER
        })
      }

      window.__hideLoader();
      this.setActiveOrderId(newOrder._id);
      this.postOrderRedirect(newOrder._id);
    },

    postOrderRedirect(orderId) {
      const serviceTypesWithTables = this.serviceTypes
        .filter((item) => item.has_area_table)
        .map((item) => item.service_name);
      const serviceType = this.activeOrder.serviceType || this.serviceType;
      const serviceAreaId =
        this.serviceTypes.find((i) => i.service_name === serviceType)?.id ||
        this.activeServiceTypeId;

      if (serviceType === "Delivery" && this.activeOrder.isOnlineDelivery) {
        this.$router.push({
          name: "delivery",
          params: {
            channelName: this.activeOrder.channelName,
            updatedOrderId: orderId,
          },
        });

        return;
      }

      if (serviceTypesWithTables.includes(serviceType)) {
        this.$router.push({
          name: "tables",
          query: { order_id: orderId },
          params: { serviceType, serviceAreaId },
        });
      } else {
        this.$router.push({ name: "pending", query: { order_id: orderId } });
      }
    },

    savePax(pax) {
      this.updatePax(pax);
      this.isPaxModalOpen = false;
    },

    updatePax(pax) {
      this.pax = pax;
    },

    applyDiscount(discountData) {
      const { targetItemIndex, discount } = discountData;
      this.orderItems[targetItemIndex].discount = discount;
    },

    async getKOTPrintObj(
      orderDetail,
      lastKOT,
      orderProductCategories = [],
      originalSeparatorPositions = []
    ) {
      let separatorPositions = [...originalSeparatorPositions];
      let receiptDetails = this.receiptDetails;
      let printTemplate = kotHtml;
      const self = this;

      //initialize kotItemObject. this is for category based printing
      let kotItemObject = {};
      orderProductCategories.forEach(function (value) {
        kotItemObject[value] = "";
      });

      //generate kot items
      const orders = await kotGroupOrdersByServiceType(orderDetail);
      let kotItemsStr = "";
      let kotStrArr = [];
      for (let serviceType in orders) {
        const serviceHeaderString = `<span class="fs-17 kot-service-header">***${serviceType.toUpperCase()}***</span><br/><br/>`;

        const lineItemSeparatorString = `<span class="fs-17">${"-".repeat(
          LINE_ITEM_SEPARATOR_CHAR_COUNT
        )}</span><br/><br/>`;
        kotItemsStr += serviceHeaderString;
        kotStrArr.push({
          type: "service-type-header",
          string: serviceHeaderString,
        });
        let offset = 0;
        let index = 0;

        orders[serviceType].map(function (order) {
          if (order && order.kot == lastKOT) {
            let offsetIndex = index + offset;
            let separatorString = "";
            while (
              serviceType == "Dine-in" &&
              separatorPositions.includes(offsetIndex)
            ) {
              separatorString += lineItemSeparatorString;
              separatorPositions.splice(
                separatorPositions.indexOf(index + offset),
                1
              );
              offset++;
              offsetIndex++;
            }
            const returnString = `<span class="fs-17 ${order?.isVoided ? "kot-voided-item" : "kot-item"
              }">${order.quantity}  ${order.product.product_name}</span><br>
              ${order.product.hasModifier == 1
                ? `${order.product.forcedMods
                  .map(function (fmod) {
                    return `<span class="mods ${order?.isVoided
                      ? "kot-voided-item"
                      : "kot-item"
                      }">&nbsp;&nbsp;${fmod.quantity} ${fmod.modifier_name}</span><br>`;
                  })
                  .join(" ")}${order.product.unforcedMods
                    .map(function (ufmod) {
                      return `<span class="mods ${order?.isVoided
                        ? "kot-voided-item"
                        : "kot-item"
                        }">&nbsp;&nbsp;${ufmod.quantity} ${ufmod.modifier_name}</span><br>`;
                    })
                    .join(" ")}`
                : ""
              }
              ${self.appendBundledProducts(
                order
              )} ${self.appendCustomizedProducts(order)}
              ${order.request != undefined &&
                order.request != null &&
                order.request.length > 0
                ? `<span class="reqs">&nbsp;&nbsp;${order.request}</span><br>`
                : ""
              }`;

            let categoryId = order.product.product_group?.product_category_id;
            if (!kotItemObject[categoryId]) {
              kotItemObject[categoryId] += serviceHeaderString;
              const previousSeparators =
                orderDetail.separatorPositions?.filter((pos) => pos < index) ??
                [];
              if (previousSeparators.length) {
                kotItemObject[categoryId] += lineItemSeparatorString.repeat(
                  previousSeparators.length
                );
              }
            }

            kotItemObject[categoryId] += separatorString + returnString;
            kotItemsStr += separatorString + returnString;
            if (separatorString)
              kotStrArr.push({
                type: "separator",
                string: lineItemSeparatorString,
              });
            kotStrArr.push({
              type: "line-item",
              string: returnString,
              categoryId,
            });
            index++;
          }
        });

        const remainingSeparators = separatorPositions.filter(
          (pos) => pos >= index
        );
        if (serviceType == "Dine-in" && remainingSeparators.length) {
          kotItemsStr += lineItemSeparatorString;
          kotStrArr.push({
            type: "separator",
            string: lineItemSeparatorString,
          });
        }
      }

      //assign the kotItemStr to default key in kotItemObject
      kotItemObject["default"] = kotItemsStr;

      let service_type = this.serviceType == "Delivery" ? "Delivery" : "Table";
      let identifier =
        orderDetail.tableName == undefined
          ? orderDetail.tableId
          : orderDetail.tableName;

      let identifierString = "";
      let timestamp = new Date().getTime();
      switch (receiptDetails.account_type_id) {
        case 4:
          this.addOrUpdateDataContainer({
            key: "qsr_" + orderDetail._id,
            value: timestamp,
            mode: "add",
          });
          identifierString = this.activeBrandId + "-" + timestamp;
          break;
        case 2:
          if (this.serviceType == "Delivery") {
            this.addOrUpdateDataContainer({
              key: "bm_del_" + orderDetail._id,
              value: timestamp,
              mode: "add",
            });

            identifierString = this.activeBrandId + "-" + timestamp;
          } else {
            identifierString =
              this.serviceType.replace("-", " ") + "-" + identifier;
          }
          break;
        default:
          identifierString =
            this.serviceType.replace("-", " ") + "-" + identifier;
      }

      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let time =
        ("0" + today.getHours()).substr(-2) +
        ":" +
        ("0" + today.getMinutes()).substr(-2) +
        ":" +
        ("0" + today.getSeconds()).substr(-2);
      let dateTime = date + " " + time;
      const orderIdentifier = service_type + " : " + identifierString
      let toReplace = {
        __REPRINT__: "",
        __ORDERIDENTIFIER__: orderIdentifier,
        __DATETIME__: dateTime,
        __ORDERNUM__: lastKOT,
        __CASHIERNAME__: receiptDetails.cashier_name,
        __PAXCOUNT__: orderDetail.pax,
        __BRANDNAME__: receiptDetails.brand_name,
        __LOCATIONNAME__: receiptDetails.location,
        __MOREDETAILS__: orderDetail.orderDetail
          ? "Reference: " + orderDetail.orderDetail
          : "",
        __SERVICETYPE__: "",
        __KOTVOID__: "",
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
      printTemplate = printTemplate.replace(RE, function (matched) {
        return toReplace[matched];
      });

      console.log(
        convert(printTemplate.replace("__KOTITEMS__", kotItemsStr), {
          wordwrap: 130,
          preserveNewlines: true,
        })
      );

      return { printTemplate, kotItemObject, kotStrArr, orderIdentifier };
    },

    appendBundledProducts(order) {
      if (!order.product?.is_bundle) {
        return "";
      }

      return order.product?.bundled_products
        .map(
          (product) =>
            `<span class="mods">&nbsp;&nbsp;${product.quantity} ${product.product_detail.product_name
            } ${product?.is_free ? "FREE" : ""}</span><br>`
        )
        .join(" ");
    },

    appendCustomizedProducts(order) {
      if (!order.product?.is_customize) {
        return "";
      }

      return order.product?.customizedProductSummary
        .map((customizedProduct) =>
          customizedProduct.choices
            .filter((choice) => choice.selectedCount)
            .map(
              (choice) =>
                `<span class="mods">&nbsp;&nbsp;${choice.selectedCount} ${choice.name}</span><br>`
            )
            .join(" ")
        )
        .join(" ");
    },

    customizedProductAvailable(product) {
      if (!product?.is_customize) return false;

      return product?.customized_products?.some((customizedProduct) =>
        customizedProduct?.service_types?.some(
          (serviceType) => serviceType.service_name === this.serviceType
        )
      );
    },

    isProductAvailable(product) {
      const { serviceType } = this.$router.currentRoute.value.params;
      const activeChannelId =
        this.activeChannelId || this.activeOrder.channelId || "";

      let available = true;
      if (
        product?.availability?.hasOwnProperty(
          `${serviceType}_${activeChannelId}`
        )
      ) {
        available =
          product.availability?.[`${serviceType}_${activeChannelId}`] == 1;
      }

      return product.available == 1 && available;
    },

    handleSearchMenu() {
      if (!OFFLOAD.sqliteOffloadProduct) return;

      clearTimeout(this.debouncedSearch);
      this.debouncedSearch = setTimeout(() => {
        this.fetchOffloadProducts();
      }, 500);
    },

    async fetchOffloadProducts(page = 1) {
      this.offloadProductsLoading = true;
      this.offloadProducts = [];
      this.productParams.page = page;
      const params = cloneDeep(this.productParams);
      params.brandId = this.activeBrandId || this.activeOrder.brandId;
      params.categoryId = this.activeCategory !== 0 ? this.activeCategory || null : 0;
      params.groupId = this.activeGroup || null;
      //params.subGroupId = this.activeSubGroup || null;
      params.productName = this.menuQuery || null;

      const { data, totalItems } = await this.offload.getProducts(params);
      this.offloadProducts = await Promise.all(
        data.map(async (datum) => {
          const price = new PriceBridge();
          let prices = await price.getPrices({ product_detail_id: datum.id });
          prices = prices.sort((a,b) => a.id < b.id ? 1 : -1); // sort by ID descending

          datum.availability = {};
          prices.forEach((item) => {
            const key = `${item.service_type_name}_${item.service_channel_id || ""
              }`;
            datum.availability[key] = item.is_available;
          });
          datum.bundled_products = JSON.parse(datum.bundled_products);
          datum.customized_products = JSON.parse(datum.customized_products);

          return datum;
        })
      );

      this.openProduct = await this.offload.getOpenItem();

      this.totalItems = totalItems;
      this.offloadProductsLoading = false;
    },

    async fetchOffloadPrices(params) {
      const price = new PriceBridge();
      const prices = await price.getPrices(params);

      // sort by ID descending
      return prices.sort((a,b) => a.id < b.id ? 1 : -1);
    },

    async triggerManualFetch() {
      this.isFetching = true;
      if (OFFLOAD.sqliteOffloadProduct) {
        await this.checkAndSyncSqliteOffloadTables();
        await this.getProductCategoriesAndGroups();
        await this.fetchOffloadProducts();
        this.isFetching = false;
        return;
      }

      await this.fetchPricings(true);
      await this.fetchAllProducts(true);
      await this.fetchAllModifiers(true);
    },

    async getProductCategoriesAndGroups() {
      this.offloadProductsLoading = true;
      const pcb = new ProductCategoryBridge();

      const categories = OFFLOAD.sqliteOffloadMA58
        ? await pcb.getProductCategories({ brand_id: this.activeBrandId })
        : this.initialData?.categories || [];

      this.categories = [
        { id: "", name: "ALL" },
        ...categories,
      ];

      await this.setActiveCategory();
    }

  },

  watch: {
    isOnline() {
      if (this.noNetwork) {
        this.$swal.warning(
          "You are not connected to network. Placing order without network might cause transaction lost."
        );
        return;
      }
    }, 
  },
};
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .products-container {
    padding: 0 0 40px 0;
  }
  .table-details-page>div {
  padding-bottom: 20% !important;
  margin: 0;
  }
}
.table-details-page>div {
  max-height: 100vh;
  overflow: auto;
}

.flex-container {
  max-width: 100vw;
  gap: 16px;
}

.disabled-container {
  opacity: 0.4;
  pointer-events: none;
}

.flex-container.btn-group {
  gap: 0px;
}

.flex-spacer {
  visibility: hidden;
  height: 0 !important;
}

.form-control.has-query {
  box-shadow: 0px 0px 16px 4px rgba(137, 232, 216, 1) !important;
  border: 1px solid #61e8d8;
}

@media (max-width: 992px) {
  #mosaic-pos-app .just-code-basic .m-card.m-product {
    width: calc(50% - 16px);
  }
}
#mosaic-pos-app label.dropdown-label {
    font-weight: 500;
}
</style>
