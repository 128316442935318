<template>
    <div class="p-lg-4 landing-page">
        <div>
        <label v-if="brands.length > 1">Brand</label>
        <div v-if="brands.length > 1" class="btn-group mb-4 flex-container d-flex flex-wrap" role="group">
            <button
                v-for="brand in brands"
                :key="`brand-${brand.id}`"
                type="button"
                class="btn btn-lg"
                :class="{
                    active: brand.id === currentBrandId,
                    'bg-primary': brand.id === currentBrandId,
                    'bg-light': brand.id !== currentBrandId,
                }"
                @click="setActiveBrandId(brand.id)"
            >
                {{ brand.brand_name }}
            </button>
        </div>
        <hr v-if="brands.length > 1" />

        <div class="welcome-title text-left d-lg-none">
        <h4>Welcome {{ accountUser }}!</h4>
        <p>Feel free to choose the service that suits you best!</p>
        </div>

        <label class="service-title">Service</label>
        <div class="d-flex services-container services-page">
        <m-card
            v-for="service in serviceTypes"
            :key="`service-${service.id}`"
            :label="service.service_name"
            clickable
            ratio="16:9"
            :active="service.id === activeServiceTypeId"
            @click="routeToService(service)"
        >
            <template #default>
            <div class="home-nav-icon" v-html="service.icon" />
            </template>
        </m-card>
        </div>

        <hr v-if="isChannelsVisible" />
        <label v-if="isChannelsVisible">Channels</label>
        <div
            v-if="isChannelsVisible"
            class="channels-container"
        >
            <m-card
                v-for="channel in channels.filter(channel => !channel.is_online_order)"
                class=""
                ratio="4:3"
                clickable
                :key="`channel-${channel.id}`"
                :label="channel.channel_name"
                @click="orderByChannel(channel)"
                :class="channel.channel_name"
            >
                <template #default>
                    <img
                        class="channel-image"
                        :src="`/images/${channel.channel_name}.png`"
                        alt="channel-image"
                    />
                </template>
            </m-card>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
import { get, uniq, isEmpty } from 'lodash';
import {
  getOwnBrands,
  getChannels,
  getBillsDenomination,
  getShiftTable,
  getPosDate,
} from '@/spa/services/cashier-service';

import { mapMutations, mapState } from 'vuex';

import MCard from '@/spa/components/common/MCard';
import {
  OFFLOAD,
  IS_ROBINSON_ACCREDITED,
  ACTIVE_USER,
  ACTIVE_POS_DATE,
} from '@/spa/constants';
import {sqliteOffloadGetStorageValue} from "@/mobile_bridge/offload/receipt-model";

export default {
    name: 'LandingPage',

    components: {
        MCard,
    },

    data() {
        return {
            accountUser: tempAccountCurrentUsername,
            brands: [],
            isChannelsVisible: false,
            channels: [],
            tax_data: [],
            modifiers: {},
            itemDiscounts: {},
            activePosDate: {},
            previousPosDate: '',
        };
    },

    computed: {
        ...mapState([
            'activeBrandId',
            'activeServiceTypeId',
            'activeChannelId',
            "lastBillNum",
            "lastReceiptNum",
            "lastVoidBillNum",
            "lastVoidReceiptNum",
            'orders',
        ]),

        ...mapState('user', ['terminalId', 'username']),
        ...mapState('modals', ['isCashFloatModalOpen']),
        ...mapState('settings', [
            'cashFloat',
            'shiftChangeRecord',
            'shiftTable',
            'currentShift',
            'previousVoidNum',
            'hasCashFloat',
            'posDate',
            'shiftTable',
            'currentShift'
        ]),
        ...mapState('sqlite', ['initialData']),

        currentBrandId () {
            return this.activeBrandId || this.brands[0].id
        },

        serviceChannels() {
          return this.channels.filter(channel => !channel.is_online_order);
        },

        serviceTypes() {
            const activeBrand = this.brands.find(
                brand => brand.id === this.currentBrandId
            );

            this.setServiceTypes(activeBrand ? activeBrand.service_types : []);
            return activeBrand ? activeBrand.service_types : [];
        },

        serviceTypePathMap() {
            return this.serviceTypes.reduce((acc, curr) => {
                if (curr.has_area_table) {
                    acc[curr.id] = {
                        name: 'tables',
                        params: {
                            serviceType: curr.service_name,
                            serviceAreaId: curr.id
                        }
                    };
                }
                return acc;
            }, {});
        },

        serviceTypeIdsWithChannels() {
            return uniq(this.serviceChannels.filter(c => !c.is_online_order).map(c => c.service_type_id));
        },
    },

    async beforeMount() {
        window.__showLoader();
        this.setActiveBrandId('');
        this.setActiveServiceTypeId('');
        this.setActiveChannelId('');

        this.fetchOwnBrands()
            .then(() => this.selectFirstBrand());

        await this.fetchChannels();

        window.__hideLoader();
    },

    async mounted() {
        try {
            await Promise.all([
                this.fetchPosDate(),
                this.fetchShiftTable(),
            ]);

            if (this.checkIfStoreCanProceed()) {
                this.forceCashFund();
            }
        } catch (e) {
            console.error("Error: Landing.vue mounted", e);
        }
    },

    methods: {
        ...mapMutations([
            'setActiveBrandId',
            'setActiveServiceTypeId',
            'setActiveChannelId',
            'resetLastKot',
            'setLastBillNum',
            'setLastReceiptNum',
            'setLastVoidBillNum',
            'setLastVoidReceiptNum',
            'addOrder',
            'clearOrders',
        ]),

        ...mapMutations('settings', [
            'setBillsDenomination',
            'setShiftTable',
            'setPosDate',
            'setBeginningBalance',
            'setBeginningVoid',
            'setBeginningVoidAmount',
            'setPreviousVoidNum',
            'setPreviousReceiptNum',
            'setCurrentShift',
            'incrementCurrentShiftNum',
            'setServiceTypes',
        ]),

        ...mapMutations('modals', ['setIsCashFloatModalOpen']),

        forceCashFund() {
            if (!this.hasCashFloat && this.$route.params.forceCashFund == 'true') {
                this.setIsCashFloatModalOpen(true);
            }
        },

        async fetchOwnBrands() {
            if (OFFLOAD.sqliteOffloadProduct) {
                const brand = new BrandBridge();
                this.brands = await brand.getAll();
                return;
            }

            try {
                const response = await getOwnBrands();
                this.brands = response?.data || [];
            } catch (e) {
                console.error("Error: Landing.vue fetchOwnBrands", e);
            }
        },

        async fetchShiftTable() {
            if (OFFLOAD.sqliteOffloadReceipt && !isEmpty(this.shiftTable) && !isEmpty(this.currentShift)) {
                return;
            }

            try {
                const posDateBridge = new PosDateBridge();
                const activeUser = await sqliteOffloadGetStorageValue(ACTIVE_USER);
                const activePosDate = await posDateBridge.getPosDate(window.locationId);
                let data = {};

                if (!isEmpty(activeUser?.currentShift)) {
                  data = activeUser.currentShift;
                  data.business_date = activePosDate;
                } else {
                  const response = await getShiftTable();
                  data = response.data;
                  data.business_date = activePosDate ?? data.business_date;
                }

                this.setShiftTable({
                    ...data
                });

                this.setCurrentShift({
                    shift_start: data.shift_start,
                    pos_date: data.business_date,
                    shift: data.shift,
                    user_name: data.user_name,
                    user_id: data.user_id,
                });
            } catch (e) {
                console.error("Error: Landing.vue fetchShiftTable", e);
            }
        },

        async fetchPosDate() {
            const posDateBridge = new PosDateBridge();
            const activePosDate = await posDateBridge.getPosDate(window.locationId);
            if (!isEmpty(activePosDate)) {
                this.setPosDate(activePosDate);
                this.activePosDate = activePosDate;
                sessionStorage.setItem('posDate', activePosDate);

                return;
            }

            try {
                const response = await getPosDate();
                const data = response.data;
                this.setPosDate(data.pos_date);
                this.activePosDate = data.pos_date;
                sessionStorage.setItem('posDate', data.pos_date);
            } catch (e) {
                console.error("Error: Landing.vue fetchPosDate", e);
            }
        },

        async fetchChannels() {
            if (OFFLOAD.sqliteOffloadProduct) {
                this.channels = this.initialData?.channels || [];

                return;
            }

            try {
                const response = await getChannels();
                this.channels = response?.data || [];
            } catch (e) {
                console.error("Error: Landing.vue fetchChannels", e);
            }
        },

        selectFirstBrand() {
            if (this.brands.length > 0) {
                this.setActiveBrandId(this.brands[0].id);
            }
        },

        showChannels(serviceId) {
            this.setActiveServiceTypeId(serviceId);
            this.isChannelsVisible = true;
        },

        routeToService(service) {
            if (this.serviceTypeIdsWithChannels.includes(service.id)) {
                this.showChannels(service.id);
                return;
            }

            this.setActiveServiceTypeId(service.id);

            const route = get(
                this.serviceTypePathMap,
                service.id,
                { name: 'show-products', params: { serviceType: service.service_name, channelName: service.id } },
            );

            this.$router.push(route);
        },

        orderByChannel(channel) {
            this.setActiveChannelId(channel.id);

            const activeBrand = this.brands.find(
                brand => brand.id === this.currentBrandId
            );
            const activeService = activeBrand.service_types.find(
                service => service.id === this.activeServiceTypeId
            );

            this.$router.push({
                name: 'show-products',
                params: {
                    serviceType: activeService.service_name,
                    channelName: channel.channel_name,
                },
            });
        },

        //For Robinson's accredited locations - Strict Dayend
        checkIfStoreCanProceed() {
            if(!IS_ROBINSON_ACCREDITED) {
                return true;
            }

            const isMandatoryDayEnd = sessionStorage.getItem('is_mandatory_day_end');
            const isPOSDateBehind = moment(this.activePosDate).isBefore(moment());
            let posDate = moment(sessionStorage.getItem('posDate'), 'YYYY-MM-DD');
            const maxOperatingHours = posDate.add(1, 'day').add(4, 'hours');

            //if true, will redirect to DayEnd page
            if(
                isMandatoryDayEnd == '1' &&
                isPOSDateBehind &&
                (
                    !window.tempPosAndCalendarDateMatches &&
                    maxOperatingHours.isBefore(moment())
                )
            ) {
                Swal.fire({
                    title: "Previous' day's EOD was not performed!",
                    text: "Please file a Day End report before creating a new order.",
                    confirmButtonText: "Day End",
                    allowOutsideClick: false
                }).then( async (result) => {
                    if(result.isConfirmed) {
                        this.$router.push({ name: "day-end-settings"});
                    }
                });
                return false;
            }
            return true;
        },
    },
};
</script>

<style scoped>

@media screen and (max-width: 992px) {
    .services-page {
        min-height: 100vh !important;
    }
    .landing-page>div {
    padding: 30px 0;
    max-height: 100vh;
    overflow: auto;
    }
}

.channels-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 0 1rem;
    padding-bottom: 192px;
}

@media (min-width: 992px) {
    .channels-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 192px;
    }
}
.services-container {
    gap: 16px;
}

.landing-page label {
    font-size: 1.4rem;
    font-weight: 800;
}
</style>

<style>
.landing-page .home-nav-icon > svg {
    height: 48px;
    width: auto;
}

.channel-image {
    width: 80px;
    height: 100%;
    object-fit: contain;
    height: auto;
}

.foodpanda .m-card__label {
    text-transform: lowercase !important;
}
</style>