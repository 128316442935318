import * as printer from '@/printer2';
import axios from '@/spa/plugins/axios';
import { uniq, get } from 'lodash';
import { convert } from 'html-to-text';
import { Logger } from "@/spa/helpers/Logger";
import {OFFLOAD} from "@/spa/constants";

const serviceTypeHeaderPattern = /<span class="fs-17 kot-service-header">\*\*\*[a-z-]+\*\*\*<\/span><br\/><br\/>/ig;

export const location_printers = (data) => {
    return axios.post(route('store.printer-ips-idb'), data);
};

export const getPrinterIps = () => {
    if (OFFLOAD.sqliteOffloadPrinter) {
        const printerDetailBridge = new PrinterDetailBridge();
        return printerDetailBridge.getAll();
    }

    return axios.get(route('get.own-printer-ips'), { cache: { maxAge: 0 } });
};

export const getPrinterData = async() => {
    //get location printers
    let printers = await getPrinterIps();

    let printerData = printers.data;

    return printerData;
};

export const test_print = (printerData) => {
    printer.epson_test_print(printerData);
}

export const print = async(data, config, itemString = null, qrcode = null) => {
    let printerData, locationPrinters, printerDetails, accountLogo, storage;

    if (window.MosaicPosAppSupportedFeatures?.imagePrinting && window.MosaicPosAppSupportedFeatures?.nativeStorage) {
        storage = new ScopedNativeStorage(window.locationId);
        accountLogo = await storage.get('accountLogo');
    }
    
    //get location printers
    if (is_local_printer_config_available() && is_using_local_printer_config()) {
        printerDetails = await db.printer_details.toArray();
        locationPrinters = await db.location_printers.toArray();
    } else {
        printerData = get(data, 'printer_data', await getPrinterData());
        locationPrinters = printerData.location_printers; 
        printerDetails = printerData.printer_details;
    }

    if (window.MosaicPosAppSupportedFeatures?.bluetoothPrinting) {
        const storage = new ScopedNativeStorage(window.locationId);
        const bluetoothConfigJson = await storage.get('BLUETOOTH_SETTINGS');

        if (bluetoothConfigJson) {
            const bluetoothConfig = JSON.parse(bluetoothConfigJson);

            if (bluetoothConfig?.enabled && bluetoothConfig.printingSystemIntegration) {
                const btprinter = bluetoothConfig.printingSystemIntegration;
        
                printerDetails = [ ...printerDetails, ...btprinter.printer_details ];
                locationPrinters = [ ...locationPrinters, ...btprinter.location_printers ];
            }
        }
    }

    Logger.info(`Printer type: ${data.print_type}`);

    switch(data.print_type) {
        case 'KOT':
            printerDetails = printerDetails.filter(p => p.can_print_kot == 1);
            break;
        case 'PlainText':
        case 'CashFloat':
        case 'Receipt':
        case 'Void': {
            const isBillPrintout = data.mode == 'bill' || data.mode == 'void_bill';
            printerDetails = printerDetails.filter(p => isBillPrintout ? p.can_print_bill == 1 : p.can_print_receipt == 1);
            break;
        }
        case 'QRCode':
        case 'QRPayment':
            printerDetails = printerDetails.filter(p => p.can_print_qr_code);
            break;
        case 'Report':
        case 'EOD':
        case 'XZRead_Custom_Sections':
            printerDetails = printerDetails.filter(p => p.can_print_z_report == 1);
            break;
        default:
            break;
    }

    printerDetails.forEach(detail => {
        let printString = data.html;
        let categoryIdArr = [];
        // Local printer config stores model data in the printer detail object
        const printer_model = detail.printer_model || printerData.printer_models.find(pm => pm.id == detail.printer_model_id);
        const { printer_method } = printer_model;

        if (printer_method == 'IMIN') {
            switch (data.print_type) {
                case 'Receipt':
                    // EPOS-1137
                    printString = transformReceiptPrintStringForIMIN(printString);
                    break;
                case 'Report':
                    // EPOS-1260
                    printString = transformReportPrintStringForIMIN(printString);
                default:
                    break;
            }
        }

        if(data.print_type == 'KOT') {
            //get location printer product categories
            categoryIdArr = uniq(locationPrinters.filter(lp => lp.printer_detail_id == detail.id).map(p => p.product_category_id));
            let kotStr = '';
            const defaultKotStr = config.kotItemObject['default'];
            let serviceTypeHeader = '';
            const serviceTypeHeaderMatch = defaultKotStr.match(serviceTypeHeaderPattern);
            if (serviceTypeHeaderMatch) serviceTypeHeader = serviceTypeHeaderMatch[0];

            if (!categoryIdArr.includes(null) && !data.kotStrArr) {
                categoryIdArr.forEach(function(value){
                    let appendString = get(config.kotItemObject, value, '');
                    if (serviceTypeHeaderPattern.test(kotStr)) {
                        appendString = appendString.replace(serviceTypeHeaderPattern, '');
                    }
                    kotStr = kotStr.concat(appendString);
                    if (!kotStr) {
                        return;
                    }

                    if (!kotStr.includes('kot-service-header')){
                        kotStr = kotStr.concat(serviceTypeHeader + '\n');
                    }
                });
            } else if (!categoryIdArr.includes(null) && data.kotStrArr) {
                const filtered = data.kotStrArr.filter(ks => categoryIdArr.includes(ks.categoryId) || ks.type !== 'line-item');
                const filteredWithNoRepeatingSeparators = filtered.filter((ks, index) => {
                    return index === 0
                        || ks.type !== 'separator'
                        || ks.string !== filtered[index - 1].string;
                });

                const hasLineItemsLeft = filteredWithNoRepeatingSeparators.some(ks => ks.type === 'line-item');
                if (hasLineItemsLeft) {
                    kotStr = filteredWithNoRepeatingSeparators.map(ks => ks.string).join('');
                } else {
                    kotStr = '';
                }
            } else {
                kotStr = config.kotItemObject['default'];
            }

            if(!kotStr) {
                return;
            }

            printString = data.html.replace("__KOTITEMS__", kotStr);
            if (window.DEBUG_PRINTERS) {   
                console.log(
                    `Printer: ${detail.id} - ${detail.printer_location}`,
                    convert(printString, { wordwrap: 130, preserveNewlines: true }),
                );
            }

            for (let i = 1; i <= detail.kot_print_count; i++) {
                printer.print(printer_model, detail.ip_address, data.print_type, printString, detail.paper_width, itemString, qrcode);
            }
        } else {
            // Local printer config stores model data in the printer detail object
            let printer_model = detail.printer_model || printerData.printer_models.find(pm => pm.id == detail.printer_model_id);
            printer.print({ ...printer_model, top_margin: detail.top_margin }, detail.ip_address, data.print_type, printString, detail.paper_width, itemString, qrcode, accountLogo);
        }
    });

    return printerDetails;
};

const transformReceiptPrintStringForIMIN = (printString) => {
    // Change 'Serial No.' to 'SN' for IMIN printers
    printString = printString.replace(/Serial No./g, 'SN');

    const billOrNumberString = printString.match(/(Bill#|SI#):\s*\d+/g);
    
    if (billOrNumberString) {
        // Remove leading zeros from Bill# and SI# for IMIN printers
        // e.g. Bill#: 00000000000000847 -> Bill#: 847
        billOrNumberString.forEach(match => {
            const [, prefix, number] = match.match(/(Bill#|SI#):\s*(\d+)/);
            printString = printString.replace(match, `${prefix}: ${parseInt(number)}`);
        });
    }

    return printString;
};

const transformReportPrintStringForIMIN = (printString) => {
    // Remove leading zeros from array string keys for IMIN printers
    const reportPartsRequiringLeadingZeroRemoval = ['Beg. SI#', 'End. SI#', 'Beg. Bill#', 'End. Bill#', 'Beg. Void#', 'End. Void#'];
    // Example:
    // <tr>
        // <td valign="top">Beg. SI#</td>
        // <td valign="top">00000000000000013457</td>
    // </tr>
    // Need to remove leading zeros from 00000000000000013457

    reportPartsRequiringLeadingZeroRemoval.forEach(key => {
        const keyRegex = new RegExp(`${key}</td>\\s*<td valign="top">(\\d+)`);
        const match = printString.match(keyRegex);

        if (match) {
            const [, number] = match;
            printString = printString.replace(match[1], parseInt(number));
        }
    });

    return printString;
}

export const printLabel = async(data, printerDetails, printerModels) => {

    printerDetails.forEach(detail => {
        let printString = data.zpl;

        let printer_model = printerModels.find(pm => pm.id == detail.printer_model_id);

        printer.print(printer_model, detail.ip_address, data.print_type, printString, detail.paper_width);
    });

    return printerDetails;
}

export const openCashDrawer = async(printerData) => {
    let printerDetails = printerData.printer_details;
    //get printers with cash drawers
    let withCashDrawers = printerDetails.filter(detail => detail.has_cash_drawer == 1);

    withCashDrawers.forEach(printer_with_cash_drawer => {
        let printer_model = printerData.printer_models.find(pm => pm.id == printer_with_cash_drawer.printer_model_id);
        printer.epson_open_drawer(printer_with_cash_drawer.ip_address, printer_model);
    });
}

export default {
    print,
    location_printers,
    openCashDrawer,
    getPrinterIps,
    printLabel,
    test_print,
}
