<template>
    <base-modal
        v-bind="$attrs"
        min-width="320px"
        width="100vw"
        min-height="400px"
        :has-confirm="false"
        cancel-color="primary"
        cancel-label="Close"
        @before-open="parseLineItems"
    >
        <template v-slot:title>
            <div class="d-flex justify-content-between">
                <div>Bill ID: {{ billId }}</div>
                <div class="mr-2 pl-2" v-if="!isSqliteOffloadReceiptEnabled">
                    <button
                        class="btn btn-sm btn-block btn-primary"
                        @click="$emit('openCustomerDetailsModal')"
                    >
                        MANUAL SI
                    </button>
                </div>
            </div>
        </template>

        <hr />

        <div class="d-flex flex-column" v-for="(kotOrders, kot) in ordersByServiceType">
            <div class="p2 mb-2">
                <div class="d-flex">
                    <div class="mr-auto p-2">
                        <h4>KOT #:{{ kot }}</h4>
                    </div>
                    <div class="p-2">
                        <button
                            class="btn btn-sm btn-block btn-primary"
                            @click="$emit('reprintKOT', {kot, parentOrderId: order._id})"
                        >
                            REPRINT
                        </button>
                    </div>
                </div>
            </div>
            <div class="p2 mb-3">

                <template v-for="(orders, serviceTypeName) in kotOrders">
                    <table class="service-type-header">
                        <thead>
                            <tr><th><strong>{{ upperCaseString(serviceTypeName) }}</strong></th></tr>
                        </thead>
                    </table>
                    <!-- Removed filtering in :items="orders" since it's now being done in ordersByServiceType() -->
                    <v-data-table
                        :headers="lineItemHeaders"
                        :items="orders"
                        table-class-name="custom-table mb-1"
                    >
                        <template #item-product.product_name="item">
                            <strong class="product-info">{{ item.product.product_name }}</strong>
                            <template v-if="item.product.forcedMods">
                                <div class="mod" v-for="mod in item.product.forcedMods" :key="mod.modifier_name">
                                    {{ mod.mod_group_name }}: {{ mod.modifier_name }}
                                </div>
                            </template>

                            <template v-if="item.product.unforcedMods">
                                <div class="mod" v-for="mod in item.product.unforcedMods" :key="mod.modifier_name">
                                    {{ mod.modifier_name }}
                                </div>
                            </template>

                            <template v-if="item.product?.is_bundle && item.product?.bundled_products">
                                <div class="mod" v-for="product in item.product.bundled_products" :key="product.id">
                                    {{ product.product_detail.product_name }}
                                </div>
                            </template>

                            <template v-if="item.product?.is_customize">
                                <template v-for="customizedProduct in item.product.customizedProductSummary" :key="customizedProduct.id">
                                    <template v-for="choice in customizedProduct.choices" :key="choice.id">
                                        <div class="mod" v-if="choice.selectedCount">
                                            {{ choice.name }}
                                        </div>
                                    </template>
                                </template>
                            </template>

                            <template v-if="item.discount">
                                <div class="discounts discount-margin">
                                    {{ item.discount.discount_name + ' ' + $filters.formatPrice(item.discount.discountValue) }}%
                                </div>
                            </template>
                        </template>

                        <template #item-quantity="item">
                            <strong class="product-info">{{ item.quantity }}</strong>
                            <template v-if="item.product.forcedMods">
                                <div class="mod" v-for="mod in item.product.forcedMods" :key="mod.modifier_name">
                                    {{ mod.quantity }}
                                </div>
                            </template>

                            <template v-if="item.product.unforcedMods">
                                <div class="mod" v-for="mod in item.product.unforcedMods" :key="mod.modifier_name">
                                    {{ mod.quantity }}
                                </div>
                            </template>

                            <template v-if="item.product?.is_bundle && item.product?.bundled_products">
                                <div class="mod" v-for="product in item.product.bundled_products" :key="product.id">
                                    {{ product.quantity }}
                                </div>
                            </template>

                            <template v-if="item.product?.is_customize">
                                <template v-for="customizedProduct in item.product.customizedProductSummary" :key="customizedProduct.id">
                                    <template v-for="choice in customizedProduct.choices" :key="choice.id">
                                        <div class="mod" v-if="choice.selectedCount">
                                            {{ choice.selectedCount }}
                                        </div>
                                    </template>
                                </template>
                            </template>

                        </template>

                        <template #item-product.totals.beforeSc="item">
                            {{ $filters.formatPrice(item.totals.rawPrice) }}
                            <template v-if="item.product.forcedMods">
                                <div class="mod" v-for="mod in item.product.forcedMods" :key="mod.modifier_name">
                                    {{ mod.mod_price }}
                                </div>
                            </template>

                            <template v-if="item.product.unforcedMods">
                                <div class="mod" v-for="mod in item.product.unforcedMods" :key="mod.modifier_name">
                                    {{ mod.mod_price }}
                                </div>
                            </template>

                            <template v-if="item.discount">
                                <div class="discounts">
                                    - {{ $filters.formatPrice(item.discount.discountAmount) }}
                                </div>
                            </template>
                        </template>

                        <template #item-actions="item">
                            <div class="d-flex">
                                <div class="col">
                                    <strong v-if="item.isCancelled">Cancel</strong>
                                    <strong v-else-if="item.isVoided || order.isVoided">Void</strong>
                                    <strong v-else-if="order.isSettled">Paid</strong>
                                    <button
                                        v-else-if="customVisibilitySettings.BILL_SETTINGS.showLineItemVoid"
                                        class="btn btn-sm btn-block btn-danger"
                                        @click="openLineItemVoidModal(item)"
                                    >
                                        VOID
                                    </button>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </template>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <div class="col-6 p-3">
                <table class="bill-price-breakdown">
                    <tr>
                        <td align="right">Service Charge</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(orderClone.totals.serviceCharge)
                            }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">VAT</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(orderClone.totals.vat)
                            }}</strong>
                        </td>
                    </tr>
                    <tr v-if="orderClone.totals.vatExemptSales">
                        <td align="right">VAT EXEMPT SALES</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(computedVatExemptSales)
                            }}</strong>
                        </td>
                    </tr>
                    <tr v-if="orderClone.totals.zeroRatedSales">
                        <td align="right">ZERO RATED SALES</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(orderClone.totals.zeroRatedSales)
                            }}</strong>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-6 p-3">
                <table class="bill-price-breakdown">
                    <tr>
                        <td align="right">Sub Total</td>
                        <td>
                            <strong>{{ $filters.formatPrice(orderClone.totals.net) }}</strong>
                        </td>
                    </tr>
                    <tr v-if="orderClone.billDiscount">
                        <td align="right">{{ orderClone.billDiscount.discount.discount_name }}</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(orderClone.billDiscount.amount)
                            }}</strong>
                        </td>
                    </tr>
                    <tr v-for="(discount, index) in SummaryLineItemDiscounts">
                        <td align="right">{{ discount.discount.discount_name }}</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(discount.amount)
                            }}</strong>
                        </td>
                    </tr>
                    <tr v-if="orderClone.billDiscount || orderClone.orders.some(o => o.discount)">
                        <td align="right">Total Discount</td>
                        <td>
                            <strong>{{
                                $filters.formatPrice(orderClone.billDiscount?.amount ?? totalLineItemDiscounts )
                            }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Total Amount</strong></td>
                        <td>
                            <strong>{{ getTotalValue }}</strong>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <line-item-void-modal
            v-model="isItemVoidModalOpen"
            :item="voidTarget"
            :orderBilled="order.isBilled"
            triggeredFrom="bill_settings"
            @itemVoidUpdated="applyLineItemVoid"
        />
    </base-modal>
</template>

<script>
import LineItemVoidModal from './LineItemVoidModal.vue';
import BaseModal from './BaseModal.vue';
import VDataTable from 'vue3-easy-data-table';
import { mapState, mapMutations, mapActions } from 'vuex';
import { get, every } from 'lodash';
import cloneDeep from 'rfdc/default';
import {CUSTOM_VISIBILITY_LIST, OFFLOAD} from "@/spa/constants";
import {VAT_EXCL} from "@/vue/helper/discount";
import ReceiptMixin from "@/spa/components/mixins/ReceiptMixin";

export default {
    mixins: [ReceiptMixin],

    components: {
        BaseModal,
        VDataTable,
        LineItemVoidModal,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            lineItemHeaders: [
                { text: 'Product', value: 'product.product_name', sortable: true },
                { text: 'QTY', value: 'quantity', sortable: true },
                { text: 'Price', value: 'product.totals.beforeSc', sortable: true },
                { text: 'Actions', value: 'actions' },
            ],
            lineItems: [],
            isItemVoidModalOpen: false,
            voidTarget: null,
            customVisibilitySettings: CUSTOM_VISIBILITY_LIST,
            isSqliteOffloadReceiptEnabled: OFFLOAD.sqliteOffloadReceipt,
        };
    },

    computed: {
        ...mapState('settings', ['serviceTypes']),

        upperCaseString() {
            return (string) => {
                return string.toUpperCase();
            }
        },

        billId() {
            return get(
                this.order,
                'bills.idb_billing_data.bill_id.0',
                this.order.bill_num
            );
        },

        orderClone() {
            return cloneDeep(this.order);
        },

        ordersByServiceType() {
            const ordersByServiceType = this.orderClone.orders.reduce((acc, order) => {
                const serviceType = this.serviceTypes.find(i => i.id === order.serviceTypeId)?.service_name || 'Unknown Service Type';

                if (!acc[order.kot]) {
                    acc[order.kot] = {};
                }

                if (!acc[order.kot][serviceType]) {
                    acc[order.kot][serviceType] = [];
                }

                if(order.kotHistory && (order.kotHistory[order.kot] || order.movedQty)){
                    for (let key in order.kotHistory){
                        // if history kot is the last kot in combinedKots, then it is the last kot for that order
                        // this is needed as check for edge case scenario where item has same name as another item in existing table, but different kot
                        if (this.shouldSkipKotProcessing(order, key)) continue;

                        if (!acc[key]) {
                            acc[key] = {};
                        }
                        if (!acc[key][serviceType]) {
                            acc[key][serviceType] = [];
                        }

                        // movedQty prop will be set when same item from another table has been moved to another table with same name
                        if(order.movedQty){
                            const originalQty = !order.kotHistory?.[key] ? order.kotHistory[key] : order.quantity - order.kotHistory?.[key];
                            const originalQtyOrder = {...order, quantity: originalQty, totals: {...order.totals, rawPrice: order.totals.rawPrice / order.quantity * (originalQty)}}
                            acc[order.kot][serviceType].push(originalQtyOrder);
                        }

                        let newQty;
                        if (order.quantity > 1 && order.quantity !== order.kotHistory[key] && !order.prevKots?.includes(parseFloat(key))){
                            newQty = order.kotHistory[key];
                        } else {
                            newQty = order.quantity;
                        }

                        const updatedOrder = {...order, quantity: newQty, totals: {...order.totals, rawPrice: order.totals.rawPrice / order.quantity * newQty}}
                        acc[key][serviceType].push(updatedOrder);
                    }
                } else {
                    acc[order.kot][serviceType].push(order);
                }

                if (Object.keys(acc[order.kot][serviceType]).length === 0) delete acc[order.kot];

                return acc;

            }, {});

            console.log(ordersByServiceType)
            return ordersByServiceType;
        },

        totalLineItemDiscounts() {
            return this.order.orders
                .filter(o => o.discount)
                .reduce((acc, order) => acc + order.discount.discountAmount, 0);
        },
        
        SummaryLineItemDiscounts() {
            return this.order.orders
                .filter(o => o.discount)
                .reduce((acc, o) => {
                    const existing = acc.find(d => d.discount.discount_name === o.discount.discount_name);
                    if (existing) {
                        existing.amount += o.discount.discountAmount;
                    } else {
                        acc.push({
                            discount: o.discount,
                            amount: o.discount.discountAmount,
                        });
                    }

                    return acc;
                }, []);
        },

        computedVatExemptSales() {
            return [...this.SummaryLineItemDiscounts, ...[this.orderClone?.billDiscount || {}]]
                .filter(d => ENABLE_NEW_VAT_EXEMPT_SALES && VAT_EXCL.includes(d.discount?.discount_name))
                .reduce((discountTotal, item) => {
                    return discountTotal + parseFloat(item.amount);
                }, parseFloat(this.orderClone.totals.vatExemptSales));
        },
        getTotalValue() {
          return this.$filters.formatPrice(this.grossSales || this.orderClone.totals.total);
        }
    },

    methods: {
        ...mapMutations(['updateOrder']),

        ...mapActions([
            'recomputeTotals',
            'regenerateOrderTotals',
        ]),

        shouldSkipKotProcessing(order, key) {
            // Conditionals for edge cases:
            const isKeyNotLastKot = order.combinedKots && order.combinedKots[order.combinedKots.length - 1] != key;
            const isKeyNotLastKotInPrevKots = order.prevKots && order.prevKots.length > 1 && order.prevKots[order.prevKots.length - 1] != key;
            const orderHasNoKotHistoryAndNotInPrevKots = key != order.kot && !order.prevKots?.includes(parseFloat(key)) && !order.kotHistory;

            if (isKeyNotLastKot || isKeyNotLastKotInPrevKots || orderHasNoKotHistoryAndNotInPrevKots) {
                return true;
            }

            return false
        },

        openLineItemVoidModal(item) {
            this.voidTarget = item;
            this.isItemVoidModalOpen = true;
        },

        findItemIndex(item) {
            const criteriaProps = ['kot', 'productCreatedAt', 'product.id'];
            return this.order.orders
                .findIndex(o => every(criteriaProps, c => get(item, c) === get(o, c)));
        },

        async applyLineItemVoid(item) {
            const itemIndex = this.findItemIndex(item);

            this.lineItems[itemIndex] = {
                ...this.lineItems[itemIndex],
                ...item,
            };

            this.regenerateOrderTotals({ orderId: this.order._id, key: 'preVoidTotals' });
            this.updateOrder({
                orderId: this.order._id,
                order: { orders: cloneDeep(this.lineItems) },
            });

            const totalsObj = await this.recomputeTotals({
                orderId: this.order._id,
                updateOrder: true
            });

            this.orderClone.totals = totalsObj.grandTotals;

            //emit print kot void
            this.$emit('printKOTVoid', {kot: item.kot, parentOrderId:this.order._id});

            this.isItemVoidModalOpen = false;
        },

        async parseLineItems() {
            this.lineItems = cloneDeep(this.orderClone.orders);

            await this.getGrossSales(this.order._id);
        },
    },
};
</script>

<style scoped>
.product-info {
    font-size: 0.8rem;
}
.mod {
    font-size: 0.7rem;
    color: #888;
}

.discounts {
    font-size: 0.7rem;
    color: #888;
    font-style: italic;
}

.discount-margin {
    margin-left: 1.5rem;
}

.custom-table {
    --easy-table-header-font-color: #fff;
    --easy-table-header-background-color: #063B67;
}

table.service-type-header {
    width: 100%;
}

table.service-type-header thead {
    background-color: #D1D7DC !important;
    color: #063B67 !important;
}

table.service-type-header thead th {
    padding: 5px;
    color: #063B67;
    font-size: 13px;
}

</style>