<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col-xl-8">

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          <label @click.self="checkShowSync">Settings Page</label>

          <button
            class="btn btn-primary btn-lg mr-2"
            :disabled="isFetching"
            @click="triggerManualFetchingOfOrders(false)"
            >
            <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
          </button>
        </div>

        <sqlite-offload-force-sync v-if="isSqliteOffloadReceiptEnabled" />
      </div>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">Bills</label>

          <input
              v-model="billNumQuery"
              class="form-control col-4"
              type="text"
              placeholder="Search bills"
              @input="sqliteOffloadSearchBill"
          />
        </div>

        <div class="bills-section mb-4" v-if="isSqliteOffloadReceiptEnabled">
          <Vue3EasyDataTable
              ref="offloadDataTable"
              v-model:server-options="offloadBill.serverOptions"
              :server-items-length="offloadBill.serverItemsLength"
              :loading="isOffloadFetching"
              :headers="offloadBillHeader"
              :items="offloadBill.data"
              @update-sort="
                offloadBill.serverOptions.sortBy = $event.sortBy;
                offloadBill.serverOptions.sortType = $event.sortType;
                getSqliteOffloadReceiptsPaged();
              "
          >
            <template #item-business_date="item">
              {{ item.business_date.replace(" 00:00:00", "") }}
            </template>

            <template #item-bill_num="item">
              <i v-if="item.synced_at" class="fas fa-cloud text-success"></i>
              <i v-else-if="item?.is_syncing" class="fas fa-cloud-upload-alt text-warning"></i>
              <i v-else class="fa fa-cloud"></i>
              <strong class="ml-1">
                {{ item.bill_num ? `BN-${item.bill_num}` : '-' }}
              </strong>
            </template>

            <template #item-transaction_status_id="item">
              {{ sqliteOffloadTransactionStatus(item.transaction_status_id) }}
            </template>

            <template #item-vat_amount="item">
              {{ $filters.formatPrice(item.vat_amount) }}
            </template>

            <template #item-net_sales="item">
              {{ $filters.formatPrice(item.net_sales) }}
            </template>

            <template #item-gross_sales="item">
              {{ $filters.formatPrice(item.gross_sales) }}
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                      class="btn btn-sm btn-block btn-primary"
                      @click="openBillDetailsModal(item)"
                  >
                    VIEW
                  </button>
                </div>

                <div class="col">
                  <button
                      @click="openFullVoidModal(item, 'BILL')"
                      :disabled="isVoidButtonDisabled(item)"
                      class="btn btn-sm btn-block btn-danger"
                      :class="{disabled : isVoidButtonDisabled(item)}"
                  >
                    VOID
                  </button>
                </div>

                <div class="col">
                  <button
                      class="btn btn-sm btn-block btn-primary"
                      @click="showPrintOptions(item)"
                  >
                    PRINT
                  </button>
                </div>

              </div>
            </template>

            <template #pagination>
              <button
                  class="btn btn-primary mr-2"
                  :disabled="offloadBill.serverOptions.page === 1"
                  @click="getSqliteOffloadReceiptsPaged(offloadBill.serverOptions.page, 'prev')">
                <i class="fa fa-chevron-left"></i>
              </button>
              <button
                  class="btn btn-primary ml-2"
                  :disabled="offloadBill.serverItemsLength === 0 || offloadBill.serverOptions.page === Math.ceil(offloadBill.serverItemsLength / offloadBill.serverOptions.rowsPerPage)"
                  @click="getSqliteOffloadReceiptsPaged(offloadBill.serverOptions.page, 'next')">
                <i class="fa fa-chevron-right"></i>
              </button>
            </template>
          </Vue3EasyDataTable>
        </div>

        <div class="bills-section mb-4" v-else>
          <v-data-table
            ref="dataTable"
            :headers="billHeaders"
            :items="billItems"
            :rows-per-page="10"
            :loading="isFetching"
            sort-by="bill_num"
            sort-type="desc"
            :hide-footer="true"
          >
            <template #item-bill_num="item">
              <i v-if="queueStatus?.[item.bill_num] == 1 || (item?.isSyncToReceipts && !item?.isVoided) || item.isOnlineDelivery" class="fas fa-cloud text-success"></i>
              <i v-else-if="item.isSyncing || item.isUpdateSyncing || item.isSync || item.isUpdateSynced" class="fas fa-cloud-upload-alt text-warning"></i>
              <i v-else class="fa fa-cloud"></i>
              <strong class="ml-1">
                {{ item.bill_num ? `BN-${item.bill_num}` : '-' }}
              </strong>
            </template>

            <template #item-receipt_num="item">
              {{ item.receipt_num }}
            </template>

            <template #item-reprint_number="item">
              {{ item.reprint_count }}
            </template>

            <template #item-totals.vat="item">
              {{ $filters.formatPrice(item.totals.vat) }}
            </template>

            <template #item-totals.net="item">
              {{ $filters.formatPrice(item.totals.net) }}
            </template>

            <template #item-totals.total="item">
              {{ $filters.formatPrice(item.totals.total) }}
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                        class="btn btn-sm btn-block btn-primary"
                        @click="openBillDetailsModal(item)"
                    >
                      VIEW
                  </button>
                </div>

                <div class="col">
                  <button
                        @click="openFullVoidModal(item, 'BILL')"
                        :disabled="item.isVoided || (item?.isOnlineDelivery && !item?.isTabsquareOrder) || voidDisabled(item)"
                        class="btn btn-sm btn-block btn-danger"
                        :class="{disabled : voidDisabled(item)}"
                    >
                      VOID
                  </button>
                </div>

                <div class="col">
                  <button
                        class="btn btn-sm btn-block btn-primary"
                        @click="showPrintOptions(item)"
                    >
                      PRINT
                  </button>
                </div>

                <div
                    class="col"
                    v-if="isShowRetry(item)"
                >
                    <button
                        class="btn btn-sm btn-block btn-success"
                        @click="syncOrder(item)"
                    >
                        RETRY SYNC
                  </button>
                </div>
              </div>
            </template>
          </v-data-table>

          <!-- Current totals table row -->
          <v-data-table
            :headers="totalsHeaders"
            :items="totalsRow"
            :rows-per-page="1"
            :loading="isFetching"
            :hide-footer="true"
            :hide-header="true"
            table-min-height="36"
          >
            <template #item-labels.tableLabel="item">
              <div><b>{{ item.labels.tableLabel }}</b></div>
            </template>

            <template #item-labels.vatTotal="item">
              <div><b>{{ item.labels.vatTotal }}</b>{{ $filters.formatPrice(item.totals.vat) }}</div>
            </template>

            <template #item-labels.netSalesTotal="item">
              <div><b>{{ item.labels.netSalesTotal }}</b>{{ $filters.formatPrice(item.totals.net) }}</div>
            </template>

            <template #item-labels.totalAmount="item">
              <div><b>{{ item.labels.totalAmount }}</b>{{ $filters.formatPrice(item.totals.total) }}</div>
            </template>
          </v-data-table>

          <!-- Extracted VDataTable footer -->
          <div class="vue3-easy-data-table__footer" data-v-0c7093ee="">
            <div class="pagination__rows-per-page" data-v-0c7093ee="">
              rows per page:
              <div class="easy-data-table__rows-selector" data-v-09dad912="" data-v-0c7093ee="" style="--7fe9410c: #42b883;">
                <div class="rows-input__wrapper" data-v-09dad912="">
                  <select @change="updateRowsPerPageSelect" class="rows-per-page">
                    <option v-for="item in rowsPerPageOptions" :key="item" :selected="item === rowsPerPageActiveOption" :value="item" class="rows-input">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="pagination__items-index" data-v-0c7093ee="">{{ currentPageFirstIndex }}–{{ currentPageLastIndex }} of {{ clientItemsLength }}</div>
            <div class="previous-page__click-button" :class="{ 'first-page': isFirstPage }" @click="prevPage" data-v-c9da5286="">
              <span class="arrow arrow-right" data-v-c9da5286=""></span>
            </div>
            <!---->
            <div class="next-page__click-button" :class="{ 'last-page': isLastPage }" @click="nextPage" data-v-c9da5286="">
              <span class="arrow arrow-left" data-v-c9da5286=""></span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">Transactions</label>

          <input
              v-model="transactionNumQuery"
              class="form-control col-4"
              type="text"
              placeholder="Search transactions"
          />
        </div>

        <div class="transaction-section">
          <v-data-table :headers="transactionHeaders" :items="transactionItems" :rows-per-page="10">
            <template #item-totals.vat="item">
              {{ $filters.formatPrice(item.totals.vat) }}
            </template>

            <template #item-totals.net="item">
              {{ $filters.formatPrice(item.totals.net) }}
            </template>

            <template #item-totals.total="item">
              {{ $filters.formatPrice(item.totals.total) }}
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                      @click="openFullVoidModal(item, 'TRANSACTION')"
                      :disabled="item.isVoided"
                      class="btn btn-sm btn-block btn-danger"
                  >
                    VOID
                </button>
                </div>
                <div class="col">
                  <button
                      @click="printKOT(item)"
                      class="btn btn-sm btn-block btn-primary"
                  >
                    REPRINT
                  </button>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <bill-details-modal
      v-if="selectedOrder"
      v-model="isBillDetailsModalOpen"
      :order="selectedOrder"
      @reprintKOT="printKOT(null, $event, false)"
      @printKOTVoid="printKOT(null, $event, true)"
      @openCustomerDetailsModal="openCustomerDetailsModal"
      />

    <void-slip-modal
      v-model="isVoidSlipModalOpen"
      :void-slip-html="voidSlipString"
    />

    <full-void-modal
      v-model="isFullVoidModalOpen"
      :item="selectedItem"
      :voidTrigger="fullVoidTrigger"
      @applyFullVoid="applyFullVoid"
    />

    <customer-details-modal
      v-model="isCustomerDetailsModalOpen"
      :customerDetails="this.customerDetails"
      :selectedOrder="this.selectedOrder"
      :editMode="this.editMode"
      @saveCustomerDetails="postCustomerDetailsHandler"
      @skipCustomerDetails="postSkipCustomerDetailsHandler" />
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import VDataTable from 'vue3-easy-data-table';
import Vue3EasyDataTable from "vue3-easy-data-table";

import BillDetailsModal from '@/spa/components/modals/BillDetailsModal';
import VoidSlipModal from '@/spa/components/modals/VoidSlipModal';
import FullVoidModal from '@/spa/components/modals/FullVoidModal';
import CustomerDetailsModal from "@/spa/components/modals/CustomerDetailsModal";
import SqliteOffloadForceSync from "@/spa/components/pages/components/SqliteOffloadForceSync";

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { print, getPrinterData } from "@/spa/services/printer-service";
import { getTaxes, updateCustomerInfo } from '@/spa/services/product-service';
import { getReceiptDetails } from "@/spa/services/cashier-service";
import { getReceiptPrintString, getVoidPrintString, generateReceiptItemString } from "@/spa/utils/print";
import kotHtml from '@/spa/components/templates/print/kot.html';
import { mapValues, cloneDeep, isEmpty, flatten, map, uniq, get, debounce, uniqBy, isNil } from "lodash";
import { convert } from 'html-to-text';
import { Logger } from "@/spa/helpers/Logger";

import {
  getSyncedOrders,
  getSyncedSettlements,
  updateSyncedOrder,
  cleanupOlderOrders,
  cleanupOlderSettlements,
  forceSyncOrder,
  voidBackgroundSync
} from '@/spa/services/sync-service';

import moment from 'moment';

import {
  SYNC_STATUSES,
  STATUSES,
  LINE_ITEM_SEPARATOR_CHAR_COUNT,
  IS_PUC_LOCATION,
  OFFLOAD,
  ACTIVE_ACCOUNT_TYPE,
  ACCOUNT_TYPES,
} from '@/spa/constants';

import SyncStatus from "@/spa/global-mixins/sync-status";

import {hasPendingRequests} from "@/spa/plugins/axios";

import MForceSyncBlocker from '@/spa/components/common/MForceSyncBlocker';
import seriesService from "@/spa/services/series-service";
import {
  sumArray,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_ID
} from "@/mobile_bridge/offload/receipt-model";
import { multiterminalEvents } from '@/mobile_bridge/multiterminal/events';

import { computed, ref, onMounted } from 'vue';
import {OFFLOAD_RECEIPT_ACTION, OrderBridge} from "@/mobile_bridge/offload/offload-receipt";
import bus from "@/spa/utils/bus";

export default {
  name: 'BillSettings',

  components: {
    SettingsSidebar,
    VDataTable,
    BillDetailsModal,
    VoidSlipModal,
    FullVoidModal,
    CustomerDetailsModal,
    MForceSyncBlocker,
    SqliteOffloadForceSync,
    Vue3EasyDataTable
  },

  mixins: [SyncStatus],

  setup() {
    const dataTable = ref(null);

    // Computed properties
    const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
    const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
    const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);
    const isFirstPage = computed(() => dataTable.value?.isFirstPage);
    const isLastPage = computed(() => dataTable.value?.isLastPage);
    const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
    const rowsPerPageActiveOption = computed(() => dataTable.value?.rowsPerPageActiveOption);

    // Methods
    const nextPage = () => dataTable.value.nextPage();
    const prevPage = () => dataTable.value.prevPage();
    const updateRowsPerPageSelect = (e) => dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));

    // Initialize the dataTable reference after the component is mounted
    onMounted(() => {
      dataTable.value = dataTable;
    });

    return {
      // Refs
      dataTable,

      // Computed properties
      currentPageFirstIndex,
      currentPageLastIndex,
      clientItemsLength,
      isFirstPage,
      isLastPage,
      rowsPerPageOptions,
      rowsPerPageActiveOption,

      // Methods
      nextPage,
      prevPage,
      updateRowsPerPageSelect,
    };
  },

  data() {
    return {
      billHeaders: [
        { text: 'Date', value: 'businessDate', sortable: true },
        { text: 'Bill #', value: 'bill_num', sortable: true },
        { text: 'SI #', value: 'receipt_num', sortable: true },
        { text: 'SI Reprint #', value: 'reprint_count', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'VAT', value: 'totals.vat', sortable: true },
        { text: 'Net Sales', value: 'totals.net', sortable: true },
        { text: 'Total Amount', value: 'totals.total', sortable: true },
        { text: 'Actions', value: 'actions' },
      ],
      offloadBillHeader: [
        { text: 'Date', value: 'business_date', sortable: true, width: 100 },
        { text: 'Bill #', value: 'bill_num', sortable: true, width: 100 },
        { text: 'SI #', value: 'receipt_num', sortable: true },
        { text: 'SI Reprint #', value: 'reprint_count', sortable: false },
        { text: 'Status', value: 'transaction_status_id', sortable: true },
        { text: 'VAT', value: 'vat_amount', sortable: false },
        { text: 'Net Sales', value: 'net_sales', sortable: false },
        { text: 'Total Amount', value: 'gross_sales', sortable: false },
        { text: 'Actions', value: 'actions' },
      ],
      offloadBill: {
        data: [],
        serverOptions: {
          page: 1,
          rowsPerPage: 10,
          sortBy: 'bill_num',
          sortType: 'desc',
        },
        serverItemsLength: 0,
      },
      isOffloadFetching: false,

      totalsHeaders: [
        { text: 'Current totals', value: 'labels.tableLabel', width: 350 },
        { text: 'Vat Total', value: 'labels.vatTotal' },
        { text: 'Net Sales', value: 'labels.netSalesTotal' },
        { text: 'Total Amount', value: 'labels.totalAmount' },
      ],

      transactionHeaders: [
        { text: 'Date', value: 'productCreatedAt', sortable: true },
        { text: 'Transaction ID', value: 'bill_num', sortable: true },
        { text: 'KOT #', value: 'kot', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'VAT', value: 'totals.vat', sortable: true },
        { text: 'Net Sales', value: 'totals.net', sortable: true },
        { text: 'Total Amount', value: 'totals.total', sortable: true },
        { text: 'Actions', value: 'actions' },
      ],
      offloadTransactionItems: [],

      billNumQuery: '',
      transactionNumQuery: '',

      isBillDetailsModalOpen: false,
      isVoidSlipModalOpen: false,
      isFullVoidModalOpen: false,
      isCustomerDetailsModalOpen: false,
      selectedOrder: null,
      selectedItem: null,
      fullVoidTrigger: null,

      orders: [],
      settlements: [],
      tax_data: [],
      voidSlipString: '',

      isFetching: true,

      filteredBillOrders: [],
      debouncedGenerateFilteredBillOrders: () => null,

      showSyncOrderButton: false,
      showSyncOrderButtonCounter: 0,
      showSyncOrderButtonInterval: null,
      showSyncOrderButtonShowCount: 6,
      resetClickTimer: 5000,

      customerDetails: {
        name: '',
        businessStyle: '',
        address: '',
        tin: '',
        officialReceipt: '',
      },
      editMode: false,
      vatTotal: 0,
      netTotal: 0,
      totalAmount: 0,
      isSqliteOffloadReceiptEnabled: OFFLOAD.sqliteOffloadReceipt
    };
  },

  computed: {
    ...mapState(['dataContainer', 'lastVoidBillNum', 'lastVoidReceiptNum', 'queueStatus', 'activeBrandId']),
    ...mapState({ originalOrders: state => state.orders }),
    ...mapState('settings', ['posDate', 'serviceTypes']),
    ...mapGetters(['pendingOrders', 'activePendingSettlements']),
    ...mapState('user', ['locationId']),
    ...mapState('sqlite', ['initialData']),

    billItems() {
      let vatTotal = 0;
      let netTotal = 0;
      let totalAmount = 0;

      const billItems = flatten(this.filteredBillOrders.map(ord => {
        const ords = isEmpty(ord.splits) ? [ord] : ord.splits;
        return map(ords, o => {
          const ret = {
            ...ord,
            ...o,
            reprint_count: o.reprint_count ?? 0,
            orders: o.orders,
            businessDate: moment(o.originShiftTable?.pos_date).format('YYYY-MM-DD'),
            status: this.getStatusString(o),
            isSync: ord.isSync,
          }

          //check if order has splits. this is to avoid splits being undefined when voiding splits
          if(!isEmpty(ord.splits)) {
            ret['splits'] = ord.splits;
          }

          // Update the totals
          vatTotal += o.totals.vat;
          netTotal += o.totals.net;
          totalAmount += o.totals.total;

          return ret;
        });
      }));

      this.vatTotal = vatTotal;
      this.netTotal = netTotal;
      this.totalAmount = totalAmount;

      return billItems;
    },

    totalsRow(){
      // EPOS-704
      return [
        {
          labels: {
            tableLabel: 'Current Totals',
            vatTotal: 'VAT Total: ',
            netSalesTotal: 'Net Sales Total: ',
            totalAmount: 'Total Amount: ',
          },
          totals: {
            vat: this.vatTotal,
            net: this.netTotal,
            total: this.totalAmount,
          },
        }
      ]
    },
    printerData() {
      return this.getPrinterInfo();
    },

    transactionItems() {
      const pendingOrders = OFFLOAD.sqliteOffloadReceipt
          ? this.offloadTransactionItems
          : this.pendingOrders.filter(po => !po.isBilled);

      return pendingOrders.map(po => po.kots?.map(kot => {
        const kotOrders = po.orders.filter(o => o.kot === kot);

        return kotOrders.reduce((result, order) => ({
          ...result,
          parentOrderId: po._id,
          kot,
          productCreatedAt: moment(order.productCreatedAt).format('YYYY-MM-DD HH:mm'),
          status: this.getStatusString(po),
          isVoided: result.isVoided && order.isVoided,
          totals: {
            vat: result.totals.vat + (order.totals?.vat || 0),
            net: result.totals.net + (order.totals?.net || 0),
            total: result.totals.total + (order.totals?.total || 0),
          },
        }), {
            totals: {
            net: 0,
            vat: 0,
            total: 0,
          },
          isVoided: true,
        });
      }) ?? [])
        .flat()
        .filter(t => t.status === STATUSES.PENDING)
        .filter(t => !this.transactionNumQuery || t.kot.toString().includes(this.transactionNumQuery));
    },
  },

  async mounted() {
    this.debouncedGenerateFilteredBillOrders = debounce(this.generateFilteredBillOrders.bind(this), 500);
    this.refreshSyncedData();
    await this.fetchTaxes();
    this.triggerManualFetchingOfOrders(true);
    this.cleanupPreviousDayBills(true);
    this.cleanupPreviousDaySettlements(true);
    this.generateFilteredBillOrders();

    if (OFFLOAD.sqliteOffloadReceipt) {
      await this.getSqliteOffloadReceiptsPaged();
      await this.getSqliteOffloadPendingOrders();

      bus.on("reloadSqliteOffloadReceiptsPaged", async () => {
        await this.getSqliteOffloadReceiptsPaged();
      });

      multiterminalEvents.on('MessageFeed', async (event) => {
        const { type, action } = event.body;

        if (type === 'Receipt' && action === 'UPSERT') {
          await this.getSqliteOffloadReceiptsPaged();
          await this.getSqliteOffloadPendingOrders();
        }
      });
    }
  },

  watch: {
    originalOrders: {
      handler() {
        this.refreshSyncedData();
      },
      deep: true,
    },

    billNumQuery(value) {
      if (!value) {
        this.generateFilteredBillOrders();
        return;
      }

      this.debouncedGenerateFilteredBillOrders();
    },
    queueStatus: {
      handler() {
        const isAllSynced = this.billItems.every(item => this.queueStatus?.[item.bill_num] == 1 || item.isOnlineDelivery);
        const isCurrentlySyncing = this.billItems.some(item => item.isSyncing || item.isUpdateSyncing || item.isSync && item.isVoided || item.isUpdateSynced);

        if (isAllSynced) {
          this.setSyncStatus(SYNC_STATUSES.SYNCED)
        } else if (isCurrentlySyncing) {
          this.setSyncStatus(SYNC_STATUSES.SYNCING)
        } else {
          this.setSyncStatus(SYNC_STATUSES.UNSYNC)
        }
      }
    },
  },

  methods: {
    ...mapMutations(['addOrder','updateOrder', 'incrementLastVoidBillNum', 'incrementLastVoidReceiptNum', 'setPendingSettlements']),
    ...mapActions(['regenerateOrderTotals', 'recomputeTotals', 'getLatestServerOrders', 'forceSyncOrders', 'forceSyncSingleOrder', 'sqliteOffloadSync', 'sqliteUpsertReceipt']),
    ...mapMutations('global', ['setIsForceSyncing', 'setSyncStatus']),

    kotGroupOrdersByServiceType(orderDetail, transactionItem, ignoreKot = false) {
      return orderDetail.orders.reduce((acc, order) => {
        const serviceType = this.serviceTypes.find(i => i.id === order.serviceTypeId).service_name || 'Unknown Service Type'

        if(order && (order.kot == transactionItem.kot || ignoreKot)) {
          if (!acc[serviceType]) {
            acc[serviceType] = [];
          }

          acc[serviceType].push(order);
        }

        return acc;

      }, {});
    },

    async refreshSyncedData() {
      this.orders = await getSyncedOrders();
      this.settlements = await getSyncedSettlements();
      this.debouncedGenerateFilteredBillOrders();
    },

    generateFilteredBillOrders() {
      let billedOrders = uniqBy([...this.orders, ...this.originalOrders.filter(o => o && (!o.isSync || o.isOnlineDelivery))], '_id')
        .filter(o => o
          && o.isBilled
          && (o.originShiftTable?.pos_date == this.posDate || o.isOnlineDelivery)
        );

      billedOrders = billedOrders.map(o => {
        const key = this.originalOrders.findIndex(i => i._id === o._id);
        if (key >= 0) {
          o = {...o, ...this.originalOrders[key]};
        }
        return o;
      });

      if (!this.billNumQuery) {
        this.filteredBillOrders = billedOrders;
      }

      const lowerQuery = this.billNumQuery.toLowerCase();
      this.filteredBillOrders = billedOrders.filter(o =>
        `BN-${o.bill_num}`.toLowerCase().includes(lowerQuery)
        || o.splits?.some(s => s.bill_num.toString().toLowerCase().includes(lowerQuery))
        || o.receipt_num?.toString().toLowerCase().includes(lowerQuery)
        || this.getStatusString(o).toLowerCase().includes(lowerQuery)
      );
    },

    async triggerManualFetchingOfOrders(silent = false) {
        if (OFFLOAD.sqliteOffloadReceipt) {
            this.isFetching = false;
            await this.getSqliteOffloadReceiptsPaged();

            return;
        }

        if(hasPendingRequests()) {
          this.$swal.warning('You still have an on-going server request. Please try again later.');
          setTimeout(() => this.isFetching = false, 1000);
          return;
        }

        this.isFetching = true;
        try {
            await this.getLatestServerOrders();
            this.refreshSyncedData();
            if (!silent) this.$swal.success('Success', 'Multiterminal orders fetched successfully');
        } catch (e) {
            if (!silent) this.$swal.error('Failed to fetch orders', 'Please check your connection and try again');
        }

        setTimeout(() => this.isFetching = false, 1000);
    },

    async triggerForceSyncOrders() {
        if(hasPendingRequests()) {
          this.$swal.warning('You still have an on-going server request. Please try again later.');
          return;
        }

        try {
            this.setIsForceSyncing(true);
            await this.forceSyncOrders(this.posDate);
        } catch (error) {
          const errorMessage = error?.response?.data?.message ?? 'Something went wrong';
          this.$swal.error(errorMessage);
        } finally {
          this.setIsForceSyncing(false);
        }
    },

    async cleanupPreviousDayBills(silent = false, previousDaysToKeep = 1) {
      const isConfirmed = silent || await this.$swal.confirm('Are you sure?', 'This will clear all previous day bills from local storage. (They will still be stored on the server)');
      if (!isConfirmed) return;

      try {
        const cutoffDate = moment(this.posDate, 'YYYY-MM-DD 00:00:00')
          .subtract(previousDaysToKeep, 'days')
          .format('YYYY-MM-DD 00:00:00');

        await cleanupOlderOrders(cutoffDate);
        this.refreshSyncedData();
        if (silent) return;
        this.$swal.success('Success', 'Previous day bills and settlements cleared from local storage successfully');
      } catch (e) {
        if (silent) return;
        this.$swal.error('Failed to delete bills', 'Please try again');
      }
    },

    async cleanupPreviousDaySettlements(silent = false, previousDaysToKeep = 1) {
      const isConfirmed = silent || await this.$swal.confirm('Are you sure?', 'This will clear all previous day settlements from local storage. (They will still be stored on the server)');
      if (!isConfirmed) return;

      try {
        const cutoffDate = moment(this.posDate, 'YYYY-MM-DD 00:00:00')
          .subtract(previousDaysToKeep, 'days')
          .format('YYYY-MM-DD 00:00:00');

        await cleanupOlderSettlements(cutoffDate);
        if (silent) return;
        this.$swal.success('Success', 'Previous day settlements cleared from local storage successfully');
      } catch (e) {
        if (silent) return;
        this.$swal.error('Failed to delete settlements', 'Please try again');
      }
    },

    formatTotals(totals) {
        return mapValues(totals, v => v !== null ? this.$filters.formatPrice(v) : v);
    },

    async getPrinterInfo() {
      return await getPrinterData();
    },

    async parseReceiptDetails(order) {
        try {
            let receiptResponse = await getReceiptDetails();
            const brandId = OFFLOAD.sqliteOffloadReceipt
                ? this.activeBrandId
                : order.brandId;
            return Object.values(receiptResponse.data).find(d=>d.brand_id == brandId);
        } catch (error) {
            console.log(error);
        }

        return {};
    },

    getStatusString(order) {
      if (order.isVoided) return STATUSES.VOID;
      if (order.isSettled || order.receipt_num > 0) return STATUSES.PAID;
      if (order.isBilled) return STATUSES.BILLED;
      return STATUSES.PENDING;
    },

    async openBillDetailsModal(order) {
      if (!OFFLOAD.sqliteOffloadReceipt) {
        this.customerDetails.name = order.customerDetails?.name ?? '';
        this.customerDetails.businessStyle = order.customerDetails?.businessStyle ?? '';
        this.customerDetails.address = order.customerDetails?.address ?? '';
        this.customerDetails.tin = order.customerDetails?.tin ?? '';
        this.customerDetails.officialReceipt = order.customerDetails?.officialReceipt ?? '';
        this.selectedOrder = order;
      } else {
        let localId = order.local_id;
        if (order.transaction_status_id === TRANSACTION_STATUS_ID.ORIGINAL) {
          localId = Math.floor(localId / 10); //remove last digit
        }
        const ob = new OrderBridge();
        const response = await ob.getOrderById(localId);
        if (!isEmpty(response)) {
          const order = JSON.parse(response.order_data);

          if (OFFLOAD.sqliteOffloadPOSFE1300
            && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
            && !isEmpty(order.receiptMergedWith)
          ) {
            const orderResponse = await ob.getRows({
              select: ['order_data'],
              whereIn: {
                id: order.receiptMergedWith.join(",")
              }
            });

            const sqliteOrders = orderResponse.map(order => JSON.parse(order.order_data));
            order.orders = order.orders.concat(sqliteOrders.flatMap(item => item.orders));
          }

          this.selectedOrder = order;
        }
      }

      this.editMode = true;
      this.isBillDetailsModalOpen = true;
    },

    async generateReceiptArgs(order) {
        let discountContainer = [];

        let paymentsArr = order.payments ?? [];

        if(order.splits && order.splits.length) {
          paymentsArr = [];
          order.splits.filter(s => s.bill_num == order.bill_num).forEach(s => {
            paymentsArr.push(...s.payments);
            if(s.billDiscount) {
              s.billDiscount.customerDetails.forEach(detail => {
                discountContainer.push({
                    discountAmount: s.billDiscount.amount / s.billDiscount.discountQuantity,
                    discountPax: s.billDiscount.discountQuantity,
                    ...detail,
                    ...s.billDiscount.discount,
                });
              });
            } else {
              s.discounts?.forEach(d => {
                discountContainer.push({discountAmount: d.amount, discountPax: d.discountQuantity, ...d.customerDetails[0], ...d.discount});
              });
              //check if discount array is empty
              if(s.discounts.length == 0) {
                //get line item discount
                discountContainer.push(...this.getLineItemDiscounts(s));
              }
            }
          });
        } else if(order.billDiscount) {
            order.billDiscount.customerDetails.forEach(detail => {
              discountContainer.push({
                  discountAmount: order.billDiscount.amount / order.billDiscount.discountQuantity,
                  discountPax: order.billDiscount.discountQuantity,
                  ...detail,
                  ...order.billDiscount.discount,
              });
            });
        } else {
            //get line item discount
            discountContainer.push(...this.getLineItemDiscounts(order));
        }

        let receiptArgs = {
            formattedTotals: this.formatTotals(order.totals),
            tableName: order.tableName,
            receiptDetails: await this.parseReceiptDetails(order),
            customerDetails: order.customerDetails,
            discountContainer: discountContainer,
            payments: paymentsArr,
            dataContainer: this.dataContainer,
            activeBrandId: order.brandId,
            serviceType: order.serviceType,
            bill_num: order.bill_num,
            receipt_num: order.receipt_num,
            mode: 'bill',
            reprint_string: 'REPRINT <br>',
        };

        return receiptArgs;
    },

    getLineItemDiscounts(order) {
        return order.orders.reduce((ret, o) => {
            if(o.discount) {
                ret.push(o.discount);
            }

            return ret;
        }, []);
    },

    showPrintOptions(order) {
      const isSettled = OFFLOAD.sqliteOffloadReceipt
          ? order.transaction_status_id == TRANSACTION_STATUS_ID.PAID
          : order.isSettled;

      const isVoided = OFFLOAD.sqliteOffloadReceipt
          ? order.transaction_status_id == TRANSACTION_STATUS_ID.ORIGINAL
          : order.isVoided;

      const swalTitle = isVoided
          ? `Reprint Void ${isSettled || order?.receipt_num ? 'Slip' : 'Bill'}`
          : 'Choose the type of receipt';

      Swal.fire({
          title: swalTitle,
          showDenyButton: isSettled,
          showCancelButton: true,
          showConfirmButton: !isVoided ? true : !isSettled,
          confirmButtonText: isVoided ? 'Print' : 'Bill',
          denyButtonText: isVoided ? 'Print' : 'SI',
          confirmButtonColor: '#1b75bb',
          denyButtonColor: '#1b75bb',
          cancelButtonColor: '#f47070',
          customClass: {
              actions: 'print-swal-action',
          }
      }).then(async(result) => {
          if(result.isDismissed) {
            Swal.close();
            return;
          }

          let offloadOrder = null;
          if (OFFLOAD.sqliteOffloadReceipt) {
            offloadOrder = cloneDeep(order);
            const ob = new OrderBridge();
            let localId = order.local_id;
            if (isVoided) {
              localId = Math.floor(localId / 10); //remove last digit
            }
            const response = await ob.getOrderById(localId);

            if (isEmpty(response)) return;

            order = JSON.parse(response.order_data);
          }

          let receiptArgs = await this.generateReceiptArgs(order);
          if(isVoided){
            return this.reprintVoid(order, receiptArgs);
          }
          receiptArgs.mode = (result.isConfirmed) ? "bill" : "settlement";
          if(receiptArgs.mode === "settlement" && !OFFLOAD.sqliteOffloadReceipt) {
            const orderSplits = order.splits ?? [];
            const isUpdateReprintCount = Boolean(orderSplits.length == 0);
            const isUpdateSplitOrders = Boolean(orderSplits.length > 0);

            const newSplits = orderSplits.filter(s => s._id == order._id).map(o => {
                                let ret = {
                                  ...o,
                                };
                                if(isUpdateSplitOrders && o.bill_num == order.bill_num) {
                                  ret['reprint_count'] = o.reprint_count ? o.reprint_count + 1 : 1;
                                }
                                return ret;
                              });

            this.updateOrder({
              orderId: order._id,
              order: cloneDeep({
                reprint_count: isUpdateReprintCount && order.reprint_count ? order.reprint_count + 1 : 1,
                splits: newSplits,
              }),
            })
            await updateSyncedOrder(
              order._id,
              cloneDeep({
                orderId: order._id,
                orders: order.orders,
                reprint_count: isUpdateReprintCount && order.reprint_count ? order.reprint_count + 1 : 1,
                splits: newSplits,
              }),
              false,
              order.isSync
            );
            if(order.isSync){
              this.refreshSyncedData();
            }
          }

          let receiptString = await getReceiptPrintString(order, receiptArgs);

          let receiptItemString = await generateReceiptItemString(order);
          let receiptDetails = await this.parseReceiptDetails(order);
          let printData = {
              print_type: 'Receipt',
              html: receiptString,
              mode: receiptArgs.mode,
          };
          let printConfig = {
              location_id: receiptDetails.location_id,
          };

          Logger.info(`Print Receipt from showPrintOptions`);
          print(printData, printConfig, receiptItemString, IS_PUC_LOCATION);

          if (OFFLOAD.sqliteOffloadReceipt && offloadOrder && receiptArgs.mode === "settlement") {
            const rb = new ReceiptBridge();
            await rb.updateData([{
              local_id: offloadOrder.local_id,
              reprint_count: offloadOrder.reprint_count + 1
            }])

            await this.getSqliteOffloadReceiptsPaged();
          }
      });
    },

    async fetchTaxes() {
      if (OFFLOAD.sqliteOffloadProduct) {
          this.tax_data = this.initialData?.taxes || [];
          return;
      }

      try {
          const response = await getTaxes();
          this.tax_data = response.data;
      } catch (e) {
          console.error(e);
      }
    },

    async reprintVoid(order, receiptArgs){
      receiptArgs.isReprint = true;
      if(order.isSettled) {
        receiptArgs.mode = "void_paid";
        receiptArgs.void_refnum = order.void_receipt_num;
      } else {
        receiptArgs.mode = "void_bill";
        receiptArgs.void_refnum = order.void_bill_num;
      }
      //print
      let voidSlipHTML = await getVoidPrintString(order, receiptArgs);

      //replace text for modal display
      let html = voidSlipHTML;
      html = html.replace(/__BLANK__/ig, ' ');
      html = html.replace(/__MOD__/ig, ' ');
      this.voidSlipString = html;

      let receiptDetails = await this.parseReceiptDetails(order);
      let printData = {
          print_type: 'Void',
          html: voidSlipHTML,
          mode: receiptArgs.mode,
      };
      let printConfig = {
          location_id: receiptDetails.location_id,
      };

      Logger.info(`Print Receipt from reprintVoid`);
      print(printData, printConfig);

      this.isVoidSlipModalOpen = true;

      setTimeout(() => this.triggerManualFetchingOfOrders(true), 3000);
    },

    async voidBill(order, voidReason) {
      const result = await this.$openApproverModal();
      if (!result.success) return;

      window.__showLoader();

      // TODO:
      if (OFFLOAD.sqliteOffloadReceipt) {
        await this.sqliteOffloadVoidBill(order, voidReason, result);

        return;
      }

      //generate void num
      let void_bill_num = null;
      let void_receipt_num = null;

      let receiptArgs = await this.generateReceiptArgs(order);

      switch(order.status) {
        case "Billed":
          void_bill_num = await seriesService.getLastVoidBillNum() + 1;
          seriesService.setLastVoidBillNum(void_bill_num);
          receiptArgs.mode = "void_bill";
          receiptArgs.void_refnum = void_bill_num;
          break;
        case "Paid":
          void_receipt_num = await seriesService.getLastVoidReceiptNum() + 1;
          seriesService.setLastVoidReceiptNum(void_receipt_num);
          receiptArgs.mode = "void_paid";
          receiptArgs.void_refnum = void_receipt_num;
          break;
        default:
            //do nothing
            break;
      }

      const orderSplits = order.splits ?? [];
      const isVoidWholeOrder = Boolean(orderSplits.length == 0 || orderSplits.filter(s => s.isVoided == true).length == orderSplits.length-1);
      const isUpdateSplitOrders = Boolean(orderSplits.length > 0);

      const ordersClone = order.orders.map(o => ({
          ...o,
          isVoidedBeforeBill: o.isVoided && o.isCancelled,
          isLineItemVoided: o.isVoided && !o.isCancelled,
          isCancelled: false,
          isVoided: true,
        }));

      const newSplits = orderSplits.filter(s => s._id == order._id)
                      .map(o => {
                        const isVoided = Boolean(o.isVoided || o.bill_num == order.bill_num)
                        let ret = {
                          ...o,
                          isVoided
                        };

                        if(isUpdateSplitOrders && o.bill_num == order.bill_num) {
                          ret['orders'] = ordersClone;
                          ret['voidApprover'] = cloneDeep(result.approver);
                          ret['isUpdateSyncing'] = false;
                          ret['isUpdateSynced'] = false;
                          ret['updateType'] = 'void';
                          ret['void_bill_num'] = void_bill_num;
                          ret['void_receipt_num'] = void_receipt_num;
                          ret['voidReason'] = voidReason;
                        }

                        return ret;
                      });

      if (order.isSettled) {
        await updateSyncedOrder(
          order._id,
          cloneDeep({
            orderId: order._id,
            orders: ordersClone,
            isVoided: true,
            voidApprover: cloneDeep(result.approver),
            updateType: 'void',
            void_bill_num,
            void_receipt_num,
            voidReason,
            splits: newSplits,
          }),
          false,
          order.isSync
        );

        // DO NOT DELETE MIGHT BE USED IN THE FUTURE
        // POS2-1630 - REOPEN VOIDED PAID
        // -----------LOGIC START------------------
        // FOR THE MEAN TIME add tax data
        // ordersClone = order.orders.map(o => ({
        //   ...o,
        //   product: {
        //     ...o.product,
        //     tax_data: this.tax_data,
        //   },
        // }));

        // const orderClone = cloneDeep({
        //     ...order,
        //     orders: ordersClone,
        //     isSettled: false,
        //     status: 'Billed',
        //     isReopened: true,
        //   });

        // delete orderClone['payments'];

        // // set is delete to true
        // // remove from synced orders
        // await updateSyncedOrder(order._id, orderClone, true);

        // //re-add to orders state
        // this.addOrder(orderClone);
        // -----------LOGIC END------------------

        this.orders = await getSyncedOrders();
      }

      //update settlement state if there are voided splits
      const voidedSplits = newSplits.filter(s => s.isVoided === true);
      if(voidedSplits.length > 0) {
        const settledSplitIndices = cloneDeep(this.activePendingSettlements?.settledSplitIndices);
        if(settledSplitIndices) {
          newSplits.forEach((s, i) => {
            if(s.isVoided) {
              settledSplitIndices[i] = '__DELETE__';
            }
          });
        }
        this.setPendingSettlements({
          orderId: order._id,
          settlement: {
            settledSplitIndices: settledSplitIndices,
          },
        });
      }

      let updateOrderObj = {
              isVoided: isVoidWholeOrder,
              voidApprover: cloneDeep(result.approver),
          isSyncing: true,
          isSync: false,
              updateType: 'void',
              void_bill_num,
              void_receipt_num,
              voidReason,
              splits: newSplits,
          };

      if(isVoidWholeOrder) {
        updateOrderObj['orders'] = ordersClone;
      }

      const index = this.filteredBillOrders.findIndex(o => o._id == order._id);
      if (index >= 0) {
        this.filteredBillOrders[index].splits = updateOrderObj.splits;
      }

      this.updateOrder({
          orderId: order._id,
          order: updateOrderObj,
      });

      this.updateUnsyncVoidedBillCount();

      //print
      let voidSlipHTML = await getVoidPrintString(order, receiptArgs);

      //replace text for modal display
      let html = voidSlipHTML;
      html = html.replace(/__BLANK__/ig, ' ');
      html = html.replace(/__MOD__/ig, ' ');
      this.voidSlipString = html;

      let receiptDetails = await this.parseReceiptDetails(order);
      let printData = {
          print_type: 'Void',
          html: voidSlipHTML,
          mode: receiptArgs.mode,
      };
      let printConfig = {
          location_id: receiptDetails.location_id,
      };

      Logger.info(`Print Receipt from voidBill`);
      print(printData, printConfig);

      this.isVoidSlipModalOpen = true;
      
      await voidBackgroundSync({
        ...order,
        ...updateOrderObj
      })

      this.$swal.success('Bill voided successfully!');

      window.__hideLoader();
      setTimeout(() => this.triggerManualFetchingOfOrders(true), 500);
    },

    async voidTransaction(transaction, voidReason) {
      const result = await this.$openApproverModal();
      if (!result.success) {
        if (result.cancelled) {
          this.$swal.error('Voiding cancelled!');
        }
        return;
      }

      let parentOrder = cloneDeep(this.pendingOrders.find(o => o._id === transaction.parentOrderId));

      if (OFFLOAD.sqliteOffloadReceipt) {
        const ob = new OrderBridge();
        const response = await ob.getOrderById(transaction.parentOrderId);

        if (isEmpty(response)) return;

        parentOrder = JSON.parse(response.order_data);
      }

      parentOrder.orders = parentOrder.orders.map(o => ({
        ...o,
        isVoided: o.kot === transaction.kot ? true : o.isVoided,
      }));

      this.regenerateOrderTotals({ orderId: transaction.parentOrderId, key: 'preVoidTotals' });

      //check if all kot are voided
      let isOrderVoided = parentOrder.orders.length === parentOrder.orders.filter(o => o.isVoided == true).length;

      if (OFFLOAD.sqliteOffloadReceipt
      && OFFLOAD.sqliteOffloadPOSFE1300
      && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
      && isOrderVoided) {
        const tdb = new ServiceTableDetailBridge();
        await tdb.update({
          table_id: parentOrder?.tableId ?? null,
          receipt_local_id: null
        });
      }

      this.updateOrder({
        orderId: transaction.parentOrderId,
        order: {
          ...parentOrder,
          isVoided: isOrderVoided || parentOrder.isVoided,
          isUpdateSyncing: false,
          isUpdateSynced: false,
          updateType: 'void',
          voidReason,
        },
      });
      this.regenerateOrderTotals({ orderId: transaction.parentOrderId });

      if (OFFLOAD.sqliteOffloadReceipt) {
        await this.sqliteUpsertReceipt({
          orderId: transaction.parentOrderId,
          action: OFFLOAD_RECEIPT_ACTION.PENDING_VOID
        })

        await this.getSqliteOffloadPendingOrders()
      }

      //print kot void
      this.printKOT(transaction, null, true);
    },

    // This function mirrors the logic from 'voidBill' to prevent regression issues.
    // Handles voiding a bill in SQLite Lite
    async sqliteOffloadVoidBill(order, voidReason, result = null) {
      try {
        const receipt = cloneDeep(order);
        const ob = new OrderBridge();

        const response = await ob.getOrderById(receipt.local_id);

        if (isEmpty(response)) return;

        // Parse the order data from the response
        order = JSON.parse(response.order_data);

        // Create a copy of the parsed order for further manipulation
        const clonedOrder = order;
        if (OFFLOAD.sqliteOffloadPOSFE1300
          && receipt.transaction_status_id === TRANSACTION_STATUS_ID.BILLED
          && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
          && !isEmpty(clonedOrder.receiptMergedWith)
        ) {
          const orderResponse = await ob.getRows({
            select: ['order_data'],
            whereIn: {
              id: clonedOrder.receiptMergedWith.join(",")
            }
          });

          const sqliteOrders = orderResponse.map(order => JSON.parse(order.order_data));
          const flattenedData = sqliteOrders.flatMap(order => ({ kots: order.kots, pax: order.pax }));
          const pax = flattenedData.map(data => data.pax);
          pax.push(clonedOrder.pax);

          clonedOrder.kots = Array.from(new Set([...flattenedData.flatMap(data => data.kots), ...cloneDeep(clonedOrder.kots)]));
          clonedOrder.pax = sumArray(pax);
          clonedOrder.orders = clonedOrder.orders.concat(sqliteOrders.flatMap(item => item.orders));
        }

        //generate void num
        let void_bill_num = null;
        let void_receipt_num = null;

        let receiptArgs = await this.generateReceiptArgs(clonedOrder);

        order = {...order, isVoided: true};

        switch(receipt.transaction_status_id) {
          case TRANSACTION_STATUS_ID.BILLED:
            void_bill_num = await seriesService.getLastVoidBillNum() + 1;
            seriesService.setLastVoidBillNum(void_bill_num);
            receiptArgs.mode = "void_bill";
            receiptArgs.void_refnum = void_bill_num;

            this.updateOrder({
              orderId: order._id,
              order: {
                ...order,
                voidApprover: cloneDeep(result.approver),
                updateType: 'void',
                void_bill_num,
                voidReason,
              },
            });

            await this.$nextTick();

            await this.sqliteUpsertReceipt({
              orderId: order._id,
              action: OFFLOAD_RECEIPT_ACTION.BILL_VOID
            })

            break;
          case TRANSACTION_STATUS_ID.PAID:
            void_receipt_num = await seriesService.getLastVoidReceiptNum() + 1;
            seriesService.setLastVoidReceiptNum(void_receipt_num);
            receiptArgs.mode = "void_paid";
            receiptArgs.void_refnum = void_receipt_num;
            order.void_receipt_num = void_receipt_num;

            // Update order data in SQLite database
            await ob.upsertOrder(order);

            // Create and update original and void status, then upsert them into receipts and receipt contents
            const rb = new ReceiptBridge();
            await rb.upsertVoidReceipt(receipt, void_receipt_num, order.orders);

            break;
          default:
            //do nothing
            break;
        }

        //print
        const voidSlipHTML = await getVoidPrintString(order, receiptArgs);

        //replace text for modal display
        let html = voidSlipHTML;
        html = html.replace(/__BLANK__/ig, ' ');
        html = html.replace(/__MOD__/ig, ' ');
        this.voidSlipString = html;

        const receiptDetails = await this.parseReceiptDetails(order);

        await print(
          {
            print_type: 'Void',
            html: voidSlipHTML,
            mode: receiptArgs.mode,
          },
          {
            location_id: receiptDetails.location_id,
          }
        );

        this.isVoidSlipModalOpen = true;

        this.$swal.success('Bill voided successfully!');

        await this.getSqliteOffloadReceiptsPaged()

        window.__hideLoader();
      } catch (e) {
        console.log('error: ', e);
        window.__hideLoader();
      }
    },

    openFullVoidModal(item, triggeredFrom = null) {
      this.selectedItem = item;
      this.fullVoidTrigger = triggeredFrom;
      this.isFullVoidModalOpen = true;
    },

    applyFullVoid(voidParams) {
      this.isFullVoidModalOpen = false;
      if(voidParams.triggeredFrom == 'BILL') {
        this.voidBill(voidParams.item, voidParams.reason);
      } else if(voidParams.triggeredFrom == 'TRANSACTION') {
        this.voidTransaction(voidParams.item, voidParams.reason);
      }
    },

    openCustomerDetailsModal() {
      this.isCustomerDetailsModalOpen = true;
    },

    async printKOT(transactionItem, transItemObj = null, isVoided = false) {
      if(transactionItem == null) {
        transactionItem = transItemObj;
      }

      let orderDetail = null;
      if (!OFFLOAD.sqliteOffloadReceipt) {
        orderDetail = uniqBy([...this.orders, ...this.originalOrders.filter(o => o && (!o.isSync || o.isOnlineDelivery))], '_id')
          .find(o => o
            && o._id == transactionItem.parentOrderId
            && (o.originShiftTable?.pos_date == this.posDate || o.isOnlineDelivery)
          );
      } else {
        const ob = new OrderBridge();
        const response = await ob.getOrderById(transactionItem.parentOrderId)

        if (isEmpty(response)) return;

        orderDetail = JSON.parse(response.order_data);
      }

      let receiptDetails = await this.parseReceiptDetails(orderDetail);
      let kotPrintObj = this.getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoided);
      let kotHTMLContent = kotPrintObj.printTemplate;

      let printData = {
          print_type: 'KOT',
          html: kotHTMLContent,
          kotStrArr: kotPrintObj.kotStrArr,
      };

      let printConfig = {
          location_id: receiptDetails.location_id,
          kotItemObject: kotPrintObj.kotItemObject,
      };
      Logger.info(`Print Receipt from printKOT`);
      print(printData, printConfig);
    },

    getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoidItem = false) {

      let printTemplate = kotHtml;

      //initialize kotItemObject. this is for category based printing
      let kotItemObject = {};
      //get product categories and put them in an array
      let orderProductCategories = uniq(map(orderDetail.orders, o => get(o, 'product.product_group.product_category_id')));

      orderProductCategories.forEach(function(value){
          kotItemObject[value] = '';
      });

      const self = this;

      //generate kot items
      let orders = this.kotGroupOrdersByServiceType(orderDetail, transactionItem, true);
      let kotItemsStr = '';
      let kotStrArr = [];
      let separatorPositions = [...orderDetail.separatorPositions ?? []];
      for (let serviceType in orders) {
        const serviceHeaderString = `<span class="fs-17 kot-service-header">***${serviceType.toUpperCase()}***</span><br/><br/>`;
        const lineItemSeparatorString = `<span class="fs-17">${ '-'.repeat(LINE_ITEM_SEPARATOR_CHAR_COUNT) }</span><br/><br/>`;
        kotItemsStr += serviceHeaderString;
        kotStrArr.push({ type: 'service-type-header', string: serviceHeaderString });
        let offset = 0;
        let index = 0;

        orders[serviceType].map(function(order) {
          if(order && order.kot == transactionItem.kot) {
            let offsetIndex = index + offset;
            let separatorString = '';
            while (serviceType == 'Dine-in' && separatorPositions.includes(offsetIndex)) {
                separatorString += lineItemSeparatorString;
                separatorPositions.splice(separatorPositions.indexOf(index + offset), 1);
                offset++;
                offsetIndex++;
            }

            const returnString = `<span class="fs-17 ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">${order.quantity}  ${order.product.product_name}</span><br>

              ${order.product.hasModifier == 1 ?
              `${order.product.forcedMods.map(function (fmod) {
                  return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${fmod.quantity} ${fmod.modifier_name}</span><br>`
              }).join(' ')}${order.product.unforcedMods.map(function (ufmod) {
                  return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${ufmod.quantity} ${ufmod.modifier_name}</span><br>`
              }).join(' ')}` : ''}
              ${self.appendBundledProducts(order)} ${self.appendCustomizedProducts(order)}
              ${order.request != undefined && order.request != null && order.request.length > 0 ?
              `<span class="reqs">&nbsp;&nbsp;${order.request}</span><br>` : ''}
              ${order.isVoided == true ? `<span class="kot-voided-item">- voided</span><br>` : ''}`;


            let categoryId = order.product.product_group?.product_category_id;
            if (!kotItemObject[categoryId]) {
                kotItemObject[categoryId] += serviceHeaderString;
                const previousSeparators = orderDetail.separatorPositions?.filter(pos => pos < index) ?? [];
                if (previousSeparators.length) {
                    kotItemObject[categoryId] += lineItemSeparatorString.repeat(previousSeparators.length);
                }
            }

            kotItemObject[categoryId] += separatorString + returnString;
            kotItemsStr += separatorString + returnString;
            if (separatorString) kotStrArr.push({ type: 'separator', string: lineItemSeparatorString });
            kotStrArr.push({ type: 'line-item', string: returnString, categoryId });
            index++;
          } else {
            let offsetIndex = index + offset;
            while (separatorPositions.includes(offsetIndex)) {
                separatorPositions.splice(separatorPositions.indexOf(index + offset), 1);
                offset++;
                offsetIndex++;
            }
            index++;
          }
        });

        const remainingSeparators = separatorPositions.filter(pos => pos >= index);
        if (serviceType == 'Dine-in' && remainingSeparators.length) {
            kotItemsStr += lineItemSeparatorString;
            kotStrArr.push({ type: 'separator', string: lineItemSeparatorString });
        }
      }

      //assign the kotItemStr to default key in kotItemObject
      kotItemObject['default'] = kotItemsStr;

      let service_type = orderDetail.serviceType == "Delivery" ? "Delivery" : "Table";
      let identifier = orderDetail.tableName == undefined ? orderDetail.tableId : orderDetail.tableName;

      let identifierString = "";
      switch (receiptDetails.account_type_id) {
          case 4:
              identifierString = orderDetail.brandId + "-" + this.dataContainer['qsr_' + orderDetail._id];
              break;
          case 2:
              if(orderDetail.serviceType == "Delivery") {
                  identifierString = orderDetail.brandId + "-" + this.dataContainer['bm_del_' + orderDetail._id];
              } else {
                  identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
              }
              break;
          default:
              identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
      }

      if(orderDetail.isOnlineDelivery) {
        identifierString = orderDetail.shortOrderId ?? orderDetail.channelName;
      }

      // For reprint issues: this.dataContainer returns undefined after shift change so need to get fallback value
      const orderIdentifier = !identifierString.includes('undefined') ? service_type + " : " + identifierString : orderDetail.orderIdentifier;

      let today = new Date();
      let date =
          today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let time =
          ("0" + today.getHours()).substr(-2) + ":" + ("0" + today.getMinutes()).substr(-2) + ":" + ("0" + today.getSeconds()).substr(-2);
      let dateTime = date + " " + time;

      let toReplace = {
          __REPRINT__: isVoidItem ? '' : '<span style="font-weight: bold;">REPRINT</span><br>',
          __ORDERIDENTIFIER__: orderIdentifier,
          __DATETIME__: dateTime,
          __ORDERNUM__: transactionItem.kot,
          __CASHIERNAME__: receiptDetails.cashier_name,
          __PAXCOUNT__: orderDetail.pax,
          __BRANDNAME__: receiptDetails.brand_name,
          __LOCATIONNAME__: receiptDetails.location,
          __MOREDETAILS__: orderDetail.orderDetail ? 'Reference: ' + orderDetail.orderDetail : '',
          __SERVICETYPE__: '',
          __KOTVOID__: isVoidItem ? '<span style="font-weight: bold;">KOT - VOID</span><br>' : '',
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
      printTemplate = printTemplate.replace(RE, function(matched) {
          return toReplace[matched];
      });


      Logger.info(`Print Receipt from getKOTPrintObj`);

      console.log(
          convert(printTemplate.replace('__KOTITEMS__', kotItemsStr),
              {
                  wordwrap: 130,
                  preserveNewlines: true,
              }
      ));

      return {printTemplate, kotItemObject, kotStrArr};
    },

    voidDisabled(item) {
      return (item?.isSettled && !item?.isSync) ||
             (item?.isSettled && this.queueStatus?.[item.bill_num] == 0) ||
             this.checkSplitBillAreAllSettled(item);
    },

    checkSplitBillAreAllSettled(item) {
      return item?.splits?.length > 1
          && !item?.isSync
          && !item.hasOwnProperty('forManualSync');
    },

    isShowRetry(item) {
        return this.queueStatus?.[item.bill_num] != 1 && (item?.isVoided || item.splits?.every(s => s.isSettled))
    },

    async syncOrder(order) {
      const key = `${window.locationId}-salesReceipts`;
      const salesReceipts = JSON.parse(localStorage.getItem(key)) || {};
      const salesReceipt = salesReceipts[order._id]?.find(item => item.bill_num == order.bill_num) || null;

      if (salesReceipt && order.payments.length <= 0) {
        order.payments = salesReceipt.payments
      }

      if (salesReceipt && !order?.receipt_num) {
        order.receipt_num = salesReceipt.receipt_num
      }

      order.isSync = true;

      if(hasPendingRequests()) {
        this.$swal.warning('You still have an on-going server request. Please try again later.');
        return;
      }

      try {
        this.$swal.info(`Sync Retry for BN-${order.bill_num} is currently in progress`);
        await this.forceSyncSingleOrder(order);
      } catch (error) {
        const errorMessage = error?.response?.data?.message ?? 'Something went wrong';
        this.$swal.error(errorMessage);
      }
    },

    checkShowSync() {
      this.showSyncOrderButtonCounter++;

      if(this.showSyncOrderButtonCounter >= this.showSyncOrderButtonShowCount) {
        this.showSyncOrderButton = true;
      }

      if(!this.showSyncOrderButtonInterval) {
        this.showSyncOrderButtonInterval = setInterval(() => {
            clearInterval(this.showSyncOrderButtonInterval);
            this.showSyncOrderButtonCounter = 0;
        }, this.resetClickTimer);
      }
    },

    async handleUpdateCustomerInfo(selectedOrder, customerDetails) {

      const response = await this.$openApproverModal();
      if (!response.success) {
          if (response.cancelled) {
              this.$swal.warning('Save Customer Info cancelled');
          }

          return;
      }

      // add customer details to the orders state
      const orderSplits = selectedOrder.splits ?? [];
      const isUpdateSplitOrders = Boolean(orderSplits.length > 0);
      const newSplits = orderSplits.filter(s => s._id == selectedOrder._id).map(o => {
        let ret = {
          ...o,
        };

        if (isUpdateSplitOrders && o.bill_num == selectedOrder.bill_num) {
          ret['customerDetails'] = {
            name: customerDetails.name,
            businessStyle: customerDetails.businessStyle,
            address: customerDetails.address,
            tin: customerDetails.tin,
            officialReceipt: customerDetails.officialReceipt,
          }
        }

        return ret;
      });

      if (selectedOrder.isSettled) {
        await updateSyncedOrder(
          selectedOrder._id,
          {
            orderId: selectedOrder._id,
            orders: selectedOrder.orders,
            customerDetails: {
              name: customerDetails.name,
              businessStyle: customerDetails.businessStyle,
              address: customerDetails.address,
              tin: customerDetails.tin,
              officialReceipt: customerDetails.officialReceipt,
            },
            splits: newSplits,
          },
          false,
          selectedOrder.isSync
        );
      } else {
        this.updateOrder({
          orderId: selectedOrder._id,
          order: cloneDeep({
            customerDetails: {
              name: customerDetails.name,
              businessStyle: customerDetails.businessStyle,
              address: customerDetails.address,
              tin: customerDetails.tin,
              officialReceipt: customerDetails.officialReceipt,
            },
            splits: newSplits,
          }),
        })
      }

      await this.$nextTick();

      if(selectedOrder.isSync) {
        selectedOrder['customerDetails'] = {
          'name': customerDetails?.name,
          'businessStyle': customerDetails?.businessStyle,
          'address': customerDetails?.address,
          'tin': customerDetails?.tin,
          'officialReceipt': customerDetails?.officialReceipt,
        }

        await updateCustomerInfo(selectedOrder, this.locationId)
      }

      setTimeout(() => this.triggerManualFetchingOfOrders(true), 3000);

      this.generateFilteredBillOrders();
    },

    postCustomerDetailsHandler(selectedOrder, customerDetails) {
      this.isCustomerDetailsModalOpen = false;
      this.handleUpdateCustomerInfo(selectedOrder, customerDetails);
    },

    postSkipCustomerDetailsHandler() {
      this.isCustomerDetailsModalOpen = false;
      this.selectedOrder = null;
      this.customerDetails = {
        name: '',
        address: '',
        tin: '',
        businessStyle: '',
        officialReceipt: '',
      }
    },

    appendBundledProducts(order) {
        if(!order.product?.is_bundle) {
            return '';
        }

        return order.product?.bundled_products.map(product =>
            `<span class="mods">&nbsp;&nbsp;${product.quantity} ${product.product_detail.product_name}</span><br>`).join(' ');

    },

    appendCustomizedProducts(order) {
        if(!order.product?.is_customize) {
            return '';
        }

        return order.product?.customizedProductSummary.map(customizedProduct =>
            customizedProduct.choices
                .filter(choice => choice.selectedCount)
                .map(choice => `<span class="mods">&nbsp;&nbsp;${choice.selectedCount} ${choice.name}</span><br>`).join(' '))
                .join(' ');
    },

    async getSqliteOffloadReceiptsPaged(page = 1, pageAction = null) {
      this.isOffloadFetching = true;
      const posDateBridge = new PosDateBridge();
      const business_date = await posDateBridge.getPosDate(window.locationId);

      const rb = new ReceiptBridge();
      const { serverOptions: payload } = this.offloadBill;
      payload.page = page;

      const lastPage = Math.ceil(this.offloadBill.serverItemsLength / payload.rowsPerPage);
      if (pageAction == "next" && payload.page >= 1) {
        payload.page++;
      } else if (pageAction == "prev" && payload.page <= lastPage) {
        payload.page--;
      }

      payload.where = { business_date }
      payload.whereLike = {
        bill_num: this.billNumQuery
      }
      payload.whereIn = {
        transaction_status_id: "2, 3, 5" // paid, billed, original
      };

      const response = await rb.getReceiptsPaged(payload);
      this.offloadBill.data = response.data;
      this.offloadBill.serverItemsLength = response.totalItems;

      this.isOffloadFetching = false;
    },

    async getSqliteOffloadPendingOrders() {
      const {PENDING} = TRANSACTION_STATUS_ID;
      const rb = new OrderBridge();
      const orders = await rb.getPendingOrders(PENDING.toString());

      this.offloadTransactionItems = orders.map(order => {
        const parsedOrder = JSON.parse(order.order_data);
        parsedOrder.orders = parsedOrder.orders.filter(o => !o?.isVoided);
        return parsedOrder;
      });
    },

    sqliteOffloadSearchBill() {
      if (!OFFLOAD.sqliteOffloadReceipt) return;

      clearTimeout(this.debouncedSearch);
      this.debouncedSearch = setTimeout(() => {
        this.getSqliteOffloadReceiptsPaged();
      }, 500);
    },

    sqliteOffloadTransactionStatus(key) {
      if (key === TRANSACTION_STATUS_ID.ORIGINAL) {
        key = TRANSACTION_STATUS_ID.VOIDED;
      }

      return TRANSACTION_STATUS[key];
    },

    isVoidButtonDisabled(item) {
      return item?.is_integration_order || item.transaction_status_id === TRANSACTION_STATUS_ID.ORIGINAL;
    }
  }

}
</script>

<style scoped>
.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}

.rows-per-page {
  border: none;
  outline: none;
}

button.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;;
}
</style>
